<div class="content-section">
    <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
        <div class="">
 
            <div class="view-block input-style view-label-align">
                <div class="form-group row align-items-baseline">
                    <div class="col-md-12" style="text-align: center">
                        <img [src]="userDetails?.imageUrl || 'assets/img/profile-img.jpg'"
                            style="height: 100px;width: 100px; border-radius: 50%; border: 1px solid black!important;">
                    </div>
                </div>
                <div class="form-group row align-items-baseline">
                    <label class="col-md-5 textalignright ">User Name <span>:</span></label>
                    <div class="col-md-7 textaligncenter">
                        <div class="form-group">
                            <p style="padding: 0px 5px!important;">{{userDetails?.firstName + ' ' +
                                userDetails?.lastName|| 'N/A'}}</p>
                        </div>
                    </div>
                </div>
                <div class="form-group row align-items-baseline">
                    <label class="col-md-5 textalignright">Mobile Number <span>:</span></label>
                    <div class="col-md-7 textaligncenter">
                        <div class="form-group ">
                            <p class="content-view">{{userDetails?.phoneNo || 'N/A'}}</p>
                        </div>
                    </div>
                </div>
                <div class="form-group row align-items-baseline">
                    <label class="col-md-5 textalignright">Email Address <span>:</span></label>
                    <div class="col-md-7 textaligncenter">
                        <div class="form-group ">
                            <p class="content-view">{{userDetails?.email || 'N/A'}}</p>
                        </div>
                    </div>
                </div>
                <div class="form-group row align-items-baseline">
                    <label class="col-md-5 textalignright">Wallet Address <span>:</span></label>
                    <div class="col-md-7 textaligncenter">
                        <div class="form-group ">
                            <p class="content-view">{{walletAddress?.walletAddress || 'N/A'}}</p>
                        </div>
                    </div>
                </div>
                <div class="form-group row align-items-baseline">
                    <label class="col-md-5 textalignright">Registration Date & Time <span>:</span></label>
                    <div class="col-md-7 textaligncenter">
                        <div class="form-group ">
                            <p class="content-view">{{userDetails?.creationTime | date:'medium'}}</p>
                        </div>
                    </div>
                </div>
                <div class="form-group row align-items-baseline">
                    <label class="col-md-5 textalignright">User Type <span>:</span></label>
                    <div class="col-md-7 textaligncenter">
                        <div class="form-group ">
                            <p class="content-view">{{userDetails?.role || 'N/A'}}</p>
                        </div>
                    </div>
                </div>
                <div class="form-group row align-items-baseline">
                    <label class="col-md-5 textalignright">User IP Address <span>:</span></label>
                    <div class="col-md-7 textaligncenter">
                        <div class="form-group ">
                            <p class="content-view">{{userDetails?.lastLpAddress || 'N/A'}}</p>
                        </div>
                    </div>
                </div>
                <div class="form-group row align-items-baseline">
                    <label class="col-md-5 textalignright">Status <span>:</span></label>
                    <div class="col-md-7 textaligncenter">
                        <div class="form-group ">
                            <p class="content-view">{{(userDetails?.userStatus | status) || 'N/A'}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>