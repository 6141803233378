import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showLoginHeader: boolean;
  showLogo: boolean = false;
  selected: any;
  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.mainService.loginStatus.subscribe((res: boolean) => console.log("status", this.showLoginHeader = res))
    if (localStorage.getItem('token')) {
      this.showLoginHeader = true
    }
  }

  head() {
    if (this.showLogo == false) {
      if ((document.getElementById("logo").style.width = "45px")) {
        document.getElementById("logo").style.display = "none";
        this.showLogo = true;
      }
    }
    else {
      document.getElementById("logo1").style.display = "none";
      this.showLogo = false;
    }
  }

  adminProfile() {
    this.router.navigate(['profile-management'])
  }

  logOutModal() {
    $('#logoutModal').modal('show')
  }

  logout() {
    // this.mainService.loginData.next('');
    // this.mainService.loginStatus.next(false)
    // localStorage.removeItem('token');
    $('#logoutModal').modal('hide')
    this.mainService.logout();
    // this.router.navigate(['login'])
  }

}
