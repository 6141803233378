// api end points
export const ApiEndPoint = {
    login: 'auth',
    forgotPassword: 'forgot-password',
    uploadFile: 'account/upload-file',
    // jackpot lottery management
    addLottery: 'account/set-lottery-event',
    viewLottery: 'account/admin/dashboard/find-lottery-event-by-id',
    editLottery: 'account/update-lottery-event',
    // blog management
    blogList: 'static/admin/static-content/get-Blog-list',
    addBlog: 'static/admin/static-content/add-new-Blog',
    viewBlog:'static/admin/static-content/get-Blog-by-id',
    editBlog:'static/admin/static-content/update-Blog-by-id',
    deleteBlog:'static/admin/static-content/delete-Blog-by-id'
}

