import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-faq',
  templateUrl: './view-faq.component.html',
  styleUrls: ['./view-faq.component.scss']
})
export class ViewFaqComponent implements OnInit {
  faqId: any;
  titleKey: any;
  faqData: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res.faqId) {
        this.faqId = res.faqId
        this.titleKey = res.titleKey
      }
    })
    this.viewFaq();
  }

  // -------------------------- view faq ------------------------ //
  viewFaq() {
    this.mainService.showSpinner();
    let url = `static/admin/static-content/get-FAQ-by-id?faqId=${this.faqId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.faqData = res.data
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    })
  }

  // ---------------------- navigate back --------------------- //
  back() {
    this.router.navigate(['/faq-payout-management'], { queryParams: { tabView: 'FAQ', titleKey: this.titleKey } })
  }

}
