import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-user-data',
  templateUrl: './view-user-data.component.html',
  styleUrls: ['./view-user-data.component.scss']
})
export class ViewUserDataComponent implements OnInit {
  tabView: any = 'general';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  // view tab 
  viewTab(tab) {
    this.tabView = tab
  }

}