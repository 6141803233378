import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  showSidebar: boolean
  currentUrl: any;
  profileImage: any;
  profileData: any;
  showLogo: boolean = false;
  constructor(public mainService: MainService, private router: Router) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.currentUrl = '/' + e.url.split('/')[1];
        if (this.currentUrl === '/' || this.currentUrl === '/login' || this.currentUrl == '/forgot-password') {
          if (localStorage.getItem('token')) { this.router.navigate(['dashboard']) }
        }
      }
    });
  }

  showadmin(event) {
    console.log('event', event);
    if (this.showLogo == false) {
      if ((document.getElementById("admin").style.width = "45px")) {
        document.getElementById("admin").style.display = "none";
        this.showLogo = true;
      }
    }
  }

  ngOnInit() {
    $('.btn-toggle,.close_panel').click(() => {
      $('body').toggleClass('toggle-wrapper');
    });
    this.mainService.loginStatus.subscribe((res: boolean) => console.log("status", this.showSidebar = res))
    if (localStorage.getItem('token')) {
      this.showSidebar = true;
      this.getProfile();
    }
    this.mainService.loginData.subscribe((res: any) => {
      if (res) { this.profileData = res }
    })
  }

  // get profile
  getProfile() {
    // this.mainService.showSpinner()
    this.mainService.getApi('account/my-account', 1).subscribe((res: any) => {
      // console.log("sidebar profile response ==>", res);
      if (res.status == 200) {
        this.profileData = res.data
        // this.mainService.hideSpinner();
      } else {
        // this.mainService.hideSpinner();
      }
    })
  }

  adminProfile() {
    this.router.navigate(['profile-management'])
  }

  logOutModal() {
    $('#logoutModal').modal('show')
  }

  logout() {
    // this.mainService.loginData.next('');
    // this.mainService.loginStatus.next(false)
    // localStorage.removeItem('token');
    $('#logoutModal').modal('hide')
    this.mainService.logout()
    // this.router.navigate(['login'])
  }

}