import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/provider/excel.service';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  search: string;
  fromDate: any;
  toDate: any;
  today = new Date().toISOString().substr(0, 10);

  userDataArray: any[] = []
  // pagination
  itemsPerPage = 10;
  currentPage = 1;
  totalItems: any;

  userId: any;

  constructor(private router: Router, public mainService: MainService, public excelService: ExcelService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getUserList()
  }

  // ----------- search ------------- //
  searchEvent() {
    if (this.search || this.fromDate || this.toDate) {
      this.currentPage = 1;
      this.getUserList()
    }
  }

  // --------- reset ------------- //
  resetEvent() {
    if (this.search || this.fromDate || this.toDate) {
      this.currentPage = 1;
      this.search = ''
      this.fromDate = '';
      this.toDate = '';
      this.getUserList()
    }
  }

  // ------- get user list -------- //
  getUserList() {
    let url = `account/admin/user-management/filter-user-details?page=${(this.currentPage - 1) + '&pageSize=' + (this.itemsPerPage) +
      (this.search ? ('&search=' + this.search) : '') +
      (this.fromDate ? ('&fromDate=' + (new Date(this.fromDate).getTime())) : '') +
      (this.toDate ? ('&toDate=' + (new Date(this.toDate).getTime() + (1000 * 60 * 60 * 24))) : '')}`

    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log("get user list response ==>", res)
      if (res.status == 200) {
        this.userDataArray = res.data.list ? res.data.list : '';
        this.totalItems = res.data.totalCount;
        this.mainService.hideSpinner();
      } else {
        this.userDataArray = []
        this.totalItems = 0
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    }, err => {
      this.userDataArray = []
      this.totalItems = 0
      this.mainService.hideSpinner();
      this.mainService.errorToast(err.message)
    })
  }

  // ----------------- pagination --------------------- //
  pagination(event) {
    this.currentPage = event;
    this.getUserList()
  }

  // ----------------------------------- view user ------------------------------- //
  viewUser(userId) {
    this.router.navigate(['/view-user'], { queryParams: { userId: userId } })
  }

  // ----------------------------------- edit user ------------------------------- //
  editUser(userId) {
    this.router.navigate(['/edit-user'], { queryParams: { userId: userId } })
  }

  // ---------------------- block modal ---------------------- //
  blockUserModal(userId) {
    this.userId = userId
    $('#blockUser').modal('show')
  }

  blockUser() {
    this.mainService.showSpinner();
    let url = `account/admin/user-management/update-user-status?userIdForStatusUpdate=${this.userId}&userStatus=BLOCK`
    this.mainService.postApi(url, {}, 1).subscribe((res: any) => {
      console.log("block user response ==>", res)
      $('#blockUser').modal('hide');
      if (res.status == 200) {
        this.getUserList()
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // ------------------------ unblock user ------------------------- //
  unBlockUserModal(userId) {
    this.userId = userId
    $('#unBlockUser').modal('show')
  }

  unBlockUser() {
    this.mainService.showSpinner();
    let url = `account/admin/user-management/update-user-status?userIdForStatusUpdate=${this.userId}&userStatus=ACTIVE`
    this.mainService.postApi(url, {}, 1).subscribe((res: any) => {
      console.log("active user response ==>", res)
      $('#unBlockUser').modal('hide');
      if (res.status == 200) {
        this.getUserList()
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // -------------------- export to excel --------------------------- //
  exportToExcel() {
    let dataArray = [];
    this.userDataArray.forEach((element, ind) => {
      dataArray.push({
        "S.No": ind + 1,
        "User Name": element.customerName ? element.customerName : 'N/A',
        "Mobile Number": element.phoneNo ? element.phoneNo : 'N/A',
        "Email Address": element.email ? element.email : 'N/A',
        "Registration Date & Time": element.createTime ? this.datePipe.transform(element.createTime, 'medium') : 'N/A',
        "Wallet Id": element.walletId ? element.walletId : 'N/A',
        "Login IP Address": element.lastLpAddress ? element.lastLpAddress : 'N/A',
        "Status": element.userStatus ? element.userStatus : 'N/A',
      })
    })
    this.excelService.exportAsExcelFile(dataArray, 'user_management');
  }

}
