<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Banner Management / View Banner</h1>
        </div>
        <div class="content-section">
           
            <div class="order-view mrgn-0-auto setting-page">
                <div class="mb40">
                    <div class=" input-style mb20 mt20 ">
                      
                  
                        <div class="form-group row align-items-baseline view-label-align">
                            <label class="col-md-2 textalignright">Banner Image <span>:</span></label>
                            <div class="col-md-10 textaligncenter">
                                <div class="form-group ">
                             
                                    <img src={{bannerData?.imageUrl}} alt="">
                                </div>
                            </div>
                        </div>
                       
                 
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large max-WT-150 btn-theme" (click)="back()">Back</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>