<div class="content-section">
    <!-- <div class="order-view  max-WT-700 mrgn-0-auto setting-page"> -->
    <!-- <div class="order-view  mrgn-0-auto setting-page" style="max-width: 100%;"> -->
        <div class="">
            <!-- <div class="view-block input-style view-label-align"> -->
            <div class=" input-style view-label-align">



                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="no_wrap_th text-center">
                                <th class="Sorting-img">S. No.</th>
                                <th class="Sorting-img">Transaction Hash</th>
                                <th class="Sorting-img">Currency Type</th>
                                <th class="Sorting-img">Amount</th>
                                <th class="Sorting-img">Litecoin Address</th>
                                <th class="Sorting-img">Status</th>
                               <th class="Sorting-img">Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of userWalletTransaction | paginate:{itemsPerPage:itemsPerPage, currentPage:currentPage, totalItems:totalItems}; let i = index"
                                class="text-center">
                                <td>{{itemsPerPage*(currentPage -1)+i+1}}</td>
                                <!-- <td class="content">{{item?.txnHash || 'N/A'}}</td> -->
                                <td class="txnhashh">
                                    <div *ngIf="item?.txnHash!=null">
                                      {{(item?.txnHash) | shorten:20}}   <i class="fa fa-copy myIcon" aria-hidden="true"  
                                      (click)="copyToClipboard(item?.txnHash)"></i>
                                    </div>
                                    <span  *ngIf="item?.txnHash==null">
                                     N/A
                                    </span>
                                 
                                  </td>
                                <td class="content">{{item?.coinType || 'N/A' }}</td>
                                <td class="content">{{item?.amount || 'N/A'}}</td>
                                <!-- <td class="content">{{item?.address || 'N/A'}}</td> -->
                                <td class="txnhashh">
                                    <div  *ngIf="item?.address!=null">
                                      {{(item?.address) | shorten:20}}   <i class="fa fa-copy myIcon" aria-hidden="true"  
                                      (click)="copyToClipboard(item?.address)"></i>
                                    </div>
                                    <span  *ngIf="item?.address==null">
                                    N/A
                                    </span>
                                 
                                  </td>
                                <td class="content">{{item?.status || 'N/A'}}</td>
                                <td class="content">{{(item?.txnTime | date : 'medium') || 'N/A'}}</td>
                            </tr>
                            <tr *ngIf="userWalletTransaction?.length==0">
                                <td class="table-no-record" colspan="9">No Record Found</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="custom-pagination mt-2 text-align-end">
                        <pagination-controls *ngIf="totalItems>10"></pagination-controls>
                    </div>
                </div>





                <!-- <div class="form-group row align-items-baseline">
                    <div class="col-md-12" style="text-align: center">
                        <img [src]="userDetails?.imageUrl || 'assets/img/profile-img.jpg'"
                            style="height: 100px;width: 100px; border-radius: 50%; border: 1px solid black!important;">
                    </div>
                </div>
                <div class="row">
                    <!-- left row 
                    <div class="col-md-6">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright ">User Name <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <p style="padding: 0px 5px!important;">{{userDetails?.firstName + ' ' +
                                        userDetails?.lastName || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Mobile Number <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userDetails?.phoneNo || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Email Address <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userDetails?.email || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Transaction ID <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userWalletTransaction?.hashId || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Amount Added <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userWalletTransaction?.amountAdded || 0}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end left row  -->

                    <!-- right row 
                    <div class="col-md-6">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright ">Amount Withdrawn <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <p style="padding: 0px 5px!important;">{{userWalletTransaction?.amountWithdrawn || 0}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Status <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userWalletTransaction?.status || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Transaction Date & Time <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{(userWalletTransaction?.creationDate | date:'medium') ||
                                        'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Currency Type<span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userWalletTransaction?.coinName || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Wallet Balance<span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userWalletTransaction?.amount || 'N/A'}}</p>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <!-- end right row
                </div> 
            </div>-->
        </div>
    </div>
</div>