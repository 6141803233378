import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-faq-management',
  templateUrl: './faq-management.component.html',
  styleUrls: ['./faq-management.component.scss']
})
export class FaqManagementComponent implements OnInit {
  faqDataArray: any[] = []
  faqId: any;
  showSection: any = 'TILES';
  topicListArray: any[] = [];
  @Input() private titleKey; // get value from parant component 'faq-and-payout-management'

  // pagination
  itemsPerPage: number = 10;
  currentPage: number = 1;
  totalItems: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService) { }

  /**
   * @param titleKey check if get value from parant component 
   * if @param titleKey contains value then show table as per value get
   * else show tile section
   */
  ngOnInit() {
    if (this.titleKey) {
      this.showSection = 'TABLE';
      this.getFaqListBasedOnTopic()
    } else {
      this.showSection = 'TILES';
      this.getTopicListOfFaq()
    }
  }

  // get topic list of faq
  getTopicListOfFaq() {
    this.mainService.showSpinner()
    let url = `static/admin/static-content/get-only-topic-of-faq`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      // console.log(res)
      if (res.status == 200) {
        this.topicListArray = res.data ? res.data : ''
        this.mainService.hideSpinner();
      } else {
        this.mainService.hideSpinner()
      }
    })
  }

  /**
   * 
   * @param titleKey get from tiles clicked
   * show table section as per @param titleKey get
   */
  navigateTo(titleKey) {
    this.titleKey = titleKey
    this.showSection = 'TABLE'
    const queryParams: Params = { tabView: 'FAQ', titleKey: titleKey };
    this.router.navigate([], {
      relativeTo: this.activatedRoute, queryParams: queryParams, queryParamsHandling: 'merge'
    })
    this.getFaqListBasedOnTopic()
  }

  /**
   * get faq list based on @param titleKey selected on tiles
   */
  getFaqListBasedOnTopic() {
    this.mainService.showSpinner()
    let url = `static/admin/static-content/get-FAQ-by-topic-name?topicName=${this.titleKey}&page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.faqDataArray = res.data ? res.data.list : []
        this.totalItems = res.data.count ? res.data.count : 0
        this.mainService.hideSpinner();
      } else {
        this.faqDataArray = []
        this.totalItems = 0
        this.mainService.hideSpinner()
      }
    })
  }

  pagination(event) {
    this.currentPage = event
    this.getFaqListBasedOnTopic()
  }

  /**
   * on back press show the 'TILES' section
   * and remove the @param titleKey to 'null'
   */
  back() {
    this.showSection = 'TILES'
    const queryParams: Params = { titleKey: null };
    this.router.navigate([], {
      relativeTo: this.activatedRoute, queryParams: queryParams, queryParamsHandling: 'merge'
    })
    this.currentPage = 1; // on back clicked reset the page count to 1
    this.getTopicListOfFaq()
  }


  // -------------------------- navigate to add faq ---------------------------- //
  addFaq() {
    this.router.navigate(['/add-faq'], { queryParams: { titleKey: this.titleKey } })
  }

  // ---------------------------- navigate to view user ------------------------------- //
  viewFaq(faqId) {
    console.log('id', faqId);
    this.router.navigate(['/view-faq'], { queryParams: { faqId: faqId, titleKey: this.titleKey } })
  }

  // ------------------------------- navigate to edit faq --------------------------- //
  editFaq(faqId) {
    console.log('id', faqId);
    this.router.navigate(['/edit-faq'], { queryParams: { faqId: faqId, titleKey: this.titleKey } })
  }

  // ------------------------------- delete faq ----------------------------- //
  openDeleteFaqModal(faqId) {
    $('#deleteFaq').modal('show')
    this.faqId = faqId
  }
  deleteFaq() {
    let url = `static/admin/static-content/delete-FAQ-by-id?faqId=${this.faqId}`
    this.mainService.showSpinner();
    this.mainService.postApi(url, {}, 1).subscribe((res: any) => {
      console.log("delete response ==>", res)
      $('#deleteFaq').modal('hide');
      if (res.status == 200) {
        this.getFaqListBasedOnTopic()
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

}
