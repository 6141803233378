<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">User Management</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div class="sec_head_new sec_head_new_after">
                                <div class="row">
                                    <div class="col-md-2 ">
                                       
                                        <mat-form-field appearance="outline">
                                          
                                            <input matInput [matDatepicker]="picker" placeholder="From date" [(ngModel)]="fromDate" [max]="today" >
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                          </mat-form-field>
                                          
                                    </div>
                                    <div class="col-md-2">
                                     
                                            <mat-form-field appearance="outline">
                                             
                                                <input  matInput [matDatepicker]="picker1" placeholder="To date" [(ngModel)]="toDate" [min]="fromDate"
                                                [disabled]="!fromDate" >
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                              </mat-form-field>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-2">
                                        <div class="filter_search mb20 width100">
                                            <div class="input-group filter_search_group">
                                                <input type="text" class="form-control overflow-text myinput"
                                                    [(ngModel)]="search" placeholder="Search by Email" maxlength="60">
                                                <div class="input-group-append">
                                                    <button class="btn btn_search_group" type="button"><img
                                                            src="assets/img/icon-search.png" alt="Search"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-4">
                                        <div class="text-center admin-btn">
                                            <button type="button" class="btn  btn-theme Go_btn"
                                                (click)="searchEvent()">Search</button>
                                            <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                (click)="resetEvent()">Reset</button>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2">
                                        <div class="justify-content-end">
                                            <button type="button" class="btn btn-theme Go_btn " style="padding-left: 0.65rem;
                                                padding-right: 0.65rem;" (click)="exportToExcel()">Export To
                                                Excel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th class="Sorting-img">S.No.</th>
                                        <th class="Sorting-img">User Name</th>
                                        <th class="Sorting-img">Mobile Number</th>
                                        <th class="Sorting-img">Email Address</th>
                                        <th class="Sorting-img">Registration Date & Time</th>
                                        <th class="Sorting-img">Wallet Id</th>
                                        <th class="Sorting-img">Login IP Address</th>
                                        <th class="Sorting-img">Status</th>
                                        <th class="action_td_btn3 Sorting-img">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of userDataArray | paginate:{itemsPerPage:itemsPerPage, currentPage:currentPage, totalItems:totalItems}; let i = index"
                                        class="text-center">
                                        <td>{{itemsPerPage*(currentPage -1)+i+1}}</td>
                                        <td class="content">{{item?.customerName || 'N/A'}}</td>
                                        <td class="content">{{item?.phoneNo || 'N/A'}}</td>
                                        <td class="content">{{item?.email || 'N/A'}}</td>
                                        <td class="content">{{item?.createTime | date:'medium'}}</td>
                                        <td class="content">{{item?.walletId || 'N/A'}}</td>
                                        <td class="content">{{item?.lastLpAddress || 'N/A'}}</td>
                                        <td class="content">{{(item?.userStatus | status) || 'N/A'}}</td>
                                        <td>
                                            <div class="table-action-btn-alignment">
                                                <button class="btn btn-info" (click)="viewUser(item?.userId)">
                                                    <i class="fa fa-eye" style="color:white"></i></button>
                                           
                                                <button *ngIf="item?.userStatus =='ACTIVE'" class="btn btn-success ml-2"
                                                    (click)="blockUserModal(item?.userId)"><i class="fa fa-check-circle"
                                                        style="color:white"></i></button>
                                                <button *ngIf="item?.userStatus =='BLOCK'" class="btn btn-danger ml-2"
                                                    (click)="unBlockUserModal(item?.userId)"><i class="fa fa-ban"
                                                        style="color:white"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!userDataArray.length">
                                        <td class="table-no-record" colspan="9">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>


<!-- block user modal Start -->
<div class="modal fade global-modal reset-modal" id="blockUser">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Block User?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to block this user?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme" (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-red ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- end block user modal -->


<!-- active user modal Start -->
<div class="modal fade global-modal reset-modal" id="unBlockUser">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Active User?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to active this user?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme" (click)="unBlockUser()">Yes</button>
                                    <button type="button" class="btn btn-red ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- end active user modal -->