import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/constants/end-point';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.scss']
})
export class AddBannerComponent implements OnInit {

  addBannerForm: FormGroup
  image: any;
  addBanner=[]
  bannerid: any;
  imagebase: any;

  constructor( private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.addBannerFormValidation();
    // this.addBannerFormSubmit()
    this.bannerid=this.addBanner.length
  }

  // ------------------ add banner form validation ------------------------- //
  addBannerFormValidation() {
    this.addBannerForm = new FormGroup({
      // 'description': new FormControl(''),
      // 'title': new FormControl(''),
      'image': new FormControl('',[Validators.required])
    })
  }

  // -------------------------- add banner form submit ---------------------------- //
  addBannerFormSubmit() {
    if(!this.image){

      return this.mainService.errorToast('Please upload image of size 1920 * 450 or 1640 * 450 only.')


    }
    let data = {
      // "bannerId": "",
      // "description": "string",
      "imageUrl":  this.image,
      "isDeleted": false
    }
    console.log(data)
    let url = 'static/admin/static-content/create-banner'
    this.mainService.showSpinner();
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log("add banner response ==>", res)
      if (res.status == 200) {
        this.mainService.hideSpinner();
        // this.addBanner=res.data
        this.mainService.successToast(res.message)
        this.router.navigate(['/banner-management'])
      } else {
        console.log("else error")
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

 
  // image functionality
  uploadImg($event): void {
    // var img = $event.target.files[0];
    // this.uploadImageFunc(img);
    if ($event.target.files && $event.target.files[0]) {
      const max_height = 450;
      const max_width = 1920;
      const max_width2 = 1640;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          console.log(img_height, img_width);
          if (img_height == max_height && (img_width == max_width || img_width == max_width2)) {
            // this.imgBase64Path = e.target.result;
                this.uploadImageFunc($event.target.files[0]);
          } else {
            
            this.image = ''
            // this.addBannerForm.controls['bannerFile'].reset()
            this.mainService.errorToast('Please upload image of size 1920 * 450 or 1640 * 450 only.')
          }
        };
      };
      reader.readAsDataURL($event.target.files[0]);
    }
  }
  uploadImageFunc(img) {
   
    var fb = new FormData();
    fb.append('file', img)
    this.mainService.showSpinner();
    this.mainService.fileUploadPostApi('account/upload-file', fb).subscribe(res => {
      this.mainService.hideSpinner();
      if (res['status'] == '200') {
        this.image = res['data'];
        console.log(this.image);
        
      }
    }, err => {
      this.mainService.hideSpinner();
      if (err['status'] == '401') {
        this.mainService.errorToast('Unauthorized Access.');
      } else {
        this.mainService.errorToast('Something went wrong.');
      }
    })
  }
  getBase64(event) {
    let me = this;
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      //me.modelvalue = reader.result;
      
      me.imagebase = reader.result;
      console.log("gdhhf",reader.result);
      
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }
  // ------------------------ naviagte back -------------------------- //
  back() {
    this.router.navigate(['/banner-management'])
  }

}

