import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-lottery-results',
  templateUrl: './lottery-results.component.html',
  styleUrls: ['./lottery-results.component.scss']
})
export class LotteryResultsComponent implements OnInit {
  latestDrawListDataArray: any[] = [];
  closedDrawListDataArray: any[] = [];

  search: string;
  fromDate: any;
  toDate: any;
  today = new Date().toISOString().substr(0, 10);
  // pagination
  itemsPerPage = 6;
  currentPage = 1;
  totalItems: any;
  mydate:any
  constructor(private router: Router, public mainService: MainService) { }
  d=new Date()
   newTime= ((this.d.getHours()*60*60*1000)+(this.d.getMinutes()*60*1000)+(this.d.getSeconds()*1000)) 
   timeCalc = 24 * 60 * 60 * 1000
   addHours= 20*60*60*1000
   addMinute= 15*60*1000

  ngOnInit(): void {
    this.getLatestDrawList();
    this.getClosedDrawList()
  }

  // ------------------- latest draw list ----------------------- //
  getLatestDrawList() {
    this.mainService.showSpinner();
    let url = `account/find-lottery-without-result-latest-draws`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      //console.log(res)
      if (res.status == 200) {
        this.latestDrawListDataArray = res.data.latestLotteryData ? res.data.latestLotteryData : [];
       this.mydate=  new Date((this.d).getTime() + ((1 * this.timeCalc)-(this.newTime)+this.addHours+this.addMinute))
       console.log(this.mydate);
       
        
        this.mainService.hideSpinner();
      } else {
        this.latestDrawListDataArray = [];
        this.mainService.hideSpinner();
      }
    })
  }

  /**
   * ***********************************************************************************************
   * closed draw
   */


  // --------- reset ------------- //
  resetEvent() {
    if (this.search || this.fromDate || this.toDate) {
      this.currentPage = 1;
      this.search = ''
      this.fromDate = '';
      this.toDate = '';
      this.getClosedDrawList()
    }
  }
  // ------------------- closed draw list ----------------------- //
  getClosedDrawList() {
    // let url = `account/find-lottery-with-result-closed-draws?page=0&pagesize=10`
    let url = `account/find-lottery-with-result-closed-draws?page=${(this.currentPage - 1) + '&pagesize=' + (this.itemsPerPage) +
      (this.search ? ('&search=' + this.search) : '') +
      (this.fromDate ? ('&fromDate=' + (new Date(this.fromDate).getTime())) : '') +
      (this.toDate ? ('&toDate=' + (new Date(this.toDate).getTime() + (1000 * 60 * 60 * 24))) : '')}`

    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.closedDrawListDataArray = res.data.closeLotteryData ? res.data.closeLotteryData : [];
        this.totalItems = res.data.count
      } else {
        this.closedDrawListDataArray = []
        this.totalItems = 0
      }
    }, (err: any) => {
      console.log(err)
      this.closedDrawListDataArray = []
      this.totalItems = 0
    })
  }
  // ----------- search ------------- //
  searchEvent() {
    if ( this.fromDate || this.toDate) {
      this.currentPage = 1;
      this.getClosedDrawList()
    }
  }
   // ----------------- pagination --------------------- //
  pagination(event) {
    this.currentPage = event;
    this.getClosedDrawList()
  }

  // --------------------- navigate to view lottery result details --------------------- // 
  view(lotteryId, lotteryDrawType) {
    console.log(lotteryId)
    this.router.navigate(['/view-lottery-details'], { queryParams: { lotteryId: lotteryId, lotteryDrawType: lotteryDrawType } })
  }

}


