<header [hidden]="!showLoginHeader" class="header">
    <nav class="navbar clearfix">
        <div class="logo-box" [routerLink]="['dashboard']">
            <a class="navbar-brand logo" id="logo" *ngIf="showLogo == false">
                <img style="font-size: 8px; max-height: 70px;" src="assets/img/casino_logo.png" alt="ICON"
                    class="lg-logo">
            </a>
            <a class="navbar-brand logo" id="logo1" *ngIf="showLogo == true">
                <img style="font-size: 8px; max-height: 70px;" src="assets/img/casino_icon.png" alt="ICON"
                    class="lg-logo">
            </a>
        </div>
        <div class="d-flex align-items-center justify-content-between header-padding">
            <div class="header-right-part" (click)="head()">
                <button class="btn btn-toggle" type="button" style="color:#000">
                    <em class="fa fa-bars"></em>
                </button>
            </div>

            <div class="header-right-icons">
                <div class="icon-contain">
                    <i class="fa fa-user-circle-o" aria-hidden="true" (click)="adminProfile()"></i>
                    <i class="fa fa-sign-out" aria-hidden="true" (click)="logOutModal()"></i>
                </div>
            </div>
        </div>

    </nav>
</header>
