<div *ngIf="showSection == 'TILES'" class="content-section">
    <div class="outer-box">
        <div class="row">
            <div *ngFor="let item of topicListArray" class="col-md-4" (click)="navigateTo(item.topic_key)">
                <div class="blue text-center faq-tiles-block">
                    <div>
                        <h5>{{item?.topic_name}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="showSection == 'TABLE'" class="content-section">
    <div class="add-new-header-btn-align">
        <button class="btn btn-large  max-WT-150 btn-theme" (click)="addFaq()">+ Add FAQ</button>
        <button class="btn btn-large  max-WT-150 btn-theme" (click)="back()">Back</button>
    </div>
    <div class="outer-box">
        <div class="global-table no-radius p0">
            <div class="tab-content1">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="no_wrap_th text-center">
                                <th>S.No.</th>
                                <th class="Sorting-img">Question</th>
                                <th class="Sorting-img">Last Updated</th>
                                <th class="action_td_btn3 Sorting-img">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of faqDataArray | paginate: {itemsPerPage:itemsPerPage, currentPage: currentPage, totalItems: totalItems};; let i = index"
                                class="text-center">
                                <td>{{itemsPerPage * (currentPage -1)+i+1}}</td>
                                <td>{{item?.question || 'N/A'}}</td>
                                <td class="content">{{(item?.updatedAt | date:'medium') || 'N/A'}}</td>
                                <td>
                                    <div class="table-action-btn-alignment">
                                        <button class="btn btn-info" title="view" (click)="viewFaq(item?.faqDataId)">
                                            <i class="fa fa-eye" style="color:white"></i></button>
                                        <button class="btn btn-info ml-2" (click)="editFaq(item?.faqDataId)"><i
                                                class="fa fa-edit" style="color:white"></i></button>
                                        <button class="btn btn-danger ml-2" title="delete"
                                            (click)="openDeleteFaqModal(item?.faqDataId)"><i class="fa fa-trash"
                                                style="color:white"></i></button>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="!faqDataArray.length">
                                <td class="table-no-record" colspan="4">No Record Found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 10">
                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal fade global-modal reset-modal" id="deleteFaq">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete FAQ's?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this faq?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme" (click)="deleteFaq()">Yes</button>
                                    <button type="button" class="btn btn-danger btn-theme ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- end delete faq modal -->