<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Banner Management</h1>
        </div>
        <div class="content-section">
            <div class="add-new-header-btn-align">
                <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/add-banner">+ Add Banner</button>
            </div>
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th>S.No.</th>
                                     
                                        <th class="Sorting-img">Banner Image</th>

                                    
                                        <th class="action_td_btn3 Sorting-img">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of bannerList | paginate: {itemsPerPage:itemsPerPage, currentPage: currentPage, totalItems: totalItems}; let i = index" 
                                        class="text-center">
                                       
                                        <td>{{itemsPerPage * (currentPage -1)+i+1}}</td>
                                      
                                        <td >
                                            <img src={{item.imageUrl}} alt="" style="width: 200px;height: 60px;">
                                           </td>
                                    
                                        
                                        <td>
                                            <div class="table-action-btn-alignment">
                                                <button class="btn btn-info" title="view" (click)="viewBanner(item.bannerId)"
                                                   >
                                                    <i class="fa fa-eye" style="color:white"></i></button>
                                                <button class="btn btn-info ml-2" ><i
                                                        class="fa fa-edit" style="color:white" (click)="editBanner(item.bannerId)"></i></button>
                                                <button class="btn btn-danger ml-2" title="delete" (click)="openDeleteBlogModal(item.bannerId)" 
                                                    ><i
                                                        class="fa fa-trash" style="color:white"></i></button>
                                                   
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!bannerList.length">
                                        <td class="table-no-record" colspan="5">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 2">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>


<!-- delete blog modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteBlog">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete Banner?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this banner?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme" (click)="deleteBanner()">Yes</button>
                                    <button type="button" class="btn btn-danger btn-theme ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- end delete blog modal -->