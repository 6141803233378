<div class="login-wrapper">
  <div class="container-common">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="d-flex align-items-center minheight70vh">
          <form class="login_box_outer w-100" [formGroup]="resetPasswordForm">
            <div class="login-box max-WT-520">
              <div class="login-right-block">
                <div class="login-heading">
                  <h4>RESET PASSWORD</h4>
                </div>
                <div class="login-box-body">
                  <div class="form-group">
                    <input type="password" formControlName="password" class="form-control"
                      placeholder="Enter New Password" maxlength="32" (keypress)="mainService.preventSpace($event)" />
                    <div class="text-danger"
                      *ngIf="resetPasswordForm.get('password').hasError('required') && (resetPasswordForm.get('password').dirty || resetPasswordForm.get('password').touched)">
                      *Please enter new password.
                    </div>
                    <div class="text-danger"
                      *ngIf="resetPasswordForm.get('password').hasError('pattern') && !resetPasswordForm.get('password').hasError('required') && (resetPasswordForm.get('password').dirty || resetPasswordForm.get('password').touched)">
                      *Password must contain atleast 8 characters including one digit and one special character.
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="password" formControlName="confirmPassword" class="form-control"
                      placeholder="Enter Confirm New Password" maxlength="32"
                      (keypress)="mainService.preventSpace($event)" />
                    <div class="text-danger"
                      *ngIf="(resetPasswordForm.value.password != resetPasswordForm.value.confirmPassword) && resetPasswordForm.get('confirmPassword').dirty ">
                      Confirm password should be same as new password.
                    </div>
                  </div>
                </div>
                <div class="text-center mt40">
                  <button
                    [disabled]="resetPasswordForm.invalid || (resetPasswordForm.get('password').value != resetPasswordForm.get('confirmPassword').value)"
                    (click)="resetPassword()" routerLinkActive="router-link-active"
                    class="btn btn-login btn-large  width100">SUBMIT</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>