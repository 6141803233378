import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/constants/end-point';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-blog',
  templateUrl: './view-blog.component.html',
  styleUrls: ['./view-blog.component.scss']
})
export class ViewBlogComponent implements OnInit {
  blogId: any;
  blogData: any;
 

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res.blogId) {
        this.blogId = res.blogId
      }
    })
    this.viewBlog();
  }

  // -------------------------- view faq ------------------------ //
  viewBlog() {
    this.mainService.showSpinner();
    let url = `${ApiEndPoint.viewBlog}?blogId=${this.blogId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.blogData = res.data
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    })
  }

  // ---------------------- navigate back --------------------- //
  back() {
    this.router.navigate(['/blog-management'])
  }

}
