import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-general-user-data',
  templateUrl: './view-general-user-data.component.html',
  styleUrls: ['./view-general-user-data.component.scss']
})
export class ViewGeneralUserDataComponent implements OnInit {
  userId: any;
  userDetails: any;
  walletAddress: any=[];

  constructor(private activatedRoute: ActivatedRoute, public mainService: MainService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      this.userId = res.userId
    })
    this.getUserDetails()
    this.getWalletAddress()
  }

  // ------------------- get user details ------------------------ //
  getUserDetails() {
    this.mainService.showSpinner();
    let url = `account/admin/user-management/user-details?userId=${this.userId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.userDetails = res.data
        this.mainService.hideSpinner()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }
  getWalletAddress() {
    this.mainService.showSpinner();
    let url = `wallet/admin/transaction-history/get-address-user?userId=${this.userId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.walletAddress = res.data
        this.mainService.hideSpinner()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }
}
