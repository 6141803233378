import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/constants/end-point';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-add-blog',
  templateUrl: './add-blog.component.html',
  styleUrls: ['./add-blog.component.scss']
})
export class AddBlogComponent implements OnInit {
  addBlogForm: FormGroup
  image: any;

  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.addBlogFormValidation();
  }

  // ------------- add blog form validation --------------------- //
  addBlogFormValidation() {
    this.addBlogForm = new FormGroup({
      'author': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'title': new FormControl('', Validators.required),
      'image': new FormControl('')
    })
  }

  // ----------------------- add blog -------------------------- //
  addBlog() {
    let data = {
      "author": this.addBlogForm.value.author,
      "description": this.addBlogForm.value.description,
      "title": this.addBlogForm.value.title,
      'image': this.image,
      "isDeleted": false
    }
    let url = ApiEndPoint.addBlog
    console.log(data)
    this.mainService.showSpinner();
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message)
        this.router.navigate(['/blog-management'])
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // image upload
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.mainService.showSpinner();
    this.mainService.fileUploadPostApi(ApiEndPoint.uploadFile, fb).subscribe(res => {
      this.mainService.hideSpinner();
      if (res['status'] == '200') {
        this.image = res['data'];
      }
    }, err => {
      this.mainService.hideSpinner();
      if (err['status'] == '401') {
        this.mainService.errorToast('Unauthorized Access.');
      } else {
        this.mainService.errorToast('Something went wrong.');
      }
    })
  }

  // ------------------------- navigate back ----------------------- //
  back() {
    this.router.navigate(['/blog-management'])
  }

}
