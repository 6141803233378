import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-add-payout',
  templateUrl: './add-payout.component.html',
  styleUrls: ['./add-payout.component.scss']
})
export class AddPayoutComponent implements OnInit {

  addFaqForm: FormGroup

  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.addFaqFormValidation();
  }

  // ------------- add faq form validation --------------------- //
  addFaqFormValidation() {
    this.addFaqForm = new FormGroup({
      'answer': new FormControl('', Validators.required),
      'question': new FormControl('', Validators.required)
    })
  }

  // ----------------------- add faq -------------------------- //
  addFaq() {
    let data = {
      "answer": this.addFaqForm.value.answer,
      "question": this.addFaqForm.value.question,
      "isDeleted": false
    }
    let url = `static/admin/static-content/add-Guarenteed-Pay-Outs`
    console.log(data)
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message)
        this.router.navigate(['/faq-payout-management'], { queryParams: { tabView: 'PAY_OUT_FAQ' } })
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // ------------------------- navigate back ----------------------- //
  back() {
    this.router.navigate(['/faq-payout-management'], { queryParams: { tabView: 'PAY_OUT_FAQ' } })
  }

}
