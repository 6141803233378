<div class="content-section">
    <!-- <div class="order-view  max-WT-700 mrgn-0-auto setting-page"> -->
    <div class="order-view  mrgn-0-auto setting-page" style="max-width: 100%;">
        <div class="">
            <!-- <div class="admin-profile">
                        <h4 class="mb20 text-center">View Profile</h4>
                    </div> -->
            <!-- <div class="view-block input-style view-label-align"> -->
            <div class=" input-style view-label-align">
                <div class="form-group row align-items-baseline">
                    <div class="col-md-12" style="text-align: center">
                        <img [src]="userDetails?.imageUrl || 'assets/img/profile-img.jpg'"
                            style="height: 100px;width: 100px; border-radius: 50%; border: 1px solid black!important;">
                    </div>
                </div>
                <div class="row">
                    <!-- left row -->
                    <div class="col-md-6">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright ">User Name <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <p style="padding: 0px 5px!important;">{{userDetails?.firstName + ' ' +
                                        userDetails?.lastName || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright ">User ID <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <p style="padding: 0px 5px!important;">{{userDetails?.userId || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Email Address <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userDetails?.email || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Mobile Number <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userDetails?.phoneNo || 'N/A'}}</p>
                                </div>
                            </div>
                        </div>


                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Total Amount Won <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userDetails?.amountWon || 0}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- end left row  -->

                    <!-- right row -->
                    <div class="col-md-6">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright ">Crypto Wallet Balance <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group">
                                    <p class="content-view">{{userCryptoWallet?.totalBalance || 0}} LTC
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Current Invested Balance <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userDetails?.currentInvestedBalance || 0}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Currently Booked Lotto <span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userDetails?.currentlyBookedLotto || 0}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Amount<span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{userDetails?.amount || 0}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-6 textalignright">Lotto Won<span>:</span></label>
                            <div class="col-md-6 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">{{toatlLottoWon || 0}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end right row -->
                </div>
            </div>
        </div>
    </div>
</div>


<!-- ----------------------------------- table ---------------------------------- -->
<div class="content-section">
    <div class="outer-box">
        <div class="global-table no-radius p0">
            <div class="tab-content1">
                <div class="tab-pane1">
                    <div class="sec_head_new sec_head_new_after">
                        <div class="row">
                            <div class="col-md-2">
                                <input type="date" class="form-control" [(ngModel)]="fromDate" [max]="today">
                            </div>
                            <div class="col-md-2">
                                <input type="date" class="form-control" [(ngModel)]="toDate" [min]="fromDate"
                                    [disabled]="!fromDate">
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-2">
                                <div class="filter_search mb20 width100">
                                    <div class="input-group filter_search_group">
                                        <input type="text" class="form-control overflow-text" [(ngModel)]="search"
                                            placeholder="Search by Lotto Name " maxlength="60">
                                        <div class="input-group-append">
                                            <button class="btn btn_search_group" type="button"><img
                                                    src="assets/img/icon-search.png" alt="Search"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-4">
                                <div class="text-center admin-btn">
                                    <button type="button" class="btn  btn-theme Go_btn"
                                        (click)="searchEvent()">Search</button>
                                    <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                        (click)="resetEvent()">Reset</button>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2">
                                <div class="justify-content-end">
                                    <button type="button" class="btn btn-theme Go_btn " style="padding-left: 0.4rem;
                                        padding-right: 0.4rem;" (click)="exportToExcel()">Export To Excel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="no_wrap_th text-center">
                                <th class="Sorting-img">S.No.</th>
                                <th class="Sorting-img">Date</th>
                                <th class="Sorting-img">Time</th>
                                <th class="Sorting-img">Lotto Number</th>
                                <th class="Sorting-img">Amount</th>
                                <th class="Sorting-img">Status</th>
                                <th class="Sorting-img">Jackpot Lottery Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of userLotteryDataArray | paginate:{itemsPerPage:itemsPerPage, currentPage:currentPage, totalItems:totalItems}; let i = index"
                                class="text-center">
                                <td>{{itemsPerPage*(currentPage -1)+i+1}}</td>
                                <td class="content">{{item?.creationDate | date:'mediumDate' || 'N/A'}}</td>
                                <td class="content">{{item?.creationDate | date:'mediumTime' || 'N/A'}}</td>
                                <td class="content">
                                    <div class="lottery-number-box">
                                        <span class="circle red-circle">{{item?.numberOne}}</span>
                                        <span class="circle red-circle">{{item?.numberTwo}}</span>
                                        <span class="circle red-circle">{{item?.numberThree}}</span>
                                        <span class="circle red-circle">{{item?.numberFour}}</span>
                                        <span class="circle red-circle">{{item?.numberFive}}</span>
                                        <span class="circle red-circle">{{item?.numberSix}}</span>
                                    </div>
                                </td>
                                <td class="content">{{item?.amount || 'N/A'}}</td>
                                <td class="content">{{(item?.priceStatus | status) || 'N/A'}}</td>
                                <td class="content">{{item?.lotteryName || 'N/A'}}</td>
                            </tr>
                            <tr *ngIf="!userLotteryDataArray.length">
                                <td class="table-no-record" colspan="9">No Record Found</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 10">
                        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>