<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Blog Management / Add Blog</h1>
        </div>
        <div class="content-section">
           
            <div class="order-view mrgn-0-auto setting-page">
                <div class="mb40">
                    <div class="admin-profile">
                    </div>
                    <div class="input-style mb20 mt20 ">
                        <form [formGroup]="addBlogForm">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright ">Title<span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Title"
                                            formControlName="title" (keypress)="mainService.preventSpace($event)"
                                            maxlength="512">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addBlogForm.get('title').hasError('required') && (addBlogForm.get('title').dirty || addBlogForm.get('title').touched)">
                                                *Please enter title.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright ">Auther Name<span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Auther Name"
                                            formControlName="author" (keypress)="mainService.preventSpace($event)"
                                            maxlength="60">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addBlogForm.get('author').hasError('required') && (addBlogForm.get('author').dirty || addBlogForm.get('author').touched)">
                                                *Please enter auther name.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">Description <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group ">
                                        <textarea type="text" class="form-control" rows="8"
                                            placeholder="Enter Description" formControlName="description"
                                            (keypress)="mainService.preventSpace($event)"></textarea>
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addBlogForm.get('description').hasError('required') && (addBlogForm.get('description').dirty || addBlogForm.get('description').touched)">
                                                *Please enter description.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">Blog Image <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group ">
                                        <input type="file" formControlName="image"
                                            accept="image/jpg,image/jpeg,image/png" (change)="uploadImg($event)">
                                    </div>
                                    <img *ngIf="image" [src]="image" height="200">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!addBlogForm.valid"
                            (click)="addBlog()">Save</button>
                        <button class="btn btn-large  max-WT-150 btn-theme" (click)="back()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>