import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { ExcelService } from 'src/app/provider/excel.service';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {
  search: string;
  fromDate: any;
  toDate: any;
  today = new Date().toISOString().substr(0, 10);

  // pagination
  itemsPerPage = 10;
  currentPage = 1;
  totalItems: any;

  transactionHistoryDataArray: any = []
  transactionId: any; // block transaction

  constructor(private router: Router, public mainService: MainService, private datePipe: DatePipe, public excelService: ExcelService, private clipboardApi: ClipboardService) { }

  ngOnInit() {
    this.getTrasactionList()
  }

  // ----------- search ------------- //
  searchEvent() {
    if (this.search || this.fromDate || this.toDate) {
      this.currentPage = 1;
      this.getTrasactionList()
    }
  }

  // --------- reset ------------- //
  resetEvent() {
    if (this.search || this.fromDate || this.toDate) {
      this.currentPage = 1;
      this.search = ''
      this.fromDate = '';
      this.toDate = '';
      this.getTrasactionList()
    }
  }

  // ------- get transaction list -------- //
  getTrasactionList() {
    let url = `wallet/admin/transaction-history/get-all-transaction-history?coinName=LTC&page=${(this.currentPage - 1) + '&pageSize=' + (this.itemsPerPage) +
      (this.search ? ('&search=' + this.search) : '') +
      (this.fromDate ? ('&fromDate=' + (new Date(this.fromDate).getTime())) : '') +
      (this.toDate ? ('&toDate=' + (new Date(this.toDate).getTime() + (1000 * 60 * 60 * 24))) : '')}`
    this.mainService.showSpinner();
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log("transaction list response ==>", res)
      if (res.status == 200) {
        this.transactionHistoryDataArray = res.data.resultlist
       
        
        this.totalItems = res.data.totalCount;
        this.mainService.hideSpinner();
      } else {
        this.transactionHistoryDataArray = [];
        this.totalItems = 0;
        this.mainService.hideSpinner();
      }
    }, (err: any) => {
      console.log("transaction history list error =>", err)
      this.transactionHistoryDataArray = [];
      this.totalItems = 0;
      this.mainService.hideSpinner()
      this.mainService.errorToast(err.message);
    })
  }


  copyToClipboard(item) {
      
    this.mainService.successToast('Copied')
    this.clipboardApi.copyFromContent(item)
   }

  // ---------------- pagination --------------------- //
  pagination(event) {
    this.currentPage = event;
    this.getTrasactionList()
  }

  // ------------------------- view transaction ---------------------------- //
  view( transactionId) {
    this.router.navigate(['/view-transaction'], { queryParams: {  transactionId: transactionId } })
  }

  // -------------------------- block transaction ------------------------- //
  openBlockModal(transactionId) {
    this.transactionId = transactionId
    $('#blockModal').modal('show')
  }

  block() {
    let data = {
      transactionId: this.transactionId
    }
    console.log(data)
    this.mainService.showSpinner();
    this.mainService.postApi('', data, 1).subscribe((res: any) => {
      console.log("block response ==>", res)
      $('#blockModal').modal('hide');
      if (res.status == 200) {
        this.getTrasactionList()
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // -------------------- export to excel ------------------ //
  exportToExcel() {
    let dataArray = [];
    this.transactionHistoryDataArray.forEach((element,ind) => {
      dataArray.push({
        "S.No.": ind+1,
        "Transaction ID": element.txnId ? element.txnId : 'N/A',
        "Transaction Type": element.txnType ? element.txnType : 'N/A',
        "Currency Type": element.coinType ? element.coinType : 'N/A',
        "Amount": element.amount ? element.amount : 'N/A',
        "Litecoin Address": element.txnHash ? element.txnHash : 'N/A',
        "Status": element.status ? element.status : 'N/A',
        "Transaction Date & Time": element.txnTime ? this.datePipe.transform(element.txnTime, 'medium') : 'N/A'
      })
    })
    this.excelService.exportAsExcelFile(dataArray, 'transaction_management');
  }

}
