<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <!-- <h1 class="page_title">{{lotteryDetails?.lotteryName || 'Lottery'}}</h1> -->
            <h1 class="page_title">NY_LOTTERY</h1>
            <div class="card-date-time">
                <span>Draw Date : </span> <span>{{lotteryDetails?.resultDateTime | date:'mediumDate'}}</span>
            </div>
            <div class="card-date-time">
                <span>Draw Time : </span> <span>{{lotteryDetails?.resultDateTime | date:'mediumTime'}}</span>
            </div>
            <div style="float: right;margin-top: -76px;background: #6495ED!important;">
                <button class="btn btn-large  max-WT-150 btn-theme" (click)="addJackpot()"
               >Update Jackpot</button>
            </div>
            <div style="margin-top: -17px;" class="myright">
                <label for="">Total Jackpot : </label>
                <p style="font-weight: bold; margin-left: 10px;" class="mypara">{{((jackpotAmount)/ltcPrize | number : '.8-8')|| 0}} $</p>
            </div>
            <div style="margin-top: 10px;margin-right: 24px;"  class="myright">
                <label for="">Total Jackpot : </label>
                <p style="font-weight: bold; margin-left: 10px;" class="mypara">{{((jackpotAmount) | number : '.8-8')|| 0}} Ł</p>
                
            </div>
            <div style="margin-top: 35px;" class="myright">
                <label for="">Total Transaction : </label>
                <p style="font-weight: bold; margin-left: 10px;" class="mypara">{{((lastWeek)/ltcPrize | number : '.8-8')|| 0}} $</p>
            </div>
            <div style="margin-top: 60px;margin-right: 14px;" class="myright">
                <label for="">Total Transaction : </label>
                <p style="font-weight: bold; margin-left: 10px;" class="mypara">{{((lastWeek) | number : '.8-8')|| 0}} Ł</p>
                
            </div>
 
        </div>

        <!--  -->
        <div style="padding: 0px 15px 20px 15px;" class="mytop">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="admin-profile">
                        <h4 class="mb20 text-center">Lucky Draw Numbers:</h4>
                    </div>
                    <div class="add-store-block input-style mb20 mt20 ">

                        <!-- <div class="form-group row align-items-baseline view-label-align"> -->

                        <div class="lottery-number-box">
                            <span class="rounded-corner-box red-circle">{{luckyDrawNumber?.numberOne}}</span>
                            <span class="rounded-corner-box red-circle">{{luckyDrawNumber?.numberTwo}}</span>
                            <span class="rounded-corner-box red-circle">{{luckyDrawNumber?.numberThree}}</span>
                            <span class="rounded-corner-box red-circle">{{luckyDrawNumber?.numberFour}}</span>
                            <span class="rounded-corner-box red-circle">{{luckyDrawNumber?.numberFive}}</span>
                            <span class="rounded-corner-box red-circle">{{luckyDrawNumber?.numberSix}}</span>
                            <span style="border-left: 2px solid #000;"></span>
                            <span class="rounded-corner-box bonus-num-bg-color">{{luckyDrawNumber?.numberBouns}}</span>

                        </div>
                        <!-- </div> -->


                    </div>
                    <!-- <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme"
                            >Save</button>
                    </div> -->
                </div>
            </div>
        </div>
        <!--  -->
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">


                        <!-- table first -->
                        <div class="admin-profile">
                            <h4>Lottery Results</h4>
                            <!-- <button type="button" class="btn btn-theme Go_btn " style="padding-left: 0.65rem;
                                padding-right: 0.65rem;" (click)="openResultNotDrawnModal()">view Results
                                    </button> -->
                                    <!-- <div class="col-md-2">
                                        <select name="" id="" class="form-control">
                                            <option value="">Select Winner</option>
                                            <option value="">First Winner</option>
                                            <option value="">Second Winner</option>
                                            <option value="">Third Winner</option>
                                            <option value="">Fourth Winner</option>
                                            <option value="">Fifth Winner</option>
                                        </select>
                                      
                                    </div> -->
                              
                            <div class="add-new-header-btn-align">
                               
                                <button class="btn btn-large  max-WT-150 btn-theme"
                                    (click)="openCalculatorModal()">Calculator</button>
                                <!-- <button class="btn btn-large  max-WT-150 btn-theme"
                                    (click)="carryForwardLottery()">Carry Forward</button> -->
                                    <!-- <button class="btn btn-large  max-WT-150 btn-theme"
                                    (click)="sendAll()">Send All</button> -->
                            </div>
                        </div>
                        <div *ngIf="!lotteryResultArray.length" style="padding: 0px 15px 20px 15px;">
                            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                                <div class="admin-profile">
                                    <h4 class=" text-center">Results have not been drawn yet!</h4>
                                </div>
                            </div>
                        </div>

                        <div  *ngIf="lotteryResultArray.length" class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th class="Sorting-img">Winner</th>
                                        <th class="Sorting-img">User ID</th>
                                        <th class="Sorting-img">Name</th>
                                        <th class="Sorting-img">Purchase Date </th>
                                        <th class="Sorting-img">Purchase  Time</th>
                                       
                                        <th class="Sorting-img">Lotto Number</th>
                                        <th class="Sorting-img">Prize Won</th>
                                   
                                        <th class="Sorting-img">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of lotteryResultArray" class="text-center">

                                        <!-- <td class="content">{{item?.position + 'Prize' || 'N/A'}}</td> -->
                                        <td class="content">{{item?.winner}}</td>

                                        <td class="content">{{item?.fkUserId || 'N/A'}}</td>
                                        <td class="content">{{item?.userName || 'N/A'}}</td>
                                        <td>{{item?.creationDate | date:'mediumDate' || 'N/A'}}</td>
                                        <td class="content">{{item?.updatedDate | date:'mediumTime' || 'N/A'}}</td>
                                        <td class="content">
                                            <div class="lottery-number-box">
                                                <span class="circle red-circle">{{item?.numberOne}}</span>
                                                <span class="circle red-circle">{{item?.numberTwo}}</span>
                                                <span class="circle red-circle">{{item?.numberThree}}</span>
                                                <span class="circle red-circle">{{item?.numberFour}}</span>
                                                <span class="circle red-circle">{{item?.numberFive}}</span>
                                                <span class="circle red-circle">{{item?.numberSix}}</span>
                                                <span style="border-left: 1px solid #000;"></span>
                                                <span class="circle bonus-num-bg-color">{{item?.numberBouns}}</span>
                                            </div>
                                        </td>
                                        <td class="content">
                                            <div *ngIf="item?.winner=='First Winner'">
                                               
                                                    {{((amount?.firstPriceAmount | number : '.8-8'))/count1 || 'N/A'}} Ł
                                                </div >
                                                <div *ngIf="item?.winner=='Second Winner'">
                                                    
                                                        {{((amount?.secPriceAmount | number : '.8-8'))/count2 || 'N/A'}} Ł
                                                </div>
                                                <div *ngIf="item?.winner=='Third Winner'">
                                               
                                                    {{((amount?.thirdPriceAmount | number : '.8-8'))/count3 || 'N/A'}} Ł
                                                </div >
                                                <div *ngIf="item?.winner=='Fourth Winner'">
                                                    
                                                        {{((amount?.fourthPriceAmount | number : '.8-8'))/count4 || 'N/A'}} Ł
                                                </div>
                                                <div *ngIf="item?.winner=='Fifth Winner'">
                                                    
                                                    {{((amount?.fifthPriceAmount | number : '.8-8'))/count5 || 'N/A'}} Ł
                                            </div>
                                                
                                        </td>
                                    
                                        
                                        <td>
                                             <div *ngIf="item?.winner=='First Winner'">
                                               
                                                <button class="btn btn-large max-WT-100 btn-theme mybutton" (click)="sendButton(item?.fkUserId,(amount?.firstPriceAmount)/count1 )">Send</button>
                                                <button class="btn btn-large max-WT-100 btn-theme" (click)="findAddress(item?.fkUserId, item?.purchaseId)">Transfer</button>
                                            </div >
                                            <div *ngIf="item?.winner=='Second Winner'">
                                               
                                                <button class="btn btn-large max-WT-100 btn-theme mybutton" (click)="sendButton(item?.fkUserId,(amount?.secPriceAmount)/count2)">Send</button>
                                                <button class="btn btn-large max-WT-100 btn-theme" (click)="findAddress(item?.fkUserId,item?.purchaseId)">Transfer</button>

                                            </div >
                                            <div *ngIf="item?.winner=='Third Winner'">
                                               
                                               
                                                <button class="btn btn-large max-WT-100 btn-theme mybutton" (click)="sendButton(item?.fkUserId,(amount?.thirdPriceAmount)/count3)">Send</button>
                                                <button class="btn btn-large max-WT-100 btn-theme" (click)="findAddress(item?.fkUserId,item?.purchaseId)">Transfer</button>

                                            </div >
                                            <div *ngIf="item?.winner=='Fourth Winner'">                                                                                          
                                            <button class="btn btn-large max-WT-100 btn-theme mybutton" (click)="sendButton(item?.fkUserId,(amount?.fourthPriceAmount)/count4)">Send</button>
                                            <button class="btn btn-large max-WT-100 btn-theme" (click)="findAddress(item?.fkUserId,item?.purchaseId)">Transfer</button>

                                        </div >
                                            <div *ngIf="item?.winner=='Fifth Winner'">
                                               
                                                
                                                <button class="btn btn-large max-WT-100 btn-theme mybutton" (click)="sendButton(item?.fkUserId,(amount?.fifthPriceAmount)/count5 )">Send</button>
                                                <button class="btn btn-large max-WT-100 btn-theme" (click)="findAddress(item?.fkUserId,item?.purchaseId)">Transfer</button>

                                            </div >

                                           
                                        </td>
                                    </tr>
                                    <!-- <tr *ngIf="!lotteryResultArray.length">
                                        <td class="table-no-record" colspan="9">No Record Found</td>
                                    </tr> -->
                                </tbody>
                            </table>
                            <!-- <div class="custom-pagination mt-2 text-align-end" *ngIf="total > 10">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div> -->
                        </div>
                        <!-- end table first -->

                        <!-- table second -->
                        <div class="admin-profile mt40">
                            <h4 class="mt20">Purchase Details</h4>
                        </div>
                        <!-- search section -->
                        <div class="tab-pane1">
                            <div class="sec_head_new sec_head_new_after">
                                <!-- <form [formGroup]="searchForm"> -->
                                <div class="row">
                   
                                    <div class="col-md-2">
                                        <!-- <input type="date" class="form-control" [(ngModel)]="fromDate" [max]="today"> -->
                                        <mat-form-field appearance="outline">
                                            <!-- <mat-label>From date</mat-label> -->
                                            <input matInput [matDatepicker]="picker" placeholder="From date" [(ngModel)]="fromDate" [max]="today" >
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                          </mat-form-field>
                                    </div>
                                    <div class="col-md-2">
                                        <!-- <input type="date" class="form-control" [(ngModel)]="toDate" [min]="fromDate"
                                            [disabled]="!fromDate"> -->
                                            <mat-form-field appearance="outline">
                                                <!-- <mat-label>To date</mat-label> -->
                                                <input  matInput [matDatepicker]="picker1" placeholder="To date" [(ngModel)]="toDate" [min]="fromDate"
                                                [disabled]="!fromDate" >
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                              </mat-form-field>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-2">
                                        <div class="filter_search mb20 width100">
                                            <div class="input-group filter_search_group">
                                                <input type="text" class="form-control overflow-text myinput"
                                                    [(ngModel)]="search" placeholder="Search by Transaction ID "
                                                    maxlength="60">
                                                <div class="input-group-append">
                                                    <button class="btn btn_search_group" type="button"><img
                                                            src="assets/img/icon-search.png" alt="Search"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-5 col-lg-5 col-md-4">
                                        <div class="text-center admin-btn">
                                            <button type="button" class="btn  btn-theme Go_btn"
                                                (click)="searchEvent()">Search</button>
                                            <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                (click)="resetEvent()">Reset</button>
                                                <button type="button" class="btn btn-theme ml-2 Go_btn " 
                                                (click)="exportToExcel()">Export To
                                                Excel</button>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-2 col-lg-2 col-md-2">
                                        <div class="justify-content-end">
                                            <button type="button" class="btn btn-theme Go_btn " style="padding-left: 0.65rem;
                                                padding-right: 0.65rem;" (click)="exportToExcel()">Export To
                                                Excel</button>
                                        </div>
                                    </div> -->
                                </div>
                                <!-- </form> -->
                            </div>
                        </div>
                        <!-- end search section -->
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th class="Sorting-img">S.No</th>
                                        <th class="Sorting-img">Purchase Date</th>
                                        <th class="Sorting-img">Purchase Time</th>
                                        <th class="Sorting-img">User ID</th>
                                        <th class="Sorting-img">Name</th>
                                        <th class="Sorting-img">Lotto Number</th>
                                        <th class="Sorting-img">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of lotteryPurchaseListDataArray | paginate:{itemsPerPage:itemsPerPage, currentPage:currentPage, totalItems:totalItems}; let i = index"
                                        class="text-center">
                                        <td>{{itemsPerPage*(currentPage -1)+i+1}}</td>
                                        <td>{{item?.creationDate | date: 'mediumDate' || 'N/A'}}</td>
                                        <td class="content">{{item?.creationDate | date: 'mediumTime' || 'N/A'}}</td>
                                        <td class="content">{{item?.fkUserId || 'N/A'}}</td>
                                        <td class="content">{{item?.userName || 'N/A'}}</td>
                                        <td class="content">
                                            <div class="lottery-number-box">
                                                <span class="circle red-circle">{{item?.numberOne}}</span>
                                                <span class="circle red-circle">{{item?.numberTwo}}</span>
                                                <span class="circle red-circle">{{item?.numberThree}}</span>
                                                <span class="circle red-circle">{{item?.numberFour}}</span>
                                                <span class="circle red-circle">{{item?.numberFive}}</span>
                                                <span class="circle red-circle">{{item?.numberSix}}</span>
                                                <span style="border-left: 1px solid #000;"></span>
                                                <span class="circle bonus-num-bg-color">{{item?.numberBouns}}</span>
                                            </div>
                                        </td>
                                        <td class="content">
                                            <button class="btn btn-info" (click)="viewUser(item?.fkUserId)">
                                                <i class="fa fa-eye" style="color:white"></i></button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!lotteryPurchaseListDataArray.length">
                                        <td class="table-no-record" colspan="9">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 10">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
                        </div>
                        <!-- end table second -->


                    </div>
                </div>
            </div>
        </div>
    </main>
</div>


<!-- block user modal Start -->
<div class="modal fade global-modal reset-modal" id="resultNotDrawnModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Oops</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Results have not been drawn Yet !</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- end block user modal -->



<!-- ************************** calculator modal Start ************************************* -->
<div class="modal fade global-modal reset-modal" id="calculatorModalId">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h6 class="modal-title d-inline-block">Wallet Pool Balance-
                        {{amount?.WeeklyPoolWallet}} LTC</h6>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <!-- <p>Are you sure you want to active this user?</p> -->
                                <div class="input-style mb20 mt20 ">
                                    <form [formGroup]="lotteryCalculatorForm">
                                        <div class="row align-items-baseline view-label-align row-custom">
                                            <label class="col-md-4 textalignright">Admin(%) <span>:</span></label>
                                            <div class="col-md-8 textaligncenter">
                                                <div class="calculator-modal-input">
                                                    <input type="text" class="form-control form-control-modal"
                                                        placeholder="Enter Admin %" formControlName="adminPrice"
                                                        (keypress)="mainService.numOnly($event)"
                                                        (keyup)="checkPricePercentageModal($event)" maxlength="3">

                                                    <label
                                                        class="label-modal">{{viewPriceBreakDownData?.adminPriceAmount
                                                        || 0}} <span class="ml-2">LTC</span></label>
                                                </div>
                                                <div class="text-danger">
                                                    <span
                                                        *ngIf="lotteryCalculatorForm.get('adminPrice').hasError('required') && (lotteryCalculatorForm.get('adminPrice').dirty || lotteryCalculatorForm.get('adminPrice').touched)">
                                                        *Please enter admin %.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-baseline view-label-align row-custom">
                                            <label class="col-md-4 textalignright">1st Prize(%) <span>:</span></label>
                                            <div class="col-md-8 textaligncenter">
                                                <div class="calculator-modal-input">
                                                    <input type="text" class="form-control form-control-modal"
                                                        placeholder="Enter 1st Prize %" formControlName="firstPrice"
                                                        (keypress)="mainService.numOnly($event)"
                                                        (keyup)="checkPricePercentageModal($event)" maxlength="3">

                                                    <label
                                                        class="label-modal">{{viewPriceBreakDownData?.firstPriceAmount
                                                        || 0}} <span class="ml-2">LTC</span></label>
                                                </div>
                                                <div class="text-danger">
                                                    <span
                                                        *ngIf="lotteryCalculatorForm.get('firstPrice').hasError('required') && (lotteryCalculatorForm.get('firstPrice').dirty || lotteryCalculatorForm.get('firstPrice').touched)">
                                                        *Please enter 1st prize %.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-baseline view-label-align row-custom">
                                            <label class="col-md-4 textalignright">2nd Prize(%) <span>:</span></label>
                                            <div class="col-md-8 textaligncenter">
                                                <div class="calculator-modal-input">
                                                    <input type="text" class="form-control form-control-modal"
                                                        placeholder="Enter 2nd Prize %" formControlName="secondPrice"
                                                        (keypress)="mainService.numOnly($event)"
                                                        (keyup)="checkPricePercentageModal($event)" maxlength="3">

                                                    <label class="label-modal">{{viewPriceBreakDownData?.secPriceAmount
                                                        || 0}}<span class="ml-2">LTC</span></label>
                                                </div>
                                                <div class="text-danger">
                                                    <span
                                                        *ngIf="lotteryCalculatorForm.get('secondPrice').hasError('required') && (lotteryCalculatorForm.get('secondPrice').dirty || lotteryCalculatorForm.get('secondPrice').touched)">
                                                        *Please enter 2nd prize %.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-baseline view-label-align row-custom">
                                            <label class="col-md-4 textalignright">3rd Prize(%) <span>:</span></label>
                                            <div class="col-md-8 textaligncenter">
                                                <div class="calculator-modal-input">
                                                    <input type="text" class="form-control form-control-modal"
                                                        placeholder="Enter 3rd Prize %" formControlName="thirdPrice"
                                                        (keypress)="mainService.numOnly($event)"
                                                        (keyup)="checkPricePercentageModal($event)" maxlength="3">

                                                    <label
                                                        class="label-modal">{{viewPriceBreakDownData?.thirdPriceAmount
                                                        || 0}}<span class="ml-2">LTC</span></label>
                                                </div>
                                                <div class="text-danger">
                                                    <span
                                                        *ngIf="lotteryCalculatorForm.get('thirdPrice').hasError('required') && (lotteryCalculatorForm.get('thirdPrice').dirty || lotteryCalculatorForm.get('thirdPrice').touched)">
                                                        *Please enter 3rd prize %.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-baseline view-label-align row-custom">
                                            <label class="col-md-4 textalignright">4th Prize(%) <span>:</span></label>
                                            <div class="col-md-8 textaligncenter">
                                                <div class="calculator-modal-input">
                                                    <input type="text" class="form-control form-control-modal"
                                                        placeholder="Enter 4th Prize %" formControlName="fourthPrice"
                                                        (keypress)="mainService.numOnly($event)" maxlength="3"
                                                        (keyup)="checkPricePercentageModal($event)">

                                                    <label
                                                        class="label-modal">{{viewPriceBreakDownData?.fourthPriceAmount
                                                        || 0}}<span class="ml-2">LTC</span></label>
                                                </div>
                                                <div class="text-danger">
                                                    <span
                                                        *ngIf="lotteryCalculatorForm.get('fourthPrice').hasError('required') && (lotteryCalculatorForm.get('fourthPrice').dirty || lotteryCalculatorForm.get('fourthPrice').touched)">
                                                        *Please enter 4th prize %.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-baseline view-label-align row-custom">
                                            <label class="col-md-4 textalignright">5th Prize(%) <span>:</span></label>
                                            <div class="col-md-8 textaligncenter">
                                                <div class="calculator-modal-input">
                                                    <input type="text" class="form-control form-control-modal"
                                                        placeholder="Enter 5th Prize %" formControlName="fifthPrice"
                                                        (keypress)="mainService.numOnly($event)"
                                                        (keyup)="checkPricePercentageModal($event)" maxlength="3">

                                                    <label
                                                        class="label-modal">{{viewPriceBreakDownData?.fifthPriceAmount
                                                        || 0}}<span class="ml-2">LTC</span></label>
                                                </div>
                                                <div class="text-danger">
                                                    <span
                                                        *ngIf="lotteryCalculatorForm.get('fifthPrice').hasError('required') && (lotteryCalculatorForm.get('fifthPrice').dirty || lotteryCalculatorForm.get('fifthPrice').touched)">
                                                        *Please enter 5th prize %.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-btn-box d-flex">
                                    <button type="submit" class="btn btn-theme"
                                        [disabled]="lotteryCalculatorForm.invalid"
                                        (click)="updatePricePercentage()">Apply</button>
                                    <button type="submit" class="btn btn-theme"
                                        (click)="calculatePriceBreakDown()">Calculate</button>
                                    <button type="button" class="btn btn-red" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- end active user modal -->

<div class="modal fade global-modal reset-modal" id="sendRewardId">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h4 class="modal-title d-inline-block" style="font-size: 38px;">Alert</h4>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to send prize token</p>
                                <div class="modal-btn-box" style="margin-top:21px">
                                    <button type="button" class="btn btn-red " data-dismiss="modal">Cancel</button>
                                    <button type="submit" class="btn btn-theme ml-2" data-dismiss="modal" (click)="sendToUser()">Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade global-modal reset-modal" id="sendAll">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h4 class="modal-title d-inline-block" style="font-size: 38px;">Alert</h4>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to send Prize token to all the winners?</p>
                                <div class="modal-btn-box" style="margin-top:21px">
                                    <button type="submit" class="btn btn-theme" data-dismiss="modal" (click)="sendToAll()">Send</button>
                                    <button type="button" class="btn btn-red ml-2" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade global-modal reset-modal" id="AddJackpot">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h4 class="modal-title d-inline-block" style="font-size: 38px;">Update Jackpot</h4>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div>
                                <div style="display:-webkit-inline-box">
                                    <label for="" style="margin-top: 8px;">Jackpot Amount :</label>
                                    <input type="text" placeholder="Amount in LTC" class="form-control" style="margin-left: 10px;width: 235px;" [(ngModel)]="priceAmount" [ngModelOptions]="{ standalone: true }" (keypress)="mainService.OnlyNumAllowed($event)">
                                </div>
                        
                                    <div class="modal-btn-box" style="margin-top:21px">
                                        <button type="button" class="btn btn-red " data-dismiss="modal">Cancel</button>
                                        <button type="submit" class="btn btn-theme ml-2" data-dismiss="modal" (click)="editJackpot()" >Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal fade global-modal reset-modal" id="transfer">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h4 class="modal-title d-inline-block" style="font-size: 38px;">Transfer Winner Amount</h4>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body max-WT-700">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div>
                                <div style="display: flex;">
                                    <label for="" style="width: 198px;">Wallet Address </label>
                                   <p style="margin-left: auto;word-break: break-all;">: {{walletAddress?.walletAddress}} <i class="fa fa-copy myIcon" aria-hidden="true" style="margin-left: 2px;" (click)="copyToClipboard(walletAddress?.walletAddress)"></i></p>
                                   
                                </div>
                                <div style="display:-webkit-inline-box" class="mt-3">
                                    <label for="" style="margin-top: 8px;">Jackpot Amount :</label>
                                    <input type="text" placeholder="Amount in LTC" class="form-control" style="margin-left: 10px;width: 235px;" [(ngModel)]="priceAmount" [ngModelOptions]="{ standalone: true }" (keypress)="mainService.OnlyNumAllowed($event)">
                                </div>
                        
                                    <div class="modal-btn-box" style="margin-top:21px">
                                        <button type="button" class="btn btn-red " data-dismiss="modal">Cancel</button>
                                        <button type="submit" class="btn btn-theme ml-2" data-dismiss="modal" (click)="sendWinningAward()" >Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>