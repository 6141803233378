import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    console.log(value)
    switch (value) {
      case 'BLOCK':
        return 'Blocked';
      case 'ACTIVE':
        return 'Active';
      case 'PENDING':
        return 'Pending';
      case 'UNVERIFIED':
        return 'Unverified';
      default:
        return value;
    }
  }

}
