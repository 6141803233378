import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-payout-management',
  templateUrl: './payout-management.component.html',
  styleUrls: ['./payout-management.component.scss']
})
export class PayoutManagementComponent implements OnInit {
  payOutFaqDataArray: any[] = []
  payOutFaqId: any;
  // pagination
  itemsPerPage: number = 10;
  currentPage: number = 1;
  totalItems: any;
  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.getPayOutFaqList()
  }

  // ------------------------- get faq list -------------------------- //
  getPayOutFaqList() {
    // this.payOutFaqDataArray = this.mainService.faqDataArray
    this.mainService.showSpinner()
    let url = `static/admin/static-content/list-Guarenteed-Pay-Outs?page=${this.currentPage-1}&pageSize=${this.itemsPerPage}`
    this.mainService.postApi(url, {}, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.payOutFaqDataArray = res.data.list ? res.data.list : []
        this.totalItems = res.data.count ? res.data.count : 0
        this.mainService.hideSpinner();
      } else {
        this.payOutFaqDataArray = []
        this.totalItems = 0
        this.mainService.hideSpinner()
      }
    })
  }

  pagination(event) {
    this.currentPage = event
    this.getPayOutFaqList()
  }
  // -------------------------- navigate to add pay out faq ---------------------------- //
  addPayOutFaq() {
    this.router.navigate(['/add-payout'])
  }

  // ---------------------------- navigate to view pay out faq ------------------------------- //
  viewPayOutFaq(payOutFaqId) {
    console.log('id', payOutFaqId);
    this.router.navigate(['/view-payout'], { queryParams: { payOutFaqId: payOutFaqId } })
  }

  // ------------------------------- navigate to edit pay out faq --------------------------- //
  editPayOutFaq(payOutFaqId) {
    console.log('id', payOutFaqId);
    this.router.navigate(['/edit-payout'], { queryParams: { payOutFaqId: payOutFaqId } })
  }

  // ------------------------------- delete faq ----------------------------- //
  openDeletePayOutFaqModal(payOutFaqId) {
    $('#deletePayOutFaq').modal('show')
    this.payOutFaqId = payOutFaqId
  }
  deletePayOutFaq() {
    let url = `static/admin/static-content/delete-Guarenteed-Pay-Outs?payOutsId=${this.payOutFaqId}`
    this.mainService.showSpinner();
    this.mainService.postApi(url, {}, 1).subscribe((res: any) => {
      console.log("delete response ==>", res)
      $('#deletePayOutFaq').modal('hide');
      if (res.status == 200) {
        this.getPayOutFaqList()
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

}
