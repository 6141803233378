import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-management',
  templateUrl: './profile-management.component.html',
  styleUrls: ['./profile-management.component.scss']
})
export class ProfileManagementComponent implements OnInit {
  tabView: any = 'edit-profile';

  constructor() { }

  ngOnInit(): void {
  }

  // view tab
  viewTab(tab) {
    this.tabView = tab
  }

}
