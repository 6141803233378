import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-payout',
  templateUrl: './edit-payout.component.html',
  styleUrls: ['./edit-payout.component.scss']
})
export class EditPayoutComponent implements OnInit {
  editFaqForm: FormGroup
  payOutFaqId: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res.payOutFaqId) {
        this.payOutFaqId = res.payOutFaqId
      }
    })
    this.editFaqFormValidation();
    this.viewFaq()
  }

  // ------------------ edit pay out faq form validation ------------------------- //
  editFaqFormValidation() {
    this.editFaqForm = new FormGroup({
      'answer': new FormControl('', Validators.required),
      'question': new FormControl('', Validators.required)
    })
  }

  // -------------------------- view pay out faq ------------------------ //
  viewFaq() {
    this.mainService.showSpinner();
    let url = `static/admin/static-content/view-Guarenteed-Pay-Outs?payOutsId=${this.payOutFaqId}`
    this.mainService.postApi(url, {}, 1).subscribe((res: any) => {
      console.log("view faq response ==>", res);
      if (res.status == 200) {
        this.editFaqForm.patchValue({
          'question': res.data.question ? res.data.question : '',
          'answer': res.data.question ? res.data.answer : ''
        })
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    })
  }

  // -------------------------- edit pay out faq ---------------------------- //
  editFaq() {
    let data = {
      'answer': this.editFaqForm.value.answer,
      'question': this.editFaqForm.value.question,
      "payOutsId":this.payOutFaqId,
      "isDeleted": false
    }
    console.log(data)
    let url = `static/admin/static-content/update-Guarenteed-Pay-Outs`
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log("edit faq response ==>", res)
      if (res.status == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message)
        this.router.navigate(['/faq-payout-management'], { queryParams: { tabView: 'PAY_OUT_FAQ' } })
      } else {
        console.log("else error")
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // ------------------------ navigate back -------------------------- //
  back() {
    this.router.navigate(['/faq-payout-management'], { queryParams: { tabView: 'PAY_OUT_FAQ' } })
  }

}
