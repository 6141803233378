import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  resetToken: any;

  constructor(private activatedRoute: ActivatedRoute, public mainService: MainService, public router: Router) { }

  ngOnInit() {
    this.resetPasswordForm = new FormGroup({
      "password": new FormControl('', ([Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)])),
      "confirmPassword": new FormControl('', [Validators.required])
    });
    if (this.activatedRoute['queryParams']['value'] && this.activatedRoute['queryParams']['value'].token) {
      this.resetToken = this.activatedRoute['queryParams']['value'].token
    }
  }

//  reset password 
  resetPassword() {
    if (this.resetPasswordForm.valid) {
      const data = {
        password: this.resetPasswordForm.value.password,
        token: this.resetToken
      }
      this.mainService.showSpinner()
      this.mainService.postApi('account/reset-password', data, 0).subscribe((res: any) => {
        console.log('reset password response ==>', res);
        if (res.status == 200) {
          this.mainService.hideSpinner();
          this.mainService.successToast('Reset password successfully.')
          this.router.navigate(['/login'])
        } else {
          this.mainService.hideSpinner();
          this.mainService.errorToast(res.message)
        }
      })
    }
    else {
      console.log('Email address is required.');
      this.mainService.errorToast('Email address is required.')
    }
  }

}