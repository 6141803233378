<div class="login-wrapper">
    <div class="container-common">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="d-flex align-items-center minheight70vh">
                    <form class="login_box_outer w-100" [formGroup]="resetForm">
                        <div class="login-box max-WT-520">
                            <div class="login-right-block">
                                <div class="login-heading">
                                    <h4>FORGOT PASSWORD</h4>
                                </div>
                                <div class="login-box-body">
                                    <p class="common-paragrph text-center">Enter your registered email address. We will
                                        send reset link.</p>
                                    <div class="form-group">
                                        <input type="email" class="form-control" name="email" formControlName="email"
                                            placeholder="Enter Email Address" maxlength="60"
                                            (keypress)="mainService.preventSpace($event)" />
                                        <div *ngIf="f.email.errors && (f.email.touched || f.email.dirty)"
                                            class="text-danger">
                                            <span *ngIf="f.email.hasError('required') "> *Email address is required.</span>
                                            <span *ngIf="f.email.hasError('pattern')"> *Please enter valid email
                                                address.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt40 admin-profile">
                                    <button type="button" class="btn btn-login btn-large  width100 font-100"
                                        [disabled]="!resetForm.valid" (click)="submit()">SUBMIT</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- otp modal -->
<div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Please Enter OTP</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</div>