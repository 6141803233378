import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.scss']
})
export class EditFaqComponent implements OnInit {
  editFaqForm: FormGroup
  faqId: any;
  titleKey: any;
  faqData: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res.faqId) {
        this.faqId = res.faqId
        this.titleKey = res.titleKey
      }
    })
    this.editFaqFormValidation();
    this.viewFaq()
  }

  // ------------------ edit faq form validation ------------------------- //
  editFaqFormValidation() {
    this.editFaqForm = new FormGroup({
      'answer': new FormControl('', Validators.required),
      'question': new FormControl('', Validators.required)
    })
  }

  // -------------------------- view faq ------------------------ //
  viewFaq() {
    this.mainService.showSpinner();
    let url = `static/admin/static-content/get-FAQ-by-id?faqId=${this.faqId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log("view faq response ==>", res);
      if (res.status == 200) {
        this.faqData = res.data
        this.editFaqForm.patchValue({
          'question': res.data.question ? res.data.question : '',
          'answer': res.data.question ? res.data.answer : ''
        })
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    })
  }

  // -------------------------- edit faq ---------------------------- //
  editFaq() {
    let data = {
      "faqId": this.faqId,
      'answer': this.editFaqForm.value.answer,
      'question': this.editFaqForm.value.question,
      "isDeleted": false,
      "topicKey": this.titleKey 
    }
    console.log(data)
    let url = `static/admin/static-content/update-FAQ-by-id`
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log("edit faq response ==>", res)
      if (res.status == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message)
        this.router.navigate(['/faq-payout-management'], { queryParams: { tabView: 'FAQ', titleKey: this.titleKey } })
      } else {
        console.log("else error")
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // ------------------------ naviagte back -------------------------- //
  back() {
    this.router.navigate(['/faq-payout-management'], { queryParams: { tabView: 'FAQ', titleKey: this.titleKey } })
  }

}
