<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Transaction Management</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div class="sec_head_new sec_head_new_after">
                             
                                <div class="row">
                                    <div class="col-md-2">
                                        
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="picker" placeholder="From date" [(ngModel)]="fromDate" [max]="today" >
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                          </mat-form-field>
                                    </div>
                                    <div class="col-md-2">
                     
                                            <mat-form-field appearance="outline">
                                           
                                                <input  matInput [matDatepicker]="picker1" placeholder="To date" [(ngModel)]="toDate" [min]="fromDate"
                                                [disabled]="!fromDate" >
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                              </mat-form-field>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-2">
                                        <div class="filter_search mb20 width100">
                                            <div class="input-group filter_search_group">
                                                <input type="text" class="form-control overflow-text myinput"
                                                    [(ngModel)]="search" placeholder="Search by Transaction ID "
                                                    maxlength="60">
                                                <div class="input-group-append">
                                                    <button class="btn btn_search_group" type="button"><img
                                                            src="assets/img/icon-search.png" alt="Search"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-4">
                                        <div class="text-center admin-btn">
                                            <button type="button" class="btn  btn-theme Go_btn"
                                                (click)="searchEvent()">Search</button>
                                            <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                (click)="resetEvent()">Reset</button>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2">
                                        <div class="justify-content-end">
                                            <button type="button" class="btn btn-theme Go_btn " style="padding-left: 0.65rem;
                                                padding-right: 0.65rem;" (click)="exportToExcel()">Export To
                                                Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- </form> -->
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th class="Sorting-img">S.No.</th>
                                        <th class="Sorting-img">Transaction ID</th>
                                        <th class="Sorting-img">Transaction Type</th>
                                        <th class="Sorting-img">Currency Type</th>
                                        <th class="Sorting-img">Amount</th>
                                        <th class="Sorting-img">Transaction Hash</th>
                                        <th class="Sorting-img">Status</th>
                                        <th class="Sorting-img">Transaction Date & Time</th>
                                        <th class="action_td_btn3 Sorting-img">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of transactionHistoryDataArray | paginate:{itemsPerPage:itemsPerPage, currentPage:currentPage, totalItems:totalItems}; let i = index"
                                        class="text-center">
                                        <td>{{itemsPerPage*(currentPage -1)+i+1}}</td>
                                        <td class="content">{{item?.txnId || 'N/A'}}</td>
                                        <td class="content">{{item?.txnType || 'N/A'}}</td>
                                        <td class="content">{{item?.coinType || 'N/A'}}</td>
                                        <td class="content">{{item?.amount || 'N/A'}}</td>
                               
                                        <td>

                                      
                                        <div  *ngIf="item?.txnHash!=null">
                                            {{(item?.txnHash) | shorten :20}}   <i class="fa fa-copy myIcon1" aria-hidden="true"  
                                            (click)="copyToClipboard(item?.txnHash)"></i>
                                          </div>
                                          <span  *ngIf="item?.txnHash==null">
                                           N/A
                                          </span>

                                        </td>
                                        <td class="content">{{item?.status || 'N/A'}}</td>
                                        <td class="content">{{(item?.txnTime | date:'medium') || 'N/A'}}</td>
                                        <td>
                                            <div class="table-action-btn-alignment">
                                                <button class="btn btn-info" title="view"
                                                    (click)="view(item?.txnId)">
                                                    <i class="fa fa-eye" style="color:white"></i></button>
                                        
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!transactionHistoryDataArray?.length">
                                        <td class="table-no-record" colspan="9">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 10">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>


<!-- block transaction modal Start -->
<div class="modal fade global-modal reset-modal" id="blockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Block Transaction?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to block this transaction?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme" (click)="block()">Yes</button>
                                    <button type="button" class="btn btn-red ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- end transaction user modal -->