import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/constants/end-point';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-jackpot-lottery-management',
  templateUrl: './jackpot-lottery-management.component.html',
  styleUrls: ['./jackpot-lottery-management.component.scss']
})
export class JackpotLotteryManagementComponent implements OnInit {
  lotteryForm: FormGroup
  file: any;
  imageType: any;
  lotteryImageUrl: any;
  addLotteryReq: boolean = true;
  lotteryId: number;
  lotteryCalculatorForm: FormGroup;
  viewPriceBreakDownData: any;
  weeklyPoolWallet: number;
  // pagination
  currentPage = 1;
  itemsPerPage = 2;
  totalItems: any;
  latestDrawListDataArray: any[] = [];
  closedDrawListDataArray: any[] = [];
  lotteryPricePercentageFields = ['adminPrice', 'firstPrice', 'secondPrice', 'thirdPrice', 'fourthPrice', 'fifthPrice'];// to total lottery percentage

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log("lotteryId->", res);
      if (res.lotteryId) {
        this.lotteryId = res.lotteryId
        this.addLotteryReq = false;
        this.getLottery()
      }
    })
    this.lotteryFormValidation();
    this.lotteryCalculatorFormValidation()
    this.getLatestDrawList(); // recent lottery
    this.getClosedDrawList(); // previous lottery
  }

  lotteryFormValidation() {
    this.lotteryForm = new FormGroup({
      // 'lotteryName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'lotteryName': new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(60)]),
      'adminPrice': new FormControl('', Validators.required),
      'firstPrice': new FormControl('', Validators.required),
      'secondPrice': new FormControl('', Validators.required),
      'thirdPrice': new FormControl('', Validators.required),
      'fourthPrice': new FormControl('', Validators.required),
      'fifthPrice': new FormControl('', Validators.required),
      'salePrice': new FormControl('', Validators.required),

      'startDateTime': new FormControl('', Validators.required),
      'endDateTime': new FormControl('', [Validators.required]),
      'resultDateTime': new FormControl('', Validators.required)
    }, [this.startEndDateValidator, this.endResultDateValidator])
  }

  startEndDateValidator(g: FormGroup) {
    let startDateTime = g.get('startDateTime');
    let endDateTime = g.get('endDateTime');
    if (endDateTime.value) {
      if (startDateTime.value > endDateTime.value) {
        g.get('endDateTime').setErrors({ dateLessThen: true });
      } else {
        g.get('endDateTime').setErrors(null);
        return null;
      }
    }
  }

  endResultDateValidator(g: FormGroup) {
    let endDateTime = g.get('endDateTime');
    let resultDateTime = g.get('resultDateTime')
    if (resultDateTime.value) {
      if (endDateTime.value > resultDateTime.value) {
        g.get('resultDateTime').setErrors({ dateLessThen: true });
      } else {
        g.get('resultDateTime').setErrors(null)
        return null;
      }
    }
  }

  // ---------------- add new lottery ----------------------- //
  newLottery() {
    console.log('newLottery call')
    this.addLotteryReq = true
    this.lotteryForm.reset()
  }

  /**
   * on form submit it will check for the variable that @addLotteryReq is true or false
   * if @addLotteryReq get true the addLottery function will call else editLottery function will call
   */

  lotteryFormSubmit() {
    if (this.checkLotteryPricePercentage('event')) {
      if (this.addLotteryReq) {
        this.addLotteryFormSubmit()
      } else {
        this.editLotteryFormSubmit()
      }
    }
  }


  // add lottery
  addLotteryFormSubmit() {
    let data = {
      'lotteryStatus': 'OPEN',
      'lotteryImageUrl': this.lotteryImageUrl,
      'lotteryName': this.lotteryForm.value.lotteryName,
      'adminPrice': Number(this.lotteryForm.value.adminPrice),
      'firstPrice': Number(this.lotteryForm.value.firstPrice),
      'secondPrice': Number(this.lotteryForm.value.secondPrice),
      'thirdPrice': Number(this.lotteryForm.value.thirdPrice),
      'fourthPrice': Number(this.lotteryForm.value.fourthPrice),
      'fifthPrice': Number(this.lotteryForm.value.fifthPrice),
      'salePrice': Number(this.lotteryForm.value.salePrice),

      'startDateTime': new Date(this.lotteryForm.value.startDateTime).getTime(),
      'endDateTime': new Date(this.lotteryForm.value.endDateTime).getTime(),
      'resultDateTime': new Date(this.lotteryForm.value.resultDateTime).getTime()
    }
    console.log(data)
    let url = ApiEndPoint.addLottery;
    this.mainService.showSpinner()
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.mainService.hideSpinner()
        this.mainService.successToast('Lottery added successfully.')
        this.lotteryForm.reset();
        this.getLatestDrawList(); // recent lottery
        this.getClosedDrawList();
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast('Something went wrong.')
      }
    })
  }

  // edit lottery
  editLotteryFormSubmit() {
    let data = {
      'lotteryId': this.lotteryId,
      'lotteryImageUrl': this.lotteryImageUrl,
      'lotteryName': this.lotteryForm.value.lotteryName,
      'adminPrice': Number(this.lotteryForm.value.adminPrice),
      'firstPrice': Number(this.lotteryForm.value.firstPrice),
      'secondPrice': Number(this.lotteryForm.value.secondPrice),
      'thirdPrice': Number(this.lotteryForm.value.thirdPrice),
      'fourthPrice': Number(this.lotteryForm.value.fourthPrice),
      'fifthPrice': Number(this.lotteryForm.value.fifthPrice),
      'salePrice': Number(this.lotteryForm.value.salePrice),

      'startDateTime': new Date(this.lotteryForm.value.startDateTime).getTime(),
      'endDateTime': new Date(this.lotteryForm.value.endDateTime).getTime(),
      'resultDateTime': new Date(this.lotteryForm.value.resultDateTime).getTime()
    }
    console.log(data)
    let url = ApiEndPoint.editLottery;
    this.mainService.showSpinner()
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.mainService.hideSpinner()
        this.mainService.successToast('Lottery updated successfully.')
        this.lotteryForm.reset();
        this.getLatestDrawList(); // recent lottery
        this.getClosedDrawList();
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast('Something went wrong.')
      }
    })
  }


  /**
   * on click on card portion 
   * editLottery function 
   * patch lottery data in form
   */
  editLottery(lotteryId) {
    console.log("editLottery call")
    this.lotteryId = lotteryId
    this.addLotteryReq = false
    this.getLottery()
  }

  // ------------------------ get lottery details on base of lottery id ------------------- //
  getLottery() {
    let url = `${ApiEndPoint.viewLottery}?lotteryId=${this.lotteryId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.lotteryImageUrl = res.data.lotteryImageUrl ? res.data.lotteryImageUrl : ''
        this.lotteryForm.patchValue({
          'lotteryName': res.data.lotteryName ? res.data.lotteryName : '',
          'adminPrice': res.data.adminPrice ? res.data.adminPrice : '',
          'firstPrice': res.data.firstPrice ? res.data.firstPrice : '',
          'secondPrice': res.data.secondPrice ? res.data.secondPrice : '',
          'thirdPrice': res.data.thirdPrice ? res.data.thirdPrice : '',
          'fourthPrice': res.data.fourthPrice ? res.data.fourthPrice : '',
          'fifthPrice': res.data.fifthPrice ? res.data.fifthPrice : '',
          'salePrice': res.data.salePrice ? res.data.salePrice : '',

          'startDateTime': res.data.startDateTime ? res.data.startDateTime.split('.')[0] : '',
          'endDateTime': res.data.endDateTime ? res.data.endDateTime.split('.')[0] : '',
          'resultDateTime': res.data.resultDateTime ? res.data.resultDateTime.split('.')[0] : '',
        })
        window.scrollTo(0, 0);
      }
    })
  }

  checkLotteryPricePercentage(event) {
    // if (event.target.value) {
    var totalLotteryPricePercentage: number = 0;
    for (const fields of this.lotteryPricePercentageFields) {
      totalLotteryPricePercentage = totalLotteryPricePercentage + Number(this.lotteryForm.controls[fields].value)
    }
    console.log(totalLotteryPricePercentage)
    if (totalLotteryPricePercentage <= 100) {
      return true
    } else {
      this.mainService.infoToast(`Percentage can't be greater then 100.`)
      return false
    }
    // }
  }
  // ------------- image upload ----------- //
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.mainService.showSpinner();
    this.mainService.fileUploadPostApi(ApiEndPoint.uploadFile, fb).subscribe(res => {
      this.mainService.hideSpinner();
      if (res['status'] == '200') {
        this.lotteryImageUrl = res['data'];
      }
    })
  }

  /**
   * Calculator
   */

  lotteryCalculatorFormValidation() {
    this.lotteryCalculatorForm = new FormGroup({
      'adminPrice': new FormControl('', Validators.required),
      'firstPrice': new FormControl('', Validators.required),
      'secondPrice': new FormControl('', Validators.required),
      'thirdPrice': new FormControl('', Validators.required),
      'fourthPrice': new FormControl('', Validators.required),
      'fifthPrice': new FormControl('', Validators.required),
    })
  }

  openCalculatorModal() {
    $('#calculatorModalId').modal('show')
    this.patchPricePercentageInModal()
  }

  patchPricePercentageInModal() {
    this.lotteryCalculatorForm.patchValue({
      'adminPrice': this.lotteryForm.value.adminPrice ? this.lotteryForm.value.adminPrice : '',
      'firstPrice': this.lotteryForm.value.firstPrice ? this.lotteryForm.value.firstPrice : '',
      'secondPrice': this.lotteryForm.value.secondPrice ? this.lotteryForm.value.secondPrice : '',
      'thirdPrice': this.lotteryForm.value.thirdPrice ? this.lotteryForm.value.thirdPrice : '',
      'fourthPrice': this.lotteryForm.value.fourthPrice ? this.lotteryForm.value.fourthPrice : '',
      'fifthPrice': this.lotteryForm.value.fifthPrice ? this.lotteryForm.value.fifthPrice : '',
    })
    this.viewPriceBreakDown();
  }


  viewPriceBreakDown() {
    this.mainService.showSpinner();
    let url = `account/admin/user-management/view-price-break-down?lotteryId=${this.lotteryId}`
    this.mainService.postApi(url, {}, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.weeklyPoolWallet = res.data.WeeklyPoolWallet ? res.data.WeeklyPoolWallet : 0
        this.viewPriceBreakDownData = res.data ? res.data : '';
        this.mainService.hideSpinner();
      } else {
        this.viewPriceBreakDownData = '';
        this.mainService.hideSpinner();
      }
    })
  }

  calculatePriceBreakDown() {
    if (this.lotteryCalculatorForm.invalid) {
      return this.mainService.infoToast('Please provide valid percentage.')
    } else if (this.checkPricePercentageModal('event')) {
      let apiReqData = {
        'adminWinPercentage': Number(this.lotteryCalculatorForm.value.adminPrice),
        'firstWinPercentage': Number(this.lotteryCalculatorForm.value.firstPrice),
        'secondWinPercentage': Number(this.lotteryCalculatorForm.value.secondPrice),
        'thirdWinPercentage': Number(this.lotteryCalculatorForm.value.thirdPrice),
        'fourthWinPercentage': Number(this.lotteryCalculatorForm.value.fourthPrice),
        'fifthWinPercentage': Number(this.lotteryCalculatorForm.value.fifthPrice)
      }
      let url = `account/admin/user-management/calulate-price-break-down?lotteryId=${this.lotteryId}`
      this.mainService.showSpinner()
      this.mainService.postApi(url, apiReqData, 1).subscribe((res: any) => {
        console.log(res)
        if (res.status == 200) {
          this.viewPriceBreakDownData = res.data ? res.data : '';
          this.mainService.hideSpinner();
        } else {
          this.viewPriceBreakDownData = '';
          this.mainService.hideSpinner();
        }
      })
    }
  }

  updatePricePercentage() {
    if (this.lotteryCalculatorForm.invalid) {
      return this.mainService.infoToast('Please provide valid percentage.')
    } else if (this.checkPricePercentageModal('event')) {
      $('#calculatorModalId').modal('hide')
      this.lotteryForm.patchValue({
        'adminPrice': this.lotteryCalculatorForm.value.adminPrice ? this.lotteryCalculatorForm.value.adminPrice : this.lotteryForm.value.adminPrice,
        'firstPrice': this.lotteryCalculatorForm.value.firstPrice ? this.lotteryCalculatorForm.value.firstPrice : this.lotteryForm.value.firstPrice,
        'secondPrice': this.lotteryCalculatorForm.value.secondPrice ? this.lotteryCalculatorForm.value.secondPrice : this.lotteryForm.value.secondPrice,
        'thirdPrice': this.lotteryCalculatorForm.value.thirdPrice ? this.lotteryCalculatorForm.value.thirdPrice : this.lotteryForm.value.thirdPrice,
        'fourthPrice': this.lotteryCalculatorForm.value.fourthPrice ? this.lotteryCalculatorForm.value.fourthPrice : this.lotteryForm.value.fourthPrice,
        'fifthPrice': this.lotteryCalculatorForm.value.fifthPrice ? this.lotteryCalculatorForm.value.fifthPrice : this.lotteryForm.value.fifthPrice,
      })
    }
  }

  checkPricePercentageModal(event) {
    // if (event.target.value) {
    var totalPricePercentage: number = 0;
    for (const fields in this.lotteryCalculatorForm.controls) {
      totalPricePercentage = totalPricePercentage + Number(this.lotteryCalculatorForm.controls[fields].value)
    }
    console.log(totalPricePercentage)
    if (totalPricePercentage <= 100) {
      return true
    } else {
      this.mainService.infoToast(`Percentage can't be greater then 100.`)
      return false
    }
    // }
  }



  
  /** 
   * **************************************************************************
   * recent lottery and previous lottery 
   */

  // ------------------- recent lottery list ----------------------- //
  getLatestDrawList() {
    this.mainService.showSpinner();
    let url = `account/find-lottery-without-result-latest-draws`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.latestDrawListDataArray = res.data.latestLotteryData ? res.data.latestLotteryData : [];
        this.mainService.hideSpinner();
      } else {
        this.latestDrawListDataArray = [];
        this.mainService.hideSpinner();
      }
    })
  }


  // ------------------- previous lottery list ----------------------- //
  getClosedDrawList() {
    let url = `account/find-lottery-with-result-closed-draws?page=${(this.currentPage - 1) + '&pagesize=' + (this.itemsPerPage)}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.closedDrawListDataArray = res.data.closeLotteryData ? res.data.closeLotteryData : [];
        this.totalItems = res.data.count
      } else {
        this.closedDrawListDataArray = []
        this.totalItems = 0
      }
    }, (err: any) => {
      console.log(err)
      this.closedDrawListDataArray = []
      this.totalItems = 0
    })
  }

  // ----------------- pagination --------------------- //
  pagination(event) {
    this.currentPage = event;
    this.getClosedDrawList()
  }

}
