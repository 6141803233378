import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.scss']
})
export class AddFaqComponent implements OnInit {
  addFaqForm: FormGroup
  titleKey: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService) {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res)
      if (res.titleKey) {
        this.titleKey = res.titleKey
      }
    })
  }

  ngOnInit() {
    this.addFaqFormValidation();
  }

  // ------------- add faq form validation --------------------- //
  addFaqFormValidation() {
    this.addFaqForm = new FormGroup({
      'answer': new FormControl('', Validators.required),
      'question': new FormControl('', Validators.required)
    })
  }

  // ----------------------- add faq -------------------------- //
  addFaq() {
    let data = {
      "answer": this.addFaqForm.value.answer,
      // "faqId": 0,
      "isDeleted": false,
      "question": this.addFaqForm.value.question,
      "topicKey": this.titleKey 
    }
    let url = `static/admin/static-content/add-new-FAQ`
    console.log(data)
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message)
        this.router.navigate(['/faq-payout-management'], { queryParams: { tabView: 'FAQ', titleKey: this.titleKey } })
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // ------------------------- navigate back ----------------------- //
  back() {
    this.router.navigate(['/faq-payout-management'], { queryParams: { tabView: 'FAQ', titleKey: this.titleKey } })
  }

}
