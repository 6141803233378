<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Dashboard</h1>
        </div>
        <div class="content-section dashboard-block">
            <ul class="dash_list d-flex w-100 flex-wrap text-center">
                <li class="red" >
                    <div class="dash_icon">
                        <i class="fa fa-users" aria-hidden="true"></i>
                    </div>
                
                    <h2>User's Viewed Homepage</h2>
                    <h4>{{dashboardData?.userViewedHomepage || 0}}</h4>
                </li>
                <li class="red">
                    <div class="dash_icon">
                        <i class="fa fa-users" aria-hidden="true"></i>
                    </div>
                   
                    <h2>Total User's Signed Up</h2>
                    <h4>{{dashboardData?.userSignedUp || 0}}</h4>
                </li>
                <li class="red">
                    <div class="dash_icon">
                        <i class="fa fa-suitcase" aria-hidden="true"></i>
                    </div>
                    <h2>Admin Wallet</h2>
                    <h4>{{dashboardData?.adminWallet || 0}}</h4>
                </li>
                <li class="red">
                    <div class="dash_icon">
                        <i class="fa fa-university" aria-hidden="true"></i>
                    </div>
                    <h2>Total Earning</h2>
                    <h4>{{dashboardData?.totalEarning || 0}}</h4>
                </li>
            </ul>
            <ul class="dash_list d-flex w-100 flex-wrap text-center">
          
      
            </ul>
            <div class="admin-profile" style="margin-left: 2%;margin-top: -10px;">
                <h4 class="mb20">Quick Links</h4>
            </div>
            <ul class="dash_list d-flex w-100 flex-wrap text-center">
                <li class="red" [routerLink]="['/lottery-results']" style="cursor:pointer">
                    <div class="dash_icon">
                        <i class="fa fa-ticket" aria-hidden="true"></i>
                    </div>
                    <h2>Lottery Results</h2>
            
                </li>
         
                <li class="green" [routerLink]="['/referral-management']" style="cursor:pointer">
                    <div class="dash_icon">
                        <i class="fa fa-user-plus" aria-hidden="true"></i>
                    </div>
                    <h2>Referral Management</h2>
            
                </li>
            </ul>
  
        </div>


        <!-- -------------------------------------------- card section ----------------------------------- -->
        
        <div class="order-view  mrgn-0-auto setting-page" style="box-shadow: none;">
            <div class="">
                <div class="admin-profile" style="margin-top: -30px;">
                    <h4 class="mb20">Recently Added Lottories</h4>
                </div>
                <div class="input-style view-label-align">
                    <div *ngIf="recentlyAddedLotteryArray" class="row">
                        <!-- left row -->
                        <div *ngFor="let item of recentlyAddedLotteryArray; let i = index" class="col-md-6">
                            <div *ngIf="i < 2" class="card">
                                <h5 class="card-title">{{item?.lotteryName || 'Lottery'}}</h5>
                                <div class="row mr0 mL0">
                                    <div class="col-sm-5">
                                        <div class="card-left-image">
                                            <img class="d-block w-100" [src]="item?.lotteryImageUrl || 'https://picsum.photos/150?image=641'" alt="">
                                        </div>
                                    </div>
                                    <div class="col-sm-7">
                                        <div class="card-block">
                                            <h6 class="text-center">USD {{item?.salePrise}}</h6>
                                            <div class="card-date-time"><span>Draw Date :</span> <span>{{item?.resultDateTime | date:'mediumDate'}}</span></div>
                                            <div class="card-date-time"><span>Draw Time :</span> <span>{{item?.resultDateTime | date:'mediumTime'}}</span>
                                            </div>
                                            <div class="card-btn-container mt10">
                                         
                                                <a class="btn btn-sm card-btn mL10" (click)="viewLottery(item?.lotteryId, 'OPEN')">View
                                                    Details</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end left row  -->

                   
                       
                      
                    </div>
                    <!-- when lottery data not found -->
                    <div *ngIf="!recentlyAddedLotteryArray.length">
                        <div class="col-md-12">
                            <div class="card">
                                <h5 class="card-title">No Record Found</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- end card section -->
    </main>
</div>