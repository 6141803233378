import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dashboardData: any = {
    userViewedHomepage: 0,
    userSignedUp: 0,
    adminWallet: 0,
    totalPayOutToUser: 0,
    transactionFeeEarned: 0,
    totalEarning: 0,
    totalReferralEarning: 0,
    totalFundTransfer: 0
  }

  recentlyAddedLotteryArray: any[] = []

  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit(): void {
    this.getUserViewedHomepage()
    this.getUserSignedUp()
    this.getAdminWallet()
    // this.getTotalPayoutToUser()
    // this.getTransactionFeeEarned()
    this.getTotalEarning()
    // this.getReferralEarning()
    // this.getTotalFundTransfer()

    this.getLotteryCardList() // recently added lottery
  }

  // ----------- get all user view homepage ------------------------ //
  getUserViewedHomepage() {
    this.mainService.getApi('account/get-total-home-page-matrics', 1).subscribe((res: any) => {
      console.log("homepage viewed response",res)
      if (res.status == 200) {
        this.dashboardData.userViewedHomepage = Number(res.data)
      } else {
        this.dashboardData.userViewedHomepage = 0
      }
    })
  }

  getUserSignedUp() {
    this.mainService.getApi('account/admin/dashboard/get-all-signed-up-user', 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.dashboardData.userSignedUp = Number(res.data)
        console.log(this.dashboardData.userSignedUp)
      } else {
        this.dashboardData.userSignedUp = 0
      }
    })
  }

  getAdminWallet() {
    this.mainService.getApi('account/admin/dashboard/admin-wallet', 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.dashboardData.adminWallet = Number(res.data.adminWallet)
      } else {
        this.dashboardData.adminWallet = 0
      }
    })
  }

  getTotalPayoutToUser() {
    this.mainService.getApi('account/admin/dashboard/total-payout-to-user', 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.dashboardData.totalPayOutToUser = Number(res.data.TotalPayOutToUser)
      } else {
        this.dashboardData.totalPayOutToUser = 0
      }
    })
  }

  getTransactionFeeEarned() {
    this.mainService.getApi('account/admin/dashboard/total-transaction-fee-earned', 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.dashboardData.transactionFeeEarned = Number(res.data.transactionFeeEarned)
      } else {
        this.dashboardData.transactionFeeEarned = 0
      }
    })
  }

  getTotalEarning() {
    this.mainService.getApi('account/admin/dashboard/total-earning', 1).subscribe(res => {
      if (res.status == 200) {
        this.dashboardData.totalEarning = Number(res.data)
      } else {
        this.dashboardData.totalEarning = 0
      }
    })
  }

  getReferralEarning() {
    this.mainService.getApi('account/admin/dashboard/referral-earning', 1).subscribe(res => {
      if (res.status == 200) {
        this.dashboardData.totalReferralEarning = Number(res.data.TotalReferralEarning)
      } else {
        this.dashboardData.totalReferralEarning = 0
      }
    })
  }

  getTotalFundTransfer() {
    this.mainService.getApi('account/admin/dashboard/total-fund-transfer', 1).subscribe(res => {
      if (res.status == 200) {
        this.dashboardData.totalFundTransfer = Number(res.data.TotalFundTransfer)
      } else {
        this.dashboardData.totalFundTransfer = 0
      }
    })
  }


  /**
   * *****************************************************************************
   * card section 
   * get lottry card list of recently added lottery
   * @param lotteryId 
   * edit and view lottery using lottery id
   */
  //  https://java-cryptocasino.mobiloitte.com/account/swagger-ui.html#/admin-lottery-controller/findLotteryWithoutResultUsingGET
  //  // https://java-cryptocasino.mobiloitte.com/account/find-lottery-without-result-latest-draws
  getLotteryCardList() {
    // let url = `account/admin/dashboard/find-list-lottery-event`
    let url = `account/find-lottery-without-result-latest-draws`
    this.mainService.getApi(url, 1).subscribe(res => {
      console.log(res)
      if (res.status == 200) {
        this.recentlyAddedLotteryArray = res.data.latestLotteryData ? res.data.latestLotteryData : []
      } else {
        this.recentlyAddedLotteryArray = []
      }
    })
  }

  // ---------------- navigate to edit lottery -------------------- //
  editLottery(lotteryId) {
    this.router.navigate(['/jackpot-lottery-management'], { queryParams: { lotteryId: lotteryId } })
  }

  // ---------------- navigate to view lottery --------------------- //
  viewLottery(lotteryId, lotteryDrawType) {
    this.router.navigate(['/view-lottery-details'], { queryParams: { lotteryId: lotteryId, lotteryDrawType: lotteryDrawType } })
  }

  // viewLottery(){
  //   // https://java-cryptocasino.mobiloitte.com/account/admin/dashboard/find-lottery-event-by-id?lotteryId=3243
  // }

}
