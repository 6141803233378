import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from 'ngx-pagination';
import { CKEditorModule } from 'ngx-ckeditor';
// component
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/authentication/login/login.component';
import { ForgotPasswordComponent } from './pages/authentication/forgot-password/forgot-password.component';
import { SidebarComponent } from './pages/shared/sidebar/sidebar.component';
import { DashboardComponent } from './pages/dashboard-layout/dashboard/dashboard.component';
import { UserManagementComponent } from './pages/dashboard-layout/user-management/user-management/user-management.component';
import { HeaderComponent } from './pages/shared/header/header.component';
import { ResetPasswordComponent } from './pages/authentication/reset-password/reset-password.component';
import { ProfileManagementComponent } from './pages/dashboard-layout/profile-management/profile-management/profile-management.component';
import { EditProfileComponent } from './pages/dashboard-layout/profile-management/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './pages/dashboard-layout/profile-management/change-password/change-password.component';
import { ViewUserDataComponent } from './pages/dashboard-layout/user-management/view-user/view-user-data/view-user-data.component';
import { ViewGeneralUserDataComponent } from './pages/dashboard-layout/user-management/view-user/view-general-user-data/view-general-user-data.component';
import { ViewWalletUserDataComponent } from './pages/dashboard-layout/user-management/view-user/view-wallet-user-data/view-wallet-user-data.component';
import { ViewWalletTransactionUserDataComponent } from './pages/dashboard-layout/user-management/view-user/view-wallet-transaction-user-data/view-wallet-transaction-user-data.component';
import { EditUserComponent } from './pages/dashboard-layout/user-management/edit-user/edit-user.component';
import { LotteryPurchaseDataComponent } from './pages/dashboard-layout/lottery-management/lottery-purchase-data/lottery-purchase-data.component';
import { TransactionHistoryComponent } from './pages/dashboard-layout/transaction-management/transaction-history/transaction-history.component';
import { ViewTransactionComponent } from './pages/dashboard-layout/transaction-management/view-transaction/view-transaction.component';
import { StaticContentManagementComponent } from './pages/dashboard-layout/static-content-management/static-content-management/static-content-management.component';
import { EditStaticContentComponent } from './pages/dashboard-layout/static-content-management/edit-static-content/edit-static-content.component';
import { ViewStaticContentComponent } from './pages/dashboard-layout/static-content-management/view-static-content/view-static-content.component';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { JackpotLotteryManagementComponent } from './pages/dashboard-layout/lottery-management/jackpot-lottery-management/jackpot-lottery-management.component';
import { ViewLotteryStatusUserDataComponent } from './pages/dashboard-layout/user-management/view-user/view-lottery-status-user-data/view-lottery-status-user-data.component';
import { LotteryResultsComponent } from './pages/dashboard-layout/lottery-management/lottery-results/lottery-results/lottery-results.component';
import { ViewLotteryDetailsComponent } from './pages/dashboard-layout/lottery-management/lottery-results/view-lottery-details/view-lottery-details.component';
import { FaqManagementComponent } from './pages/dashboard-layout/faq-management/faq-management/faq-management.component';
import { AddFaqComponent } from './pages/dashboard-layout/faq-management/add-faq/add-faq.component';
import { EditFaqComponent } from './pages/dashboard-layout/faq-management/edit-faq/edit-faq.component';
import { ViewFaqComponent } from './pages/dashboard-layout/faq-management/view-faq/view-faq.component';
import { DatePipe } from '@angular/common';
import { BlogManagementComponent } from './pages/dashboard-layout/blog-management/blog-management/blog-management.component';
import { AddBlogComponent } from './pages/dashboard-layout/blog-management/add-blog/add-blog.component';
import { ViewBlogComponent } from './pages/dashboard-layout/blog-management/view-blog/view-blog.component';
import { EditBlogComponent } from './pages/dashboard-layout/blog-management/edit-blog/edit-blog.component';
import { ReferralManagementComponent } from './pages/dashboard-layout/referral-management/referral-management/referral-management.component';
import { FaqAndPayoutManagementComponent } from './pages/dashboard-layout/faq-and-payout/faq-and-payout-management/faq-and-payout-management.component';
import { PayoutManagementComponent } from './pages/dashboard-layout/faq-and-payout/payout/payout-management/payout-management.component';
import { AddPayoutComponent } from './pages/dashboard-layout/faq-and-payout/payout/add-payout/add-payout.component';
import { ViewPayoutComponent } from './pages/dashboard-layout/faq-and-payout/payout/view-payout/view-payout.component';
import { EditPayoutComponent } from './pages/dashboard-layout/faq-and-payout/payout/edit-payout/edit-payout.component';
import { ReplaceUnderscorePipe } from './pipes/replace-underscore.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { BannerManagementComponent } from './pages/dashboard-layout/banner-management/banner-management/banner-management.component';
import { AddBannerComponent } from './pages/dashboard-layout/banner-management/add-banner/add-banner.component';
import { ViewBannerComponent } from './pages/dashboard-layout/banner-management/view-banner/view-banner.component';
import { EditBannerComponent } from './pages/dashboard-layout/banner-management/edit-banner/edit-banner.component';

import {MatDatepickerModule} from '@angular/material/datepicker';
// import {MatNativeDateModule} from '@angular/material';
// import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatNativeDateModule } from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { ShortenPipe } from './pipes/shorten.pipe';
import { EstdatePipe } from './pipes/estdate.pipe';
import { WalletManagementComponent } from './pages/dashboard-layout/wallet-management/wallet-management.component';
import { QRCodeModule } from 'angularx-qrcode';

import { NgxQRCodeModule } from 'ngx-qrcode2';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SidebarComponent,
    DashboardComponent,
    UserManagementComponent,
    HeaderComponent,
    ResetPasswordComponent,
    ProfileManagementComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    ViewUserDataComponent,
    ViewGeneralUserDataComponent,
    ViewWalletUserDataComponent,
    ViewWalletTransactionUserDataComponent,
    EditUserComponent,
    LotteryPurchaseDataComponent,
    TransactionHistoryComponent,
    ViewTransactionComponent,
    StaticContentManagementComponent,
    EditStaticContentComponent,
    ViewStaticContentComponent,
    JackpotLotteryManagementComponent,
    ViewLotteryStatusUserDataComponent,
    LotteryResultsComponent,
    ViewLotteryDetailsComponent,
    FaqManagementComponent,
    AddFaqComponent,
    EditFaqComponent,
    ViewFaqComponent,
    BlogManagementComponent,
    AddBlogComponent,
    ViewBlogComponent,
    EditBlogComponent,
    ReferralManagementComponent,
    FaqAndPayoutManagementComponent,
    PayoutManagementComponent,
    AddPayoutComponent,
    ViewPayoutComponent,
    EditPayoutComponent,
    ReplaceUnderscorePipe,
    StatusPipe,
    BannerManagementComponent,
    AddBannerComponent,
    ViewBannerComponent,
    EditBannerComponent,
    ShortenPipe,
    EstdatePipe,
    WalletManagementComponent,

  ],
  imports: [
    BrowserModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxQRCodeModule,
    QRCodeModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      timeOut: 2000
    }),
    NgxSpinnerModule,
    NgxPaginationModule,
    CKEditorModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
