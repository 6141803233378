<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <!-- <h1 class="page_title">Ticket Management</h1> -->
            <h1 class="page_title"> Jackpot Lottery Management </h1>
        </div>
        <div class="content-section">
            <div class="add-new-header-btn-align">
                <button *ngIf="!addLotteryReq" class="btn btn-large  max-WT-150 btn-theme"
                    (click)="openCalculatorModal()">Calculator</button>
                <button class="btn btn-large  max-WT-150 btn-theme" (click)="newLottery()">+ New Lottery</button>
            </div>

            <!-- <div class="order-view  max-WT-700 mrgn-0-auto setting-page"> -->
            <div class="order-view mrgn-0-auto setting-page">

                <div class="  mb40">
                    <div class="admin-profile">
                        <!-- <h4 class="mb20 text-center">Edit Profile</h4> -->
                    </div>
                    <!-- <div class="add-store-block input-style mb20 mt20 "> -->
                    <div class="input-style mb20 mt20 ">
                        <form [formGroup]="lotteryForm" #f="ngForm">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright ">Lottery Name <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Lottery Name"
                                            formControlName="lotteryName" (keypress)="mainService.preventSpace($event)"
                                            maxlength="60">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="lotteryForm.get('lotteryName').hasError('required') && (lotteryForm.get('lotteryName').dirty || lotteryForm.get('lotteryName').touched)">
                                                *Please enter lottery name.
                                            </span>
                                            <!-- <span
                                                *ngIf="lotteryForm.get('lotteryName').hasError('pattern') && (lotteryForm.get('lotteryName').dirty || f.submitted)">
                                                *Name accept only alphabets.
                                            </span> -->
                                            <span
                                                *ngIf="lotteryForm.get('lotteryName').hasError('minlength') && !(lotteryForm.get('lotteryName').hasError('pattern')) && (lotteryForm.get('lotteryName').dirty || f.submitted)">
                                                *Minimum length must be 2.
                                            </span>
                                            <span
                                                *ngIf="lotteryForm.get('lotteryName').hasError('maxlength') && !(lotteryForm.get('lotteryName').hasError('pattern')) && (lotteryForm.get('lotteryName').dirty || f.submitted)">
                                                *Maximum length should not more than 20.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">Admin(%) <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter Admin Percentage"
                                            formControlName="adminPrice" (keypress)="mainService.numOnly($event)"
                                            (keyup)="checkLotteryPricePercentage($event)" maxlength="3">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="lotteryForm.get('adminPrice').hasError('required') && (lotteryForm.get('adminPrice').dirty || lotteryForm.get('adminPrice').touched)">
                                                *Please enter admin percentage.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">1st Prize(%) <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter 1st Prize Percentage"
                                            formControlName="firstPrice" (keypress)="mainService.numOnly($event)"
                                            (keyup)="checkLotteryPricePercentage($event)" maxlength="3">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="lotteryForm.get('firstPrice').hasError('required') && (lotteryForm.get('firstPrice').dirty || lotteryForm.get('firstPrice').touched)">
                                                *Please enter 1st prize percentage.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">2nd Prize(%) <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter 2nd Prize Percentage"
                                            formControlName="secondPrice" (keypress)="mainService.numOnly($event)"
                                            (keyup)="checkLotteryPricePercentage($event)" maxlength="3">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="lotteryForm.get('secondPrice').hasError('required') && (lotteryForm.get('secondPrice').dirty || lotteryForm.get('secondPrice').touched)">
                                                *Please enter 2nd prize percentage.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">3rd Prize(%) <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter 3rd Prize Percentage"
                                            formControlName="thirdPrice" (keypress)="mainService.numOnly($event)"
                                            (keyup)="checkLotteryPricePercentage($event)" maxlength="3">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="lotteryForm.get('thirdPrice').hasError('required') && (lotteryForm.get('thirdPrice').dirty || lotteryForm.get('thirdPrice').touched)">
                                                *Please enter 3rd prize percentage.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">4th Prize(%) <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter 4th Prize Percentage"
                                            formControlName="fourthPrice" (keypress)="mainService.numOnly($event)"
                                            (keyup)="checkLotteryPricePercentage($event)" maxlength="3">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="lotteryForm.get('fourthPrice').hasError('required') && (lotteryForm.get('fourthPrice').dirty || lotteryForm.get('fourthPrice').touched)">
                                                *Please enter 4th prize percentage.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">5th Prize(%) <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter 5th Prize Percentage"
                                            formControlName="fifthPrice" (keypress)="mainService.numOnly($event)"
                                            (keyup)="checkLotteryPricePercentage($event)" maxlength="3">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="lotteryForm.get('fifthPrice').hasError('required') && (lotteryForm.get('fifthPrice').dirty || lotteryForm.get('fifthPrice').touched)">
                                                *Please enter 5th prize percentage.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">Sale Price <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter Sale Price"
                                            formControlName="salePrice" (keypress)="mainService.numOnly($event)"
                                            maxlength="50">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="lotteryForm.get('salePrice').hasError('required') && (lotteryForm.get('salePrice').dirty || lotteryForm.get('salePrice').touched)">
                                                *Please enter sale price.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">Start Date <span>:</span></label>
                                <div class="col-md-4 textaligncenter">
                                    <div class="form-group ">
                                        <input type="datetime-local" class="form-control" placeholder="Start Date"
                                            formControlName="startDateTime">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">End Date <span>:</span></label>
                                <div class="col-md-4 textaligncenter">
                                    <div class="form-group ">
                                        <input type="datetime-local" class="form-control" placeholder="End Date"
                                            formControlName="endDateTime">
                                        <div class="text-danger">
                                            <span *ngIf="lotteryForm.get('endDateTime').errors?.dateLessThen">
                                                *Lottery end date should always be after start date.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">Result Date <span>:</span></label>
                                <div class="col-md-4 textaligncenter">
                                    <div class="form-group">
                                        <input type="datetime-local" class="form-control" placeholder="Result Date"
                                            formControlName="resultDateTime">
                                        <div class="text-danger">
                                            <span *ngIf="lotteryForm.get('resultDateTime').errors?.dateLessThen">
                                                *Result date should always be after the lottery end date.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">From Date <span>:</span></label>
                                <div class="col-md-4 textaligncenter">
                                    <div class="form-group ">
                                        <input type="date" class="form-control" placeholder="From Date"
                                            formControlName="fromDate" (keypress)="mainService.preventSpace($event)"
                                            maxlength="50">
                                    </div>
                                </div>
                                <label class="col-md-2 textalignright">To Date <span>:</span></label>
                                <div class="col-md-4 textaligncenter">
                                    <div class="form-group ">
                                        <input type="date" class="form-control" placeholder="To Date"
                                            formControlName="toDate" (keypress)="mainService.preventSpace($event)"
                                            maxlength="50">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">Start Time <span>:</span></label>
                                <div class="col-md-4 textaligncenter">
                                    <div class="form-group ">
                                        <input type="time" class="form-control" placeholder="Start Time"
                                            formControlName="startTime" (keypress)="mainService.preventSpace($event)"
                                            maxlength="50">
                                    </div>
                                </div>
                                <label class="col-md-2 textalignright">End Time <span>:</span></label>
                                <div class="col-md-4 textaligncenter">
                                    <div class="form-group ">
                                        <input type="time" class="form-control" placeholder="End Time"
                                            formControlName="endTime" (keypress)="mainService.preventSpace($event)"
                                            maxlength="50">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">Draw Date <span>:</span></label>
                                <div class="col-md-4 textaligncenter">
                                    <div class="form-group ">
                                        <input type="date" class="form-control" placeholder="Draw Date"
                                            formControlName="drawDate" (keypress)="mainService.preventSpace($event)"
                                            maxlength="50">
                                    </div>
                                </div>
                                <label class="col-md-2 textalignright">Draw Time <span>:</span></label>
                                <div class="col-md-4 textaligncenter">
                                    <div class="form-group ">
                                        <input type="time" class="form-control" placeholder="Draw Time"
                                            formControlName="drawTime" (keypress)="mainService.preventSpace($event)"
                                            maxlength="50">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">Result Date <span>:</span></label>
                                <div class="col-md-4 textaligncenter">
                                    <div class="form-group ">
                                        <input type="date" class="form-control" placeholder="Result Date"
                                            formControlName="resultDate" (keypress)="mainService.preventSpace($event)"
                                            maxlength="50">
                                    </div>
                                </div>
                                <label class="col-md-2 textalignright">Result Time <span>:</span></label>
                                <div class="col-md-4 textaligncenter">
                                    <div class="form-group ">
                                        <input type="time" class="form-control" placeholder="Result Time"
                                            formControlName="resultTime" (keypress)="mainService.preventSpace($event)"
                                            maxlength="50">
                                    </div>
                                </div>
                            </div> -->

                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">Lottery Image <span>:</span></label>
                                <div class="col-md-4 textaligncenter">
                                    <div class="form-group ">
                                        <input type='file' accept="image/jpg,image/jpeg,image/png" id="image"
                                            (change)="uploadImg($event)">
                                    </div>
                                    <img *ngIf="lotteryImageUrl" [src]="lotteryImageUrl" height="200">
                                </div>
                            </div>

                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!lotteryForm.valid"
                            (click)="lotteryFormSubmit()">Save</button>
                        <!-- <button class="btn btn-large  max-WT-150 btn-theme" (click)="back()">Back</button> -->
                    </div>
                </div>
            </div>
        </div>


        <!-- card section -->
        <!-- <div class="content-section"> -->
        <div class="order-view  mrgn-0-auto setting-page" style="box-shadow: none;">
            <div class="">
                <div class="admin-profile">
                    <h4 class="mb20">Recent Lotteries</h4>
                </div>
                <div class=" input-style view-label-align">
                    <div class="row">
                        <!-- left row -->
                        <div *ngFor="let item of latestDrawListDataArray; let i = index" class="col-md-6">
                            <div *ngIf="i < 2" class="card">
                                <h5 class="card-title">{{item?.lotteryName || 'Lottery'}}</h5>
                                <div class="row mr0 mL0">
                                    <div class="col-sm-5">
                                        <div class="card-left-image">
                                            <img class="d-block w-100"
                                                [src]="item?.lotteryImageUrl || 'https://picsum.photos/150?image=641'"
                                                alt="">
                                        </div>
                                    </div>
                                    <div class="col-sm-7">
                                        <div class="card-block">
                                            <h6>USD {{item?.salePrice}}</h6>
                                            <p>{{item?.startDateTime | date:'mediumDate'}}</p>
                                            <a class="btn btn-sm card-btn" (click)="editLottery(item?.lotteryId)">Edit
                                                Lottery</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end left row  -->

                        <!-- right row -->
                        <!-- <div class="col-md-6">
                                <div class="card">
                                    <h5 class="card-title">California Jackpot</h5>
                                    <div class="row mr0 mL0">
                                        <div class="col-sm-5">
                                            <div class="card-left-image">
                                                <img class="d-block w-100" src="https://picsum.photos/150?image=641"
                                                    alt="">
                                            </div>
                                        </div>
                                        <div class="col-sm-7">
                                            <div class="card-block">
                                                <h6>USD 75,000,000</h6>
                                                <p>1 Day 2 Hours</p>
                                                <a class="btn btn-sm card-btn">Edit Lottery</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        <!-- end right row -->
                    </div>
                    <!-- when lottery data not found -->
                    <div *ngIf="!latestDrawListDataArray.length">
                        <div class="col-md-12">
                            <div class="card">
                                <h5 class="card-title">No Record Found</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- </div> -->
        <!-- end card section -->

        <!-- card section -->
        <!-- <div class="content-section"> -->
        <div class="order-view  mrgn-0-auto setting-page pb20" style="box-shadow: none;">
            <div class="">
                <div class="admin-profile">
                    <h4 class="mb20">Previous Lotteries</h4>
                </div>
                <div class=" input-style view-label-align">
                    <div class="row">
                        <!-- left row -->
                        <div *ngFor="let item of closedDrawListDataArray | paginate:{itemsPerPage:itemsPerPage, currentPage:currentPage, totalItems:totalItems} "
                            class="col-md-6">
                            <div class="card">
                                <h5 class="card-title">{{item?.lotteryName}}</h5>
                                <div class="row mr0 mL0">
                                    <div class="col-sm-5">
                                        <div class="card-left-image">
                                            <img class="d-block w-100"
                                                [src]="item?.lotteryImageUrl || 'https://picsum.photos/150?image=641'"
                                                alt="">
                                        </div>
                                    </div>
                                    <div class="col-sm-7">
                                        <div class="card-block">
                                            <h6>USD {{item?.salePrice}}</h6>
                                            <p>{{item?.startDateTime | date:'mediumDate'}}</p>
                                            <a class="btn btn-sm card-btn" (click)="editLottery(item?.lotteryId)">Edit
                                                Lottery</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end left row  -->

                        <!-- right row -->
                        <!-- <div class="col-md-6">
                                <div class="card">
                                    <h5 class="card-title">Canada casino Jackpot</h5>
                                    <div class="row mr0 mL0">
                                        <div class="col-sm-5">
                                            <div class="card-left-image">
                                                <img class="d-block w-100" src="https://picsum.photos/150?image=641"
                                                    alt="">
                                            </div>
                                        </div>
                                        <div class="col-sm-7">
                                            <div class="card-block">
                                                <h6>USD 75,000,000</h6>
                                                <p>11 Day  2 Hours</p>
                                                <a class="btn btn-sm card-btn">Edit Lottery</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        <!-- end right row -->
                    </div>
                    <!-- when lottery data not found -->
                    <div *ngIf="!closedDrawListDataArray.length">
                        <div class="col-md-12">
                            <div class="card">
                                <h5 class="card-title">No Record Found</h5>
                            </div>
                        </div>
                    </div>
                    <!-- pagination -->
                    <!-- <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 2">
                        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- pagination -->
        <div class="custom-pagination text-align-end pb20 pR35" *ngIf="totalItems > 2">
            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
        </div>
        <!-- </div> -->
        <!-- end card section -->
    </main>
</div>



<!-- ************************** calculator modal Start ************************************* -->
<div class="modal fade global-modal reset-modal" id="calculatorModalId">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h6 class="modal-title d-inline-block">Wallet Pool Balance
                        {{weeklyPoolWallet}} LTC</h6>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <!-- <p>Are you sure you want to active this user?</p> -->
                                <div class="input-style mb20 mt20 ">
                                    <form [formGroup]="lotteryCalculatorForm">
                                        <div class="row align-items-baseline view-label-align row-custom">
                                            <label class="col-md-4 textalignright">Admin(%) <span>:</span></label>
                                            <div class="col-md-8 textaligncenter">
                                                <div class="calculator-modal-input">
                                                    <input type="text" class="form-control form-control-modal"
                                                        placeholder="Enter Admin %" formControlName="adminPrice"
                                                        (keypress)="mainService.numOnly($event)"
                                                        (keyup)="checkPricePercentageModal($event)" maxlength="3">

                                                    <label
                                                        class="label-modal">{{viewPriceBreakDownData?.adminPriceAmount
                                                        || 0}} <span class="ml-2">LTC</span></label>
                                                </div>
                                                <div class="text-danger">
                                                    <span
                                                        *ngIf="lotteryCalculatorForm.get('adminPrice').hasError('required') && (lotteryCalculatorForm.get('adminPrice').dirty || lotteryCalculatorForm.get('adminPrice').touched)">
                                                        *Please enter admin %.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-baseline view-label-align row-custom">
                                            <label class="col-md-4 textalignright">1st Prize(%) <span>:</span></label>
                                            <div class="col-md-8 textaligncenter">
                                                <div class="calculator-modal-input">
                                                    <input type="text" class="form-control form-control-modal"
                                                        placeholder="Enter 1st Prize %" formControlName="firstPrice"
                                                        (keypress)="mainService.numOnly($event)"
                                                        (keyup)="checkPricePercentageModal($event)" maxlength="3">

                                                    <label
                                                        class="label-modal">{{viewPriceBreakDownData?.firstPriceAmount
                                                        || 0}} <span class="ml-2">LTC</span></label>
                                                </div>
                                                <div class="text-danger">
                                                    <span
                                                        *ngIf="lotteryCalculatorForm.get('firstPrice').hasError('required') && (lotteryCalculatorForm.get('firstPrice').dirty || lotteryCalculatorForm.get('firstPrice').touched)">
                                                        *Please enter 1st prize %.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-baseline view-label-align row-custom">
                                            <label class="col-md-4 textalignright">2nd Prize(%) <span>:</span></label>
                                            <div class="col-md-8 textaligncenter">
                                                <div class="calculator-modal-input">
                                                    <input type="text" class="form-control form-control-modal"
                                                        placeholder="Enter 2nd Prize %" formControlName="secondPrice"
                                                        (keypress)="mainService.numOnly($event)"
                                                        (keyup)="checkPricePercentageModal($event)" maxlength="3">

                                                    <label class="label-modal">{{viewPriceBreakDownData?.secPriceAmount
                                                        || 0}}<span class="ml-2">LTC</span></label>
                                                </div>
                                                <div class="text-danger">
                                                    <span
                                                        *ngIf="lotteryCalculatorForm.get('secondPrice').hasError('required') && (lotteryCalculatorForm.get('secondPrice').dirty || lotteryCalculatorForm.get('secondPrice').touched)">
                                                        *Please enter 2nd prize %.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-baseline view-label-align row-custom">
                                            <label class="col-md-4 textalignright">3rd Prize(%) <span>:</span></label>
                                            <div class="col-md-8 textaligncenter">
                                                <div class="calculator-modal-input">
                                                    <input type="text" class="form-control form-control-modal"
                                                        placeholder="Enter 3rd Prize %" formControlName="thirdPrice"
                                                        (keypress)="mainService.numOnly($event)"
                                                        (keyup)="checkPricePercentageModal($event)" maxlength="3">

                                                    <label
                                                        class="label-modal">{{viewPriceBreakDownData?.thirdPriceAmount
                                                        || 0}}<span class="ml-2">LTC</span></label>
                                                </div>
                                                <div class="text-danger">
                                                    <span
                                                        *ngIf="lotteryCalculatorForm.get('thirdPrice').hasError('required') && (lotteryCalculatorForm.get('thirdPrice').dirty || lotteryCalculatorForm.get('thirdPrice').touched)">
                                                        *Please enter 3rd prize %.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-baseline view-label-align row-custom">
                                            <label class="col-md-4 textalignright">4th Prize(%) <span>:</span></label>
                                            <div class="col-md-8 textaligncenter">
                                                <div class="calculator-modal-input">
                                                    <input type="text" class="form-control form-control-modal"
                                                        placeholder="Enter 4th Prize %" formControlName="fourthPrice"
                                                        (keypress)="mainService.numOnly($event)" maxlength="3"
                                                        (keyup)="checkPricePercentageModal($event)">

                                                    <label
                                                        class="label-modal">{{viewPriceBreakDownData?.fourthPriceAmount
                                                        || 0}}<span class="ml-2">LTC</span></label>
                                                </div>
                                                <div class="text-danger">
                                                    <span
                                                        *ngIf="lotteryCalculatorForm.get('fourthPrice').hasError('required') && (lotteryCalculatorForm.get('fourthPrice').dirty || lotteryCalculatorForm.get('fourthPrice').touched)">
                                                        *Please enter 4th prize %.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-baseline view-label-align row-custom">
                                            <label class="col-md-4 textalignright">5th Prize(%) <span>:</span></label>
                                            <div class="col-md-8 textaligncenter">
                                                <div class="calculator-modal-input">
                                                    <input type="text" class="form-control form-control-modal"
                                                        placeholder="Enter 5th Prize %" formControlName="fifthPrice"
                                                        (keypress)="mainService.numOnly($event)"
                                                        (keyup)="checkPricePercentageModal($event)" maxlength="3">

                                                    <label
                                                        class="label-modal">{{viewPriceBreakDownData?.fifthPriceAmount
                                                        || 0}}<span class="ml-2">LTC</span></label>
                                                </div>
                                                <div class="text-danger">
                                                    <span
                                                        *ngIf="lotteryCalculatorForm.get('fifthPrice').hasError('required') && (lotteryCalculatorForm.get('fifthPrice').dirty || lotteryCalculatorForm.get('fifthPrice').touched)">
                                                        *Please enter 5th prize %.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-btn-box d-flex">
                                    <button type="submit" class="btn btn-theme"
                                        [disabled]="lotteryCalculatorForm.invalid"
                                        (click)="updatePricePercentage()">Apply</button>
                                    <button type="submit" class="btn btn-theme"
                                        (click)="calculatePriceBreakDown()">Calculate</button>
                                    <button type="button" class="btn btn-red" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- end active user modal -->