import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $

@Component({
  selector: 'app-banner-management',
  templateUrl: './banner-management.component.html',
  styleUrls: ['./banner-management.component.scss']
})
export class BannerManagementComponent implements OnInit {
  // currentPage: any;
  bannerList=[]
  itemsPerPage=5
  currentPage=1
  totalItems:any
  bannerId: any;
  myid: any;

  constructor( private router: Router, public mainService: MainService) { }

  ngOnInit(): void {
    this.getBannerList();
  }
  pagination(event) {
    this.currentPage = event
    
  }
  getBannerList() {
  
    let url = 'static/admin/static-content/get-all-banner'
    this.mainService.showSpinner();
    this.mainService.getApi(url,1).subscribe((res: any) => {
      console.log("add banner response ==>", res)
      if (res.status == 200) {
        this.mainService.hideSpinner();
        this.bannerList=res.data
       
        this.totalItems=res.data.length
        console.log(this.bannerList);
        this.mainService.successToast(res.message)
      } else {
        console.log("else error")
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }
  editBanner(bannerId) {
    console.log('id', bannerId);
    this.router.navigate(['/edit-banner'], { queryParams: { bannerId: bannerId } })
  }
  viewBanner(bannerId) {
    console.log('id', bannerId);
    this.router.navigate(['/view-banner'], { queryParams: { bannerId: bannerId } })
  }

  openDeleteBlogModal(bannerId) {
    $('#deleteBlog').modal('show')
    this.bannerId = bannerId
  }
  deleteBanner() {
    let url = `static/admin/static-content/delete-banner?bannerId=${this.bannerId}`
    this.mainService.showSpinner();
    this.mainService.deleteApi(url, {}, 1).subscribe((res: any) => {
      console.log("delete response ==>", res)
      $('#deleteBlog').modal('hide');
      if (res.status == 200) {
        this.getBannerList()
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }
  
}
