import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-static-content-management',
  templateUrl: './static-content-management.component.html',
  styleUrls: ['./static-content-management.component.scss']
})
export class StaticContentManagementComponent implements OnInit {
  staticContentDataArray: any[] = []

  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.getStaticContentList()
  }

  // ------------------------ get static content list ------------------------ //
  getStaticContentList() {
    this.mainService.showSpinner();
    let url = `static/admin/static-content/get-all-static-content`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log("get static content list response ==>", res)
      if (res.status == 200) {
        this.staticContentDataArray = res.data ? res.data : '';
        this.mainService.hideSpinner();
      } else {
        this.staticContentDataArray = []
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
    }, err => {
      this.staticContentDataArray = [];
      this.mainService.hideSpinner();
      this.mainService.errorToast(err.responseMessage)
    })
  }

  // ----------------------------------- view static content ------------------------------- //
  view(pageKey, staticContentId) {
    this.router.navigate(['/view-static-content'], { queryParams: { pageKey: pageKey, contentId: staticContentId } })
  }

  // ----------------------------------- edit static content ------------------------ //
  edit(pageKey, staticContentId) {
    this.router.navigate(['/edit-static-content'], { queryParams: { pageKey: pageKey, contentId: staticContentId } })
  }

}
