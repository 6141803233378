<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">User Management / Edit User</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40">
                   
                    <div class=" input-style mb20 mt20 ">
                        <form [formGroup]="editUserForm" #f="ngForm">
                            <div class="user-profile">
                                <div class="image-box">
                                    <img [src]="imageUrl || 'assets/img/profile-img.jpg'" id="sub-admin-image" alt="">
                                    <label class="upload-label">
                                        <input type="file" formControlName="image" name="image"
                                            accept="image/jpg,image/jpeg,image/png" id="image"
                                            (change)="uploadImg($event)" maxlength="56">
                                        <em class="fa fa-pencil" style="color: #012970 !important;font-size: 12px"></em>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright ">User Name <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter User Name"
                                            formControlName="username" (keypress)="mainService.preventSpace($event)"
                                            maxlength="60">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="editUserForm.get('username').hasError('required') && (editUserForm.get('username').dirty || editUserForm.get('username').touched)">
                                                *Please enter user name.
                                            </span>
                                            <span
                                                *ngIf="editUserForm.get('username').hasError('pattern') && (editUserForm.get('username').dirty || f.submitted)">
                                                Name accept only alphabets.
                                            </span>
                                            <span
                                                *ngIf="editUserForm.get('username').hasError('minlength') && !(editUserForm.get('username').hasError('pattern')) && (editUserForm.get('username').dirty || f.submitted)">
                                                Minimum length must be 2.
                                            </span>
                                            <span
                                                *ngIf="editUserForm.get('username').hasError('maxlength') && !(editUserForm.get('username').hasError('pattern')) && (editUserForm.get('username').dirty || f.submitted)">
                                                Maximum length should not more than 20.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Mobile Number<span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                        <div class="phone-number-input-box">
                                            <select class="form-control mr-2" formControlName="countryCode">
                                                <option *ngFor="let item of countryCode" [value]="item">{{item}}
                                                </option>
                                            </select>
                                            <input type="text" class="form-control" placeholder="Enter Mobile Number"
                                                formControlName="phoneNo" (keypress)="mainService.preventSpace($event)"
                                                maxlength="18">
                                        </div>
                                        <div *ngIf="editUserForm.get('countryCode').hasError('required') && (editUserForm.get('phoneNo').dirty || editUserForm.get('phoneNo').touched)"
                                            class="text-danger">
                                            <span *ngIf="!editUserForm.get('countryCode').value">*Please select
                                                country code.</span>
                                        </div>
                                        <div *ngIf="editUserForm.get('countryCode').value" class="text-danger">
                                            <span
                                                *ngIf="editUserForm.get('phoneNo').hasError('required') && (editUserForm.get('phoneNo').dirty || editUserForm.get('phoneNo').touched)">
                                                *Please enter mobile number.
                                            </span>
                                            <span
                                                *ngIf="(editUserForm.get('phoneNo').hasError('pattern')) && editUserForm.get('phoneNo').dirty">
                                                Please enter valid mobile number.
                                            </span>
                                            <span
                                                *ngIf="editUserForm.get('phoneNo').hasError('maxlength') && !(editUserForm.get('phoneNo').hasError('pattern')) && editUserForm.get('phoneNo').dirty ">
                                                Maximum length should not more than 18.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Email Address<span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter Email Address"
                                            formControlName="email" (keypress)="mainService.preventSpace($event)"
                                            maxlength="60">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="editUserForm.get('email').hasError('required') && (editUserForm.get('email').dirty || editUserForm.get('email').touched)">
                                                *Please enter email address.
                                            </span>
                                            <span *ngIf="editUserForm.get('email').hasError('pattern')">
                                                Please enter valid email address.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Registration Date & Time <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control"
                                            placeholder="Email Registration Date & Time" formControlName="creationTime"
                                            (keypress)="mainService.preventSpace($event)"
                                            [attr.disabled]="disabledAttr">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="editUserForm.get('email').hasError('required') && (editUserForm.get('email').dirty || editUserForm.get('email').touched)">
                                                *Please select registration date.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Crypto Wallet <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter Crypto  Wallet"
                                            formControlName="cryptoWallet" (keypress)="mainService.preventSpace($event)"
                                            maxlength="60" [attr.disabled]="disabledAttr">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">User IP Address <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter User IP Address"
                                            formControlName="userIPAddress"
                                            (keypress)="mainService.preventSpace($event)" maxlength="50"
                                            [attr.disabled]="disabledAttr">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Status <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter Status"
                                            formControlName="userStatus" (keypress)="mainService.preventSpace($event)"
                                            maxlength="50" [attr.disabled]="disabledAttr">
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!editUserForm.valid"
                            (click)="editUserProfile()">Save</button>
                        <button class="btn btn-large  max-WT-150 btn-theme" (click)="back()">Back</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>