import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-faq-and-payout-management',
  templateUrl: './faq-and-payout-management.component.html',
  styleUrls: ['./faq-and-payout-management.component.scss']
})
export class FaqAndPayoutManagementComponent implements OnInit {
  tabView: any = 'FAQ';
  titleKey: any;
  routeFromChild: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  /**
   * @param routeFromChild check when route back from faq or payout faq page
   * check if @param tabView will contain 'FAQ' or 'PAY_OUT_FAQ'
   * if @param tabView contain 'FAQ' show faq section then check for @param titleKey
   * if @param titleKey contain then show 'TABLE' section else 'TILES'
   */
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      if (res.tabView) {
        this.routeFromChild = true;
        if (res.tabView == 'FAQ') {
          this.tabView = res.tabView
          this.titleKey = res.titleKey ? res.titleKey : ''
        } else {
          this.tabView = res.tabView
        }
      }
    })
  }

  /**
   * 
   * @param tab when user clicked on top tabs 
   * check for @param routeFromChild contains value then clear the value of @param titleKey
   */
  // view tab 
  viewTab(tab) {
    this.tabView = tab
    if (this.routeFromChild) {
      const queryParams: Params = { tabView: tab, titleKey: null };
      this.router.navigate([], {
        relativeTo: this.activatedRoute, queryParams: queryParams, queryParamsHandling: 'merge'
      })
    }
  }

}

