import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceUnderscore'
})
export class ReplaceUnderscorePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    console.log("valuee to be transformed", value)
    return value? value.replace(/_/g, " "): value
    // return null;
  }

}
