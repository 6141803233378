<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Referral Management</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div class="sec_head_new ">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12">
                                        <div class="justify-content-end pull-right">
                                            <button type="button" class="btn btn-theme Go_btn " style="padding-left: 0.65rem;
                                                padding-right: 0.65rem;" (click)="exportToExcel()">Export To
                                                Excel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th class="Sorting-img">S.No.</th>
                              
                                        <th class="Sorting-img">Refer To User Name</th>
                                        <th class="Sorting-img">Refer To Email Address</th>
                                        <th class="Sorting-img">Registration Date & Time</th>
                                        <th class="Sorting-img">Login IP Address</th>
                                        <th class="Sorting-img">Lotto Ticket Purchased</th>
                                
                                        <th class="Sorting-img">Refer By User Name</th>
                                        <th class="Sorting-img">Referral Id</th>
                                 
                                        <th class="Sorting-img">Refer By Email Address</th>
                                        <th class="action_td_btn3 Sorting-img">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of referralDataArray | paginate:{itemsPerPage:itemsPerPage, currentPage:currentPage, totalItems:totalItems}; let i = index"
                                        class="text-center">
                                        <td>{{itemsPerPage * (currentPage -1)+i+1}}</td>
                                        <td class="content">{{item?.referToUserName || 'N/A'}}</td>
                                        <td class="content">{{item?.referToEmail || 'N/A'}}</td>
                                        <td class="content">{{(item?.registrationDateTime | date : 'medium') || 'N/A'}}</td>
                                        <td class="content">{{item?.referToIpAddress || 'N/A'}}</td>
                                        <td class="content">{{item?.isLottoTickerPurchased || 'N/A'}}</td>
                                        <td class="content">{{item?.referByUserName || 'N/A'}}</td>
                                        <td class="content">{{item?.referById || 'N/A'}}</td>
                                        <td class="content">{{item?.referByEmail || 'N/A'}}</td>
                                        <td>
                                            <div class="table-action-btn-alignment">
                                                <button class="btn btn-info" (click)="viewUser(item?.userId)">
                                                    <i class="fa fa-eye" style="color:white"></i></button>
                                                <button class="btn btn-info ml-2" (click)="viewReward(item?.userId)"><i
                                                        class="fa fa-trophy" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!referralDataArray.length">
                                        <td class="table-no-record" colspan="10">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>


<!-- active user modal Start -->
<div class="modal fade global-modal reset-modal" id="sendRewardId">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Send Reward To User?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                               
                                <p>Are you sure you want to send 6 ticket credit? </p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme" (click)="sendPrice()">Yes</button>
                                    <button type="button" class="btn btn-red ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- end active user modal -->