import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-banner',
  templateUrl: './view-banner.component.html',
  styleUrls: ['./view-banner.component.scss']
})
export class ViewBannerComponent implements OnInit {
id:any
bannerData:any=[]
myimage:any
  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res.bannerId) {
        this.id = res.bannerId
      }
    })
    this.viewBanner()
  }

  back() {
    this.router.navigate(['/banner-management'])
  }
  viewBanner(){
    this.mainService.showSpinner()
    let url=`static/admin/static-content/view-banner?bannerId=${this.id}`

    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.bannerData = res.data
        this.mainService.hideSpinner();
        this.mainService.successToast("Success");
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast("Something went wrong");
      }
    })
    
  }
}
