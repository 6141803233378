<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Profile</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                      

                        <!-- tab section -->
                        <div class="tab-section">
                            <div class="tab">
                                <button class="tablinks" [ngClass]="tabView == 'edit-profile' ? 'active': ''"
                                    (click)="viewTab('edit-profile')">Personal Details</button>
                                <button class="tablinks" [ngClass]="tabView == 'change-password' ? 'active': ''"
                                    (click)="viewTab('change-password')">Security</button>
                            </div>
                        </div>
                        <!--end tab section  -->

                        <!-- patient tab -->
                        <app-edit-profile *ngIf="tabView == 'edit-profile'">
                        </app-edit-profile>
                        <!-- end patient tab -->

                        <!-- plasma donated patient  -->
                        <app-change-password
                            *ngIf="tabView == 'change-password'">
                        </app-change-password>
                        <!--end plasma donated patient  -->
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>