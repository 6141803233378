
<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title">Wallet Management</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <!-- <div class="tab-section">
                            <div class="tab">
                                <button class="tablinks" [ngClass]="{'active': currTab=='HOT'}"
                                (click)="selectTab('HOT')">Litecoin Wallet</button>
                                    <button class="tablinks"  [ngClass]="{'active': currTab=='COLD'}"
                                    (click)="selectTab('COLD')">Weekly Pool Wallet</button>
                </div>
            </div> -->

            <div class="tab-content">
                <div class="tab-pane active show" >
                    <div class="tab-pane1">
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No</th>
                                        <th id="">Coin</th>
                                        <th id="">Wallet Address</th>
                                        <!-- <th id="">Last Withdraw On</th> -->
                                        <th id="">Wallet Balance(LTC) </th>
                                        <th id="">Wallet Balance(USD)</th>
                                        <th id="">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of finalHotWalletArray | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems:totalItems}; let i = index;">
                                        <td>{{i+1}}</td>
                                        <td>{{item?.data.CoinName || "---"}}</td>
                                        <td>{{item.data.HotWalletData.address || "---"}}<i *ngIf="item.data.HotWalletData.address" class="fa fa-clone pointer"
                                                aria-hidden="true" style="margin-left: 2%;"
                                                (click)="copyToClipboard(item.data.HotWalletData.address)"></i></td>
                                      
                                        <td>
                                            {{(item?.data.HotWalletData.hotWalletBalance | number : '.8-8') || '---'}} Ł
                                        </td>
                                        <td>
                                            {{((item?.data.HotWalletData.hotWalletBalance)/ltcPrize | number : '.8-8')}} $
                                        </td>
                                        <td class="action_td_btn3">
                                            <button class="btn btn-theme btn-sm"
                                                (click)="addFunds()">Deposit</button>
                                            <button class="btn btn-theme btn-sm ml-2"
                                                (click)="withdraw()">Withdraw</button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="finalHotWalletArray == ''">
                                        <td colspan="8"> No wallet found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 text-right" *ngIf="finalHotWalletArray.length > 10 ">
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
<!-- Add Funds Modal-->
<div class="modal fade global-modal reset-modal" id="add">
    <div class="modal-dialog max-WT-500">
      <form class="change_password">
        <div class="modal-content" >
          <div>
            <div class="modal-body">
              <h3 style="text-align: center;font-size: 25px; ">Add Funds </h3>
              <hr>
              <div class="text-center modal_flax_height align-items-center justify-content-center">
                <div class="w-150">
                 
                  <div style="text-align: -webkit-center;">
           
                    <qrcode [qrdata]="qrdata" [width]="256" [errorCorrectionLevel]="'M' "></qrcode>
                    <p>You can use this address to deposit amount in your wallet!</p>
                    <div style="display: flex;padding:0px  39px 0px 39px;display:-webkit-inline-box">
                     
                      <label for="">Address</label>: 
                      <p style="margin-left: auto;word-break: break-all;">{{walletAddress}}</p>
                      <i class="fa fa-copy myIcon" aria-hidden="true" (click)="copyToClipboard(walletAddress)"></i>
                    </div>
                  </div>
                  <div>
                  
                  </div>
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  
  
  <!-- Withdraw Funds Modal-->
  <div class="modal fade global-modal reset-modal" id="withdraw">
    <div class="modal-dialog " style="width: 500px;">
      <form class="change_password">
        <div class="modal-content">
          <div>
            <div class="modal-body">
              <h3 style="text-align: center;font-size: 25px;">Withdraw Funds </h3>
              <hr>
              <div class=" modal_flax_height justify-content-center">
                <div >
  
                  <form [formGroup]="withdrawForm">
                    <div class="row">
                      <div class="col-md-5">
                        <label for="">Amount</label>:
  
                      </div>
                      <div class="col-md-7 mb-3 ">
                        <input type="text" class="form-control"  placeholder="Amount in LTC" (keypress)="service.OnlyNumAllowed($event)"  maxlength="16" formControlName="amount" (change)="myAmount($event)">
                        <span  *ngIf="(withdrawForm.get('amount').touched) && withdrawForm.get('amount').hasError('required')">
                          <span class="text-danger myspan">*Please enter amount.</span>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <label for="">Address</label>:
  
                      </div>
                      <div class="col-md-7 mb-3">
                        <input type="text" class="form-control" placeholder="Address" formControlName="address"> 
                        <span  *ngIf="(withdrawForm.get('address').touched) && withdrawForm.get('address').hasError('required')">
                          <span class="text-danger myspan">*Please enter address.</span>
                        </span>
                      </div>
                    </div>
          
                    <div style="text-align: -webkit-center; margin-top: 10px;">
                      <!-- <button type="submit" class="btn btn-primary mybutton" style="margin-top: 15px;"
                      (click)="WithDrawAmount()" >Submit</button> -->
                      <button type="button" class="btn btn-theme " style="background-color: red !important;" data-dismiss="modal">Cancel</button>
                      <button type="submit" class="btn btn-theme ml-2" data-dismiss="modal" (click)="WithDrawAmount()" >Submit</button>
                    </div>
                   
  
                  </form>
                  <div>
  
                  </div>
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
             