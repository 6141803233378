<div class="content-section">
    <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
        <div class="  mb40">
            <div class="admin-profile">
                <h4 class="mb20 text-center">Edit Profile</h4>
            </div>
            <div class="add-store-block input-style mb20 mt20 ">
                <form [formGroup]="editProfileForm" #f="ngForm">
                    <div class="user-profile">
                        <div class="image-box">
                            <img src="{{imageUrl || 'assets/img/profile-img.jpg'}}" id="sub-admin-image" alt="">
                            <label class="upload-label">
                                <input type="file" formControlName="image" name="image"
                                    accept="image/jpg,image/jpeg,image/png" id="image"
                                    (change)="uploadImg($event)" maxlength="56">
                                <em class="fa fa-pencil" style="color: #86559f!important;font-size: 12px"></em>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row align-items-baseline view-label-align">
                        <label class="col-md-5 textalignright ">First Name <span>:</span></label>
                        <div class="col-md-7 textaligncenter">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Enter First Name"
                                    formControlName="firstName" (keypress)="mainService.preventSpace($event)"
                                    maxlength="60">
                                <div class="text-danger">
                                    <span
                                        *ngIf="editProfileForm.get('firstName').hasError('required') && (editProfileForm.get('firstName').dirty || editProfileForm.get('firstName').touched)">
                                        *Please enter first name.
                                    </span>
                                    <span
                                        *ngIf="editProfileForm.get('firstName').hasError('pattern') && (editProfileForm.get('firstName').dirty || f.submitted)">
                                        Name accept only alphabets.
                                    </span>
                                    <span
                                        *ngIf="editProfileForm.get('firstName').hasError('minlength') && !(editProfileForm.get('firstName').hasError('pattern')) && (editProfileForm.get('firstName').dirty || f.submitted)">
                                        Minimum length must be 2.
                                    </span>
                                    <span
                                        *ngIf="editProfileForm.get('firstName').hasError('maxlength') && !(editProfileForm.get('firstName').hasError('pattern')) && (editProfileForm.get('firstName').dirty || f.submitted)">
                                        Maximum length should not more than 20.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row align-items-baseline view-label-align">
                        <label class="col-md-5 textalignright ">Last Name <span>:</span></label>
                        <div class="col-md-7 textaligncenter">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Enter Last Name"
                                    formControlName="lastName" (keypress)="mainService.preventSpace($event)"
                                    maxlength="60">
                                <div class="text-danger">
                                    <span
                                        *ngIf="editProfileForm.get('lastName').hasError('required') && (editProfileForm.get('lastName').dirty || editProfileForm.get('lastName').touched)">
                                        *Please enter last name.
                                    </span>
                                    <span
                                        *ngIf="editProfileForm.get('lastName').hasError('pattern') && (editProfileForm.get('lastName').dirty || f.submitted)">
                                        Name accept only alphabets.
                                    </span>
                                    <span
                                        *ngIf="editProfileForm.get('lastName').hasError('minlength') && !(editProfileForm.get('lastName').hasError('pattern')) && (editProfileForm.get('lastName').dirty || f.submitted)">
                                        Minimum length must be 2.
                                    </span>
                                    <span
                                        *ngIf="editProfileForm.get('lastName').hasError('maxlength') && !(editProfileForm.get('lastName').hasError('pattern')) && (editProfileForm.get('lastName').dirty || f.submitted)">
                                        Maximum length should not more than 20.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row align-items-baseline view-label-align">
                        <label class="col-md-5 textalignright">Email Address<span>:</span></label>
                        <div class="col-md-7 textaligncenter">
                            <div class="form-group ">
                                <input type="text" class="form-control" placeholder="Enter Email Address"
                                    formControlName="email" (keypress)="mainService.preventSpace($event)" maxlength="60"
                                    disabled>
                                <div class="text-danger">
                                    <span
                                        *ngIf="editProfileForm.get('email').hasError('required') && (editProfileForm.get('email').dirty || editProfileForm.get('email').touched)">
                                        *Please enter email address.
                                    </span>
                                    <span *ngIf="editProfileForm.get('email').hasError('pattern')">
                                        Please enter valid email address.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row align-items-baseline view-label-align">
                        <label class="col-md-5 textalignright">Mobile Number <span>:</span></label>
                        <div class="col-md-7 textaligncenter">
                            <div class="form-group ">
                                <div class="phone-number-input-box">
                                    <select class="form-control mr-2" formControlName="countryCode">
                                        <option *ngFor="let item of countryCode" [value]="item">{{item}}
                                        </option>
                                    </select>
                                    <input type="text" class="form-control" placeholder="Mobile no."
                                        formControlName="phoneNo" (keypress)="mainService.preventSpace($event)"
                                        maxlength="16">
                                </div>
                                <div class="text-danger">
                                    <span
                                        *ngIf="editProfileForm.get('phoneNo').hasError('required') && (editProfileForm.get('phoneNo').dirty || editProfileForm.get('phoneNo').touched)">
                                        *Please enter mobile number.
                                    </span>
                                    <span *ngIf="editProfileForm.get('phoneNo').hasError('pattern')">
                                        Please enter valid mobile number.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row align-items-baseline view-label-align">
                        <label class="col-md-5 textalignright">Country of Residence <span>:</span></label>
                        <div class="col-md-7 textaligncenter">
                            <div class="form-group ">
                                <select class="form-control" placeholder="Select Country" formControlName="country">
                                    <option value="">Select Country</option>
                                    <option *ngFor="let item of countryArray" [value]="item.country">{{item.country}}</option>
                                </select>
                                <div class="text-danger">
                                    <span
                                        *ngIf="editProfileForm.get('country').hasError('required') && (editProfileForm.get('country').dirty || editProfileForm.get('country').touched)">
                                        *Please select country.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row align-items-baseline view-label-align">
                        <label class="col-md-5 textalignright">Date of Birth <span>:</span></label>
                        <div class="col-md-7 textaligncenter">
                            <div class="form-group ">
                                <input type="date" class="form-control" placeholder="Date of Birth"
                                    formControlName="dob" (keypress)="mainService.preventSpace($event)">
                                <div class="text-danger">
                                    <span
                                        *ngIf="editProfileForm.get('dob').hasError('required') && (editProfileForm.get('dob').dirty || editProfileForm.get('dob').touched)">
                                        *Please select date of birth.
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="text-center mt40 mb40">
                <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!editProfileForm.valid"
                    (click)="editProfile()">Save</button>
            </div>
        </div>
    </div>
</div>