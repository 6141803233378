import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { MainService } from '../provider/main.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public mainService: MainService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          this.mainService.hideSpinner();
          if (error.error instanceof ErrorEvent) {
            console.log('this is client side error');
            errorMsg = `Error: ${error.error.message}`;
          }
          else {
            console.log('this is server side error', error);
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            switch (error.error.status) {
              case 400:
                this.mainService.errorToast(error.error.message);
                // this.mainService.logout();
                break;
              case 401:
                this.mainService.errorToast('Unauthorized Access.');
                this.mainService.logout()
                break;
              case 403:
                this.mainService.errorToast('Unauthorized Access.');
                this.mainService.logout()
                break;
              case 500:
                this.mainService.errorToast('Internal Server Error.');
                break;
              default:
                this.mainService.errorToast(error.error.message);
                break;
            }
          }
          console.log(errorMsg);
          return throwError(errorMsg);
        })
      )
  }
}
