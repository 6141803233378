<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Static Content Management</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th class="Sorting-img">Title</th>
                                        <th class="Sorting-img">Description</th>
                                        <th class="action_td_btn3 Sorting-img">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of staticContentDataArray; let i = index" class="text-center">
                                        <td class="content">{{(item?.pageKey | replaceUnderscore | titlecase) || 'N/A'}}</td>
                                        <td>
                                            <div class="static-description-content" innerHtml="{{item?.pageData}}">
                                            </div>
                                        </td>
                                        <td>
                                            <div class="table-action-btn-alignment">
                                                <button class="btn btn-info ml-2 bt-margin" title="view"
                                                    (click)="view(item?.pageKey, item?.staticContentId)">
                                                    <i class="fa fa-eye" style="color:white"></i></button>
                                                <button class="btn btn-info ml-2 bt-margin"
                                                    (click)="edit(item?.pageKey, item?.staticContentId)"><i
                                                        class="fa fa-edit" style="color:white"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>