import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  editUserForm: FormGroup
  file: any;
  imageType: any;
  imageUrl: any;
  countryCode: string[] = ['+90', '+91', '+92', '+93'];
  defaultCode: string = '+91';
  userDetails: any;
  userId: any;
  disabledAttr: boolean = true;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public mainService: MainService) { }

  ngOnInit() {
    this.editUserFormValidation();
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      this.userId = res.userId
    })
    this.getUserDetails()
  }
  editUserFormValidation() {
    this.editUserForm = new FormGroup({
      'username': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'image': new FormControl(''),
      'countryCode': new FormControl('', Validators.required),
      'phoneNo': new FormControl('', [Validators.required, Validators.maxLength(18), Validators.pattern(/^[1-9][0-9]{8,13}$/)]),
      'creationTime': new FormControl(''),
      'cryptoWallet': new FormControl(''),
      'userIPAddress': new FormControl(''),
      'userStatus': new FormControl('')
    })
    this.editUserForm.controls['countryCode'].setValue(this.defaultCode, { onlySelf: true });
  }

  getUserDetails() {
    this.mainService.showSpinner();
    let url = `account/admin/user-management/user-details?userId=${this.userId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.userDetails = res.data
        this.imageUrl = res.data.imageUrl? res.data.imageUrl:''
        this.editUserForm.patchValue({
          'username': res.data.firstName ? res.data.firstName : '',
          'phoneNo': res.data.pnWithoutCountryCode ? res.data.pnWithoutCountryCode : '',
          'countryCode': res.data.countryCode ? res.data.countryCode : '',
          'email': res.data.email ? res.data.email : '',
          // 'creationTime': res.data.creationTime ? res.data.creationTime : '',
          // 'cryptoWallet': res.data.cryptoWallet ? res.data.cryptoWallet : '',
          // 'userIPAddress': res.data.userIPAddress ? res.data.userIPAddress : '',
          'userStatus': res.data.userStatus ? res.data.userStatus : ''
        })
        this.mainService.hideSpinner()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }


  editUserProfile() {
    let data = {
      'userId':this.userId,
      'imageUrl':this.imageUrl,
      'firstName': this.editUserForm.value.username,
      'countryCode': this.editUserForm.value.countryCode,
      'pnWithoutCountryCode': this.editUserForm.value.phoneNo,
      'email': this.editUserForm.value.email,
      // 'creationTime': this.editUserForm.value.creationTime,
      // 'cryptoWallet': this.editUserForm.value.cryptoWallet,
      // 'userIPAddress': this.editUserForm.value.userIPAddress,
      // 'userStatus': this.editUserForm.value.userStatus
    }
    this.mainService.showSpinner();
    this.mainService.postApi('account/admin/user-management/profile-update-of-user', data, 1).subscribe((res: any) => {
      console.log("response ==>", res)
      if (res.status == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
        this.router.navigate(['/user-management'])
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // ValidateFileUpload(event) {
  //   this.file = event.target.files;
  //   if (this.file[0]) {
  //     this.imageType = this.file[0].type;
  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       this.imageUrl = e.target.result;
  //     };
  //     reader.readAsDataURL(this.file[0]);
  //   }
  // }

  // image upload
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.mainService.showSpinner();
    this.mainService.fileUploadPostApi('account/upload-file', fb).subscribe(res => {
      this.mainService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
      }
    }, err => {
      this.mainService.hideSpinner();
      if (err['status'] == '401') {
        // this.mainService.onLogout();
        this.mainService.errorToast('Unauthorized Access.');
      } else {
        this.mainService.errorToast('Something went wrong.');
      }
    })
  }

  back() {
    this.router.navigate(['/user-management'])
  }
  
}

