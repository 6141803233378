import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-wallet-transaction-user-data',
  templateUrl: './view-wallet-transaction-user-data.component.html',
  styleUrls: ['./view-wallet-transaction-user-data.component.scss']
})
export class ViewWalletTransactionUserDataComponent implements OnInit {
  userId: number;
  userDetails: any;
  userWalletTransaction: any=[];
  totalItems:any
  itemsPerPage=10
  currentPage:any=1

  constructor(private activatedRoute: ActivatedRoute, public mainService: MainService,private clipboardApi : ClipboardService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      this.userId = res.userId
    })
    this.getUserDetails();
    this.getWalletTransaction();
  }

  // ------------------------ get user details ------------------------- //
  getUserDetails() {
    this.mainService.showSpinner();
    let url = `account/admin/user-management/user-details?userId=${this.userId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.userDetails = res.data
        this.mainService.hideSpinner()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // -------------------------- get user wallet transaction --------------------- //
  getWalletTransaction() {
    //  let url = `account/get-user-transactionss?userUserId=${this.userId}`
    let url = `wallet/admin/transaction-history/get-all-transaction-history?coinName=LTC&fkUserId=${this.userId}&page=${(this.currentPage - 1)}&pageSize=${(this.itemsPerPage)}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.userWalletTransaction = res.data.resultlist
        console.log(this.userWalletTransaction);
        
      } else {
        this.mainService.errorToast(res.message)
      }
    })
  }
  copyToClipboard(item) {
      
    this.mainService.successToast('Copied')
    this.clipboardApi.copyFromContent(item)
   }

}
