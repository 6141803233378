<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Lottery Results</h1>
        </div>


        <!-- card section -->
        <!-- <div class="content-section"> -->
        <div class="order-view  mrgn-0-auto setting-page" style="box-shadow: none;">
            <div class="">
                <div class="admin-profile">
                    <h4 class="mb20">Latest Draws</h4>
                </div>
                <div class=" input-style view-label-align">
                    <div class="row">
                        <!-- left row -->
                        <div *ngFor="let item of latestDrawListDataArray; let i = index" class="col-md-6">
                            <div *ngIf="i < 2" class="card">
                                <!-- <h5 class="card-title">{{item?.lotteryName || 'Lottery'}}</h5> -->
                                <h5 class="card-title">NY_LOTTERY
                                </h5>
                                <div class="row mr0 mL0">
                                    <div class="col-sm-5">
                                        <div class="card-left-image">
                                            <img class="d-block w-100"
                                                [src]="item?.lotteryImageUrl || 'https://picsum.photos/150?image=641'"
                                                alt="">
                                        </div>
                                    </div>
                                    <div class="col-sm-7">
                                        <div class="card-block">
                                            <h6 class="text-center">USD {{item?.salePrise}}</h6>
                                            <div class="card-date-time"><span>Draw Date :</span>
                                                <span>{{item?.resultDateTime
                                                    | date:'mediumDate'}}</span>
                                            </div>
                                            <div class="card-date-time"><span>Draw Time :</span>
                                                <span>{{item?.resultDateTime  
                                                    | date:'mediumTime'}}</span>
                                            </div>
                                            <div class="card-btn-container mt10">
                                                <a class="btn btn-sm card-btn"
                                                    (click)="view(item?.lotteryId, 'OPEN')">View
                                                    Details</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end left row  -->

                        <!-- right row -->
                        <!-- <div class="col-md-6">
                            <div class="card">
                                <h5 class="card-title">California Jackpot</h5>
                                <div class="row mr0 mL0">
                                    <div class="col-sm-5">
                                        <div class="card-left-image">
                                            <img class="d-block w-100" src="https://picsum.photos/150?image=641" alt="">
                                        </div>
                                    </div>
                                    <div class="col-sm-7">
                                        <div class="card-block">
                                            <h6 class="text-center">USD 75,000,000</h6>
                                            <div class="card-date-time"><span>Draw Date :</span> <span>30
                                                    Apr,2021</span></div>
                                            <div class="card-date-time"><span>Draw Time :</span> <span>08 : 20 AM</span>
                                            </div>
                                            <div class="card-btn-container mt10">
                                                <a class="btn btn-sm card-btn" (click)="view()">View Details</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- end right row -->
                    </div>

                    <!-- when lottery data not found -->
                    <div *ngIf="!latestDrawListDataArray?.length">
                        <div class="col-md-12">
                            <div class="card">
                                <h5 class="card-title">No Record Found</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- </div> -->
        <!-- end card section -->


        <!-- card section -->
        <!-- <div class="content-section"> -->
        <div class="order-view  mrgn-0-auto setting-page" style="box-shadow: none;">
            <div class="">
                <div class="admin-profile">
                    <h4 class="mb20">Closed Draws</h4>
                </div>
                <!-- search section -->
                <div *ngIf="latestDrawListDataArray.length" class="tab-pane1">
                    <div class="sec_head_new sec_head_new_after">
                        <div class="row">
                            <div class="col-md-2">
                                <!-- <input type="date" class="form-control" [(ngModel)]="fromDate" [max]="today"> -->
                                <mat-form-field appearance="outline">
                                    <!-- <mat-label>From date</mat-label> -->
                                    <input matInput [matDatepicker]="picker" placeholder="From date" [(ngModel)]="fromDate" [max]="today" >
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                  </mat-form-field>
                            </div>
                            <div class="col-md-2">
                                <!-- <input type="date" class="form-control" [(ngModel)]="toDate" [min]="fromDate"
                                    [disabled]="!fromDate"> -->
                                    <mat-form-field appearance="outline">
                                        <!-- <mat-label>To date</mat-label> -->
                                        <input  matInput [matDatepicker]="picker1" placeholder="To date" [(ngModel)]="toDate" [min]="fromDate"
                                        [disabled]="!fromDate" >
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                      </mat-form-field>
                            </div>    
                            <!-- <div class="col-xl-3 col-lg-3 col-md-2">
                                <div class="filter_search mb20 width100">
                                    <div class="input-group filter_search_group">
                                        <input type="text" class="form-control overflow-text myinput" [(ngModel)]="search"
                                            placeholder="Search by Email" maxlength="60">
                                        <div class="input-group-append">
                                            <button class="btn btn_search_group " type="button"><img
                                                    src="assets/img/icon-search.png" alt="Search"></button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-xl-3 col-lg-3 col-md-4">
                                <div class="text-center admin-btn">
                                    <button type="button" class="btn  btn-theme Go_btn"
                                        (click)="searchEvent()">Search</button>
                                    <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                        (click)="resetEvent()">Reset</button>
                                </div>
                            </div>
                            <!-- <div class="col-xl-2 col-lg-2 col-md-2">
                                <div class="justify-content-end">
                                    <button type="button" class="btn btn-theme Go_btn " style="padding-left: 0.65rem;
                                        padding-right: 0.65rem;" >Export To
                                        Excel</button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <!-- end search section -->

                <div class=" input-style view-label-align">
                    <div class="row">
                        <!-- left row -->
                        <div *ngFor="let item of closedDrawListDataArray | paginate:{itemsPerPage:itemsPerPage, currentPage:currentPage, totalItems:totalItems} "
                            class="col-md-6 ">
                            <div class="card">
                                <!-- <h5 class="card-title">{{item?.lotteryName || 'Lottery'}}</h5> -->
                                <h5 class="card-title">NY_LOTTERY</h5>
                                <div class="row mr0 mL0">
                                    <div class="col-sm-5">
                                        <div class="card-left-image">
                                            <img class="d-block w-100"
                                                [src]="item?.lotteryImageUrl || 'https://picsum.photos/150?image=641'"
                                                alt="">
                                        </div>
                                    </div>
                                    <div class="col-sm-7">
                                        <div class="card-block">
                                            <h6 class="text-center">USD {{item?.salePrise}}</h6>
                                            <div class="card-date-time"><span>Draw Date :</span>
                                                <span>{{item?.resultDateTime
                                                    | date:'mediumDate'}}</span>
                                            </div>
                                            <div class="card-date-time"><span>Draw Time :</span>
                                                <span>{{item?.resultDateTime
                                                    | date:'mediumTime'}}</span>
                                            </div>
                                            <div class="card-btn-container mt10">
                                                <a class="btn btn-sm card-btn"
                                                    (click)="view(item?.lotteryId, 'CLOSE')">View
                                                    Details</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- when lottery data not found -->
                    <div *ngIf="!closedDrawListDataArray?.length">
                        <div class="col-md-12">
                            <div class="card">
                                <h5 class="card-title">No Record Found</h5>
                            </div>
                        </div>
                    </div>
                    <!-- pagination -->
                    <!-- <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 2">
                        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- pagination -->
        <div class="custom-pagination text-align-end pb20 pR35" *ngIf="totalItems > 2">
            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
        </div>
        <!-- </div> -->
        <!-- end card section -->
    </main>
</div>