import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/provider/excel.service';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-referral-management',
  templateUrl: './referral-management.component.html',
  styleUrls: ['./referral-management.component.scss']
})
export class ReferralManagementComponent implements OnInit {
  referralDataArray: any[] = []
  // pagination
  itemsPerPage = 10;
  currentPage = 1;
  totalItems: any;

  userId: any;
  ltcAmount: any;

  constructor(private router: Router, public mainService: MainService, public excelService: ExcelService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getUserList()
  }

  // ------- get referral list -------- //
  getUserList() {
    // this.referralDataArray = this.mainService.referralDataArray
    let apiReqData = {
      page: this.currentPage-1,
      pageSize: this.itemsPerPage  
    }
    let url = 'account/admin/user-management/search-and-filter-referral'
    this.mainService.showSpinner();
    this.mainService.postApi(url, apiReqData, 1).subscribe((res: any) => {
      console.log("get user list response ==>", res)
      if (res.status == 200) {
        this.referralDataArray = res.data.list ? res.data.list : [];
        this.totalItems = res.data.size ? res.data.size:0;
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message)

      } else {
        this.referralDataArray = []
        this.totalItems = 0
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // ----------------- pagination --------------------- //
  pagination(event) {
    this.currentPage = event;
    this.getUserList()
  }

  // ----------------------------------- view user ------------------------------- //
  viewUser(userId) {
    this.router.navigate(['/view-user'], { queryParams: { userId: userId } })
  }

  // ----------------------------------- view reward ------------------------------- //
  viewReward(userId) {
    this.userId = userId
    this.getLtcCurrentPrice()
    $('#sendRewardId').modal('show')
  }

  getLtcCurrentPrice() {
    let url = `https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=LTC&api_key=c375242417d3cd106ff3ffacf958a9eecd664a6eef00756649578baf8e06d62f`
    this.mainService.showSpinner();
    this.mainService.cryptoCompareGetApi(url).subscribe((res: any) => {
      console.log("get current ltc amount response ==>", res)
        this.ltcAmount = res.LTC
        this.mainService.hideSpinner();
    })
  }

  // ------------------ send prize ltc amount to user ------------------------------- //
  sendPrice() {
    // $('#sendRewardId').modal('hide')
    // this.mainService.successToast('Prize send successfully')
    this.mainService.showSpinner();
    //let url = `account/admin/user-management/send-ltc-to-user?toUserId=${this.userId}&ltcAmount=${this.ltcAmount}`
    let url = `account/admin/user-management/offer-credit?userId=${this.userId}`
    this.mainService.getApi(url,  1).subscribe((res: any) => {
      console.log("active user response ==>", res)
      $('#sendRewardId').modal('hide')
      if (res.status == 200) {
        this.getUserList()
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }



  // -------------------- export to excel --------------------------- //
  exportToExcel() {
    let dataArray = [];
    this.referralDataArray.forEach((element, ind) => {
      dataArray.push({
        "S.No": ind + 1,
        "Refer To User Name": element.referToUserName ? element.referToUserName : 'N/A',
        "Refer To Email Address": element.referToEmail ? element.referToEmail : 'N/A',
        "Registration Date & Time": element.registrationDateTime ? this.datePipe.transform(element.registrationDateTime, 'medium') : 'N/A',
        "Login IP Address": element.referToIpAddress ? element.referToIpAddress : 'N/A',
        "Lotto Ticket Purchased": element.isLottoTickerPurchased ? element.isLottoTickerPurchased : 'N/A',
        "Refer By User Name": element.referByUserName ? element.referByUserName : 'N/A',
        "Referral Id": element.referById ? element.referById : 'N/A',
        "Refer By Email Address": element.referByEmail ? element.referByEmail : 'N/A'
      })
    })
    this.excelService.exportAsExcelFile(dataArray, 'referral_management');
  }

}
