import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/constants/end-point';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-blog',
  templateUrl: './edit-blog.component.html',
  styleUrls: ['./edit-blog.component.scss']
})
export class EditBlogComponent implements OnInit {
  editBlogForm: FormGroup
  blogId: any;
  blogData: any;
  image: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res.blogId) {
        this.blogId = res.blogId
      }
    })
    this.editBlogFormValidation();
    this.viewBlog()
  }

  // ------------------ edit blog form validation ------------------------- //
  editBlogFormValidation() {
    this.editBlogForm = new FormGroup({
      'author': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'title': new FormControl('', Validators.required),
      'image': new FormControl('')
    })
  }

  // -------------------------- view blog ------------------------ //
  viewBlog() {
    this.mainService.showSpinner();
    let url = `${ApiEndPoint.viewBlog}?blogId=${this.blogId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log("view blog response ==>", res);
      if (res.status == 200) {
        this.image = res.data.image
        this.editBlogForm.patchValue({
          'author': res.data.author ? res.data.author : '',
          'description': res.data.description ? res.data.description : '',
          'title': res.data.title ? res.data.title : ''
        })
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    })
  }


  // -------------------------- edit blog ---------------------------- //
  editBlog() {
    let data = {
      'author': this.editBlogForm.value.author,
      "contentId": this.blogId,
      'description': this.editBlogForm.value.description,
      "isDeleted": false,
      'image': this.image,
      "title": this.editBlogForm.value.title,
    }
    console.log(data)
    let url = ApiEndPoint.editBlog
    this.mainService.showSpinner();
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log("edit blog response ==>", res)
      if (res.status == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message)
        this.router.navigate(['/blog-management'])
      } else {
        console.log("else error")
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }


  // image functionality
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.mainService.showSpinner();
    this.mainService.fileUploadPostApi(ApiEndPoint.uploadFile, fb).subscribe(res => {
      this.mainService.hideSpinner();
      if (res['status'] == '200') {
        this.image = res['data'];
      }
    }, err => {
      this.mainService.hideSpinner();
      if (err['status'] == '401') {
        this.mainService.errorToast('Unauthorized Access.');
      } else {
        this.mainService.errorToast('Something went wrong.');
      }
    })
  }

  // ------------------------ naviagte back -------------------------- //
  back() {
    this.router.navigate(['/blog-management'])
  }

}
