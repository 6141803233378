import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-static-content',
  templateUrl: './edit-static-content.component.html',
  styleUrls: ['./edit-static-content.component.scss']
})
export class EditStaticContentComponent implements OnInit {
  pageKey: any;
  contentId: any;
  showEditor: boolean = false;
  public staticContentData: string = '';

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res.pageKey) {
        this.pageKey = res.pageKey
        this.contentId = res.contentId
      }
    })
    this.getStaticContent()
  }


  // ------------------------ get static content ------------------------- //
  getStaticContent() {
    this.mainService.showSpinner();
    let url = `static/get-static-content?pageKey=${this.pageKey}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log("get static content response ==>", res)
      if (res.status == 200) {
        this.showEditor = true
        this.staticContentData = res.data.pageData
        this.mainService.hideSpinner();
      } else {
        this.showEditor = true
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
    })
  }

  // ------------------------- update static content ----------------------- //
  updateStaticContent() {
    let data = {
      "contentId": this.contentId,
      "pageData": this.staticContentData
    }
    console.log(data);
    let url = `static/admin/static-content/update-static-content`
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.mainService.successToast('Static content updated successfully.')
        this.router.navigate(['/static-content-management'])
      } else {
        this.mainService.errorToast('Something went wrong.')
      }
    })
  }

  // cancel
  cancel() {
    this.router.navigate(['static-content-management'])
  }

}
