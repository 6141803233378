import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  // baseURL = 'http://182.72.203.246:4052/'; // stagging ip
  baseURL = 'https://java-cryptocasino.mobiloitte.org/' // stagging domain
  // baseURL='https://cryptocasino-admin.mobiloitte.org/'
  // webUrl = 'http://localhost:4200/' // font-end browser ip
  webUrl = 'https://cryptocasino-admin.mobiloitte.org/' // font-end browser domain

  public loginStatus = new Subject();
  public loginData = new Subject()

  constructor(public httpClient: HttpClient, private toastrService: ToastrService, private spinnerService: NgxSpinnerService, private router: Router) { }


  // ---------------- get Api function -------------------- //
  getApi(endPointURL, isHeader): Observable<any> {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        })
      }
    }
    return this.httpClient.get(this.baseURL + endPointURL, httpHeaders)
  }


  // ---------------- post Api Function ------------------- //
  postApi(endPointURL, data, isHeader): Observable<any> {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        })
      }
    }
    return this.httpClient.post(this.baseURL + endPointURL, data, httpHeaders)
  }


  // ------------------ put Api Function ----------------- //
  putApi(endPointURL, data, isHeader): Observable<any> {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        })
      }
    }
    return this.httpClient.put(this.baseURL + endPointURL, data, httpHeaders)
  }
  patchApi(endPointURL, data, isHeader): Observable<any> {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        })
      }
    }
    return this.httpClient.patch(this.baseURL + endPointURL, data, httpHeaders)
  }



  // ------------------ delete Api Function -------------- //
  deleteApi(endPointURL, bodyData, isHeader): Observable<any> {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }),
        body: bodyData
      }
    }
    return this.httpClient.delete(this.baseURL + endPointURL, httpHeaders)
  }

  // ---------------- file upload post Api Function ------------------- //
  fileUploadPostApi(endPointURL, data): Observable<any> {
    return this.httpClient.post(this.baseURL + endPointURL, data)
  }

   // ---------------- crypto compare get Api Function ------------------- //
   cryptoCompareGetApi(url): Observable<any> {
    return this.httpClient.get(url)
  }
  // check admin login or not
  public isLoggedIn() {
    return localStorage.getItem('token') !== null;
  }


  // logout
  public logout() {
    this.loginData.next('');
    this.loginStatus.next(false)
    localStorage.removeItem('token');
    this.router.navigate(['login'])
  }


  // spinner service
  showSpinner() {
    this.spinnerService.show();
  }
  hideSpinner() {
    this.spinnerService.hide();
  }

  // toastr service
  successToast(msg) {
    this.toastrService.success(msg);
  }
  errorToast(msg) {
    this.toastrService.error(msg);
  }
  warningToast(msg) {
    this.toastrService.warning(msg);
  }
  infoToast(msg) {
    this.toastrService.info(msg);
  }

  /** to prevent first space */
  preventSpace(event) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  // To check Number Only //
  numOnly(event) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }
  liqudityUrl="https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=INR,LTC,USD&api_key=8055ecaa14aaea4cca1afca2794464bfc5965caa7dea6af8c060d22bc0a57377"
  public getLiquidity() {
    var httpOptions = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json',
      })
    }
    return this.httpClient.get(this.liqudityUrl, httpOptions);
  }

  OnlyNumAllowed(event) {
    var k = event.charCode;
    if(event.key=='Backspace')
    k=8;
    if(k>=48 && k <=57  || k ==8 || k==46)
    return true;
    else
    return false;
  }
}
