import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/constants/end-point';
import { RegEx } from 'src/app/constants/reg-ex';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  showEyePassword:any
  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.loginFormValidation();
    var rememberMe = JSON.parse(localStorage.getItem('rememberMe')) ? JSON.parse(localStorage.getItem('rememberMe')) : '';
    if (rememberMe != '') {
      this.loginForm.patchValue({
        email: window.atob(rememberMe.email),
        password: window.atob(rememberMe.password),
        rememberMe: window.atob(rememberMe.rememberMe)
      })
    }
  }

  //-------  login form validation -------- //
  loginFormValidation() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(RegEx.email)]),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl(false)
    });
  }

  // ---------  login form submit ------------- //
  login() {
    let data = {
      'email': this.loginForm.value.email,
      'password': this.loginForm.value.password,
      "userAgent": "",
      "location": "",
      "ipAddress": ""
    }
    this.mainService.showSpinner()
    this.mainService.postApi(ApiEndPoint.login, data, 0).subscribe((res: any) => {
      console.log("login response ==>", res)
      if (res.status == 200) {
        this.mainService.hideSpinner();
        localStorage.setItem('token', res.data.token);
        this.mainService.successToast('Login successfully.')
        this.mainService.loginStatus.next(true)
        this.getProfile()
        this.router.navigate(['/dashboard'])
        if (this.loginForm.value.rememberMe) {
          let remData = {
            "email": window.btoa(this.loginForm.value.email),
            "password": window.btoa(this.loginForm.value.password),
            "rememberMe": window.btoa(this.loginForm.value.rememberMe)
          }
          localStorage.setItem('rememberMe', JSON.stringify(remData))
        }
        else {
          localStorage.removeItem("rememberMe");
        }
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  //  get profile
  getProfile() {
    // this.mainService.showSpinner()
    this.mainService.getApi('account/my-account', 1).subscribe((res: any) => {
      console.log("login profile response ==>", res);
      if (res.status == 200) {
        this.mainService.loginData.next(res.data)
        // this.mainService.hideSpinner();
      } else {
        // this.mainService.hideSpinner();
      }
    })
  }

  showHidePassword() {
    this.showEyePassword = !this.showEyePassword
  }


}