<aside [hidden]="!showSidebar" class="sidebar" style="z-index: 7">
    <div class="adminprofile">
        <a class="navbar-brand logo" style="width: 100%; display: inline-flex; justify-content: center;">
            <img class="lg-logo" [src]="profileData?.imageUrl || 'assets/img/profile-img.jpg'" alt="image"
                style=" border-radius: 50px; cursor: pointer;" (click)="adminProfile()">
        </a>
        <div id="admin" class="profile-data-section" style="text-align: center; color: #221d54;">
            <label style="cursor: pointer;" (click)="adminProfile()">{{profileData?.firstName || 'Admin' }}</label>
            <p style="cursor: pointer;" (click)="adminProfile()">{{profileData?.email || 'admin@gmail.com' }}</p>
        </div>
    </div>
    <div class="sidebar-scroller">
        <div id="accordion" class="sidebar-menu">

            <div class="menu-box toggle-menu" [ngClass]="{'active' : currentUrl === '/dashboard'}"
                style="border-top: 1px solid #1975a7;">
                <div class="card-header clearfix" [routerLink]="['/dashboard']" id="headingEmpty" data-toggle="collapse"
                    data-target="#collapseEmpty" aria-expanded="false" aria-controls="collapseEmpty">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-dashboard" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Dashboard</span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu"
                [ngClass]="{'active' : currentUrl === '/user-management' || currentUrl?.includes('/view-user') || currentUrl?.includes('/edit-user') }">
                <div class="card-header clearfix" [routerLink]="['/user-management']" id="headingEmpty"
                    data-toggle="collapse" data-target="#collapseEmpty" aria-expanded="false"
                    aria-controls="collapseEmpty">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-users" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">User Management</span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu"
                [ngClass]="{'active' : currentUrl === '/transaction-history' || currentUrl?.includes('/view-transaction')}">
                <div class="card-header clearfix" [routerLink]="['/transaction-history']" id="headingEmpty"
                    data-toggle="collapse" data-target="#collapseEmpty" aria-expanded="false"
                    aria-controls="collapseEmpty">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-exchange" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Transaction Management</span></a>
                </div>
            </div>
            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/jackpot-lottery-management' ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/jackpot-lottery-management']" id="headingEmpty"
                    data-toggle="collapse" data-target="#collapseEmpty" aria-expanded="false"
                    aria-controls="collapseEmpty">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-ticket" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Lottery Management</span></a>
                </div>
            </div> -->
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/lottery-purchase-data' ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/lottery-purchase-data']" id="headingEmpty"
                    data-toggle="collapse" data-target="#collapseEmpty" aria-expanded="false"
                    aria-controls="collapseEmpty">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-ticket" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Lottery Purchase Data</span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/lottery-results' ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/lottery-results']" id="headingEmpty"
                    data-toggle="collapse" data-target="#collapseEmpty" aria-expanded="false"
                    aria-controls="collapseEmpty">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-ticket" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Weekly Lottery Results</span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/hot-wallet' ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/hot-wallet']" id="headingEmpty"
                    data-toggle="collapse" data-target="#collapseEmpty" aria-expanded="false"
                    aria-controls="collapseEmpty">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <!-- <img src="assets/img/wallet1.png" alt=""> -->
                            <i class="material-icons">account_balance_wallet</i>
                        </span><span
                            class="side_page_name head_color">LTC Wallet Management</span></a>
                </div>
            </div>
          

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/referral-management' ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/referral-management']" id="headingEmpty"
                    data-toggle="collapse" data-target="#collapseEmpty" aria-expanded="false"
                    aria-controls="collapseEmpty">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-user-plus" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Referral Management</span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/static-content-management' || currentUrl?.includes('/view-static-content') || currentUrl?.includes('/edit-static-content') ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/static-content-management']" id="headingEmpty"
                    data-toggle="collapse" data-target="#collapseEmpty" aria-expanded="false"
                    aria-controls="collapseEmpty">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-file-text" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Static Content Management</span></a>
                </div>
            </div>


            <div class="menu-box toggle-menu" [ngClass]="currentUrl?.includes('/faq-payout-management') || currentUrl?.includes('/add-faq') || currentUrl?.includes('/view-faq') 
            || currentUrl?.includes('/edit-faq') || currentUrl?.includes('/add-payout') || currentUrl?.includes('/view-payout') || currentUrl?.includes('/edit-payout')   ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/faq-payout-management']" id="headingEmpty"
                    data-toggle="collapse" data-target="#collapseEmpty" aria-expanded="false"
                    aria-controls="collapseEmpty">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-question-circle-o"
                                aria-hidden="true"></i></span><span class="side_page_name head_color">FAQ's
                            Management</span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu"
                [ngClass]="currentUrl === '/blog-management' || currentUrl === '/add-blog' || currentUrl?.includes('/view-blog') || currentUrl?.includes('/edit-blog') ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/blog-management']" id="headingEmpty"
                    data-toggle="collapse" data-target="#collapseEmpty" aria-expanded="false"
                    aria-controls="collapseEmpty">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-newspaper-o" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Blog Management</span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu"
            [ngClass]="currentUrl === '/banner-management' || currentUrl === '/add-banner' || currentUrl?.includes('/view-banner') || currentUrl?.includes('/edit-banner') ? 'active': ''">
            <div class="card-header clearfix" [routerLink]="['/banner-management']" id="headingEmpty"
                data-toggle="collapse" data-target="#collapseEmpty" aria-expanded="false"
                aria-controls="collapseEmpty">
                <a class="side_menu">
                    <span class="side_menu_icon"><i class="fa fa-picture-o" aria-hidden="true"></i>
                    </span><span class="side_page_name head_color">Banner Management</span></a>
            </div>
        </div>

            <div class="menu-box">
                <div class="card-header clearfix" id="headingOne">
                    <a class="side_menu">
                        <span class="side_menu_icon"><img src="assets/img/logout-icon.png" alt="Icon"></span><span
                            class="side_page_name head_color" (click)="logOutModal()">Log Out</span>
                    </a>
                </div>
            </div>
            <!-- empty collapse -->
            <div id="collapseEmpty" class="collapse" aria-labelledby="headingEmpty" data-parent="#accordion"
                style="display: none;">
            </div>

        </div>
    </div>

</aside>







<!-- logout modal -->
<div class="modal fade global-modal reset-modal" id="logoutModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Logout?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to logout?</p>
                                <div class="modal-btn-box">
                                    <button type="button" class="btn btn-theme" (click)="logout()">YES</button>
                                    <button type="button" class="btn btn-red ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>