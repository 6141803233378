import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-wallet-user-data',
  templateUrl: './view-wallet-user-data.component.html',
  styleUrls: ['./view-wallet-user-data.component.scss']
})
export class ViewWalletUserDataComponent implements OnInit {
  userId: any;
  userDetails: any;
  userWalletDetails: any;

  constructor(private activatedRoute: ActivatedRoute, public mainService: MainService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      this.userId = res.userId;
    })
    this.getUserDetails();
    this.getUserWalletDetails();
   
  }

  // ---------------- get user details --------------------------- //

  getUserDetails() {
    this.mainService.showSpinner();
    let url = `account/admin/user-management/user-details?userId=${this.userId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.userDetails = res.data
        this.mainService.hideSpinner()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }


  // ------------------------- get user wallet ------------------- //
  getUserWalletDetails() {
    //let url = `account/get-wallet-of-user?userId=${this.userId}`
    let url = `wallet/wallet/get-all-user-balance-and-coinlist-withName?userId=${this.userId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.userWalletDetails = res.data.userBalance[0] ? res.data.userBalance[0] : ''
      } else {
        this.mainService.errorToast(res.message)
      }
    })
  }

}
