import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-static-content',
  templateUrl: './view-static-content.component.html',
  styleUrls: ['./view-static-content.component.scss']
})
export class ViewStaticContentComponent implements OnInit {
  pageKey: any;
  contentId: any;

  staticContentData: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res.pageKey) {
        this.pageKey = res.pageKey
        this.contentId = res.contentId
      }
    })
    this.getStaticContent()
  }

// ------------------- get static content ------------------------ //
  getStaticContent() {
    this.mainService.showSpinner();
    let url = `static/get-static-content?pageKey=${this.pageKey}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log("get static content response ==>", res)
      if (res.status == 200) {
        this.staticContentData = res.data ? res.data : '';
        this.mainService.hideSpinner();
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.responseMessage)
      }
    })
  }

  // ---------------- edit static content ------------------------ //
  edit() {
    this.router.navigate(['/edit-static-content'], { queryParams: { pageKey: this.pageKey, contentId: this.contentId } })
  }

  // ---------------- navigate back ---------------------- //
  back() {
    this.router.navigate(['/static-content-management'])
  }

}
