<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Transaction Management / View</h1>
        </div>
        <div class="content-section">
           
            <div class="order-view  mrgn-0-auto setting-page">
                <div class="">
                 
                    <div class=" input-style view-label-align">
                        <div class="form-group row align-items-baseline">
                            <div class="col-md-12" style="text-align: center">
                                <img [src]="userDetails?.imageUrl || 'assets/img/profile-img.jpg'"
                                    style="height: 100px;width: 100px; border-radius: 50%; border: 1px solid black!important;">
                            </div>
                        </div>
                        <div class="row mt-5" >
                            <!-- left row -->
                            <div class="col-md-6">
                                <!-- left row -->
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-6 textalignright ">User Name <span>:</span></label>
                                    <div class="col-md-6 textaligncenter">
                                        <div class="form-group">
                                            <p style="padding: 0px 5px!important;">{{transactionDetails?.userName  || 'N/A'}}</p>
                                        </div>
                                    </div>
                                </div>
                             
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-6 textalignright">Email Address <span>:</span></label>
                                    <div class="col-md-6 textaligncenter">
                                        <div class="form-group ">
                                            <p class="content-view">{{transactionDetails?.userEmail || 'N/A'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-6 textalignright">Transaction ID <span>:</span></label>
                                    <div class="col-md-6 textaligncenter">
                                        <div class="form-group ">
                                            <p class="content-view">{{transactionDetails?.txnId || 'N/A'}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-6 textalignright">Transaction Type <span>:</span></label>
                                    <div class="col-md-6 textaligncenter">
                                        <div class="form-group ">
                                            <p class="content-view">{{transactionDetails?.txnType || 'N/A'}}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- end left row  -->

                            <!-- right row -->
                            <div class="col-md-6">
                                <!-- left row -->
                               
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-6 textalignright">Status <span>:</span></label>
                                    <div class="col-md-6 textaligncenter">
                                        <div class="form-group ">
                                            <p class="content-view">{{transactionDetails?.status || 'N/A'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-6 textalignright">Transaction Date & Time
                                        <span>:</span></label>
                                    <div class="col-md-6 textaligncenter">
                                        <div class="form-group ">
                                            <p class="content-view">{{(transactionDetails?.txnTime | date:'medium')
                                                || 'N/A'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-6 textalignright">Currency Type<span>:</span></label>
                                    <div class="col-md-6 textaligncenter">
                                        <div class="form-group ">
                                            <p class="content-view">{{transactionDetails?.coinType || 'N/A'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-6 textalignright"> Amount<span>:</span></label>
                                    <div class="col-md-6 textaligncenter">
                                        <div class="form-group ">
                                            <p class="content-view">{{transactionDetails?.units || 'N/A'}} LTC</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- end right row -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>