<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Static Content Management / Edit</h1>
        </div>
        <div class="content-section">
            <div class="order-view  mrgn-0-auto setting-page">
                <div class="">
                    <div class="admin-profile">
                        <h4 class="mb20 text-center">{{(pageKey | replaceUnderscore | titlecase) || 'N/A'}}</h4>
                    </div>
                    <div class=" input-style view-label-align">
                        <div class="form-group row align-items-baseline">
                            <div class="col-md-12 textaligncenter">
                                <div class="form-group">
                                    <ck-editor *ngIf="showEditor" name="editor1" [(ngModel)]="staticContentData"
                                        skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme"
                            (click)="updateStaticContent()">Update</button>
                        <button class="btn btn-large  max-WT-150 btn-theme" (click)="cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>