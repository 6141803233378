<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Banner Management / Edit Banner</h1>
        </div>
        <div class="content-section">
            <div class="order-view mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="admin-profile">
                    </div>
                    <div class=" input-style mb20 mt20 ">
                        <form [formGroup]="addBannerForm">
               
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">Banner Image <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group ">
                                        <input type="file" formControlName="image" accept="image/jpg,image/jpeg,image/png" (change)="uploadImg($event)">
                                       
                                    </div>
                                    <span *ngIf="addBannerForm.get('image').touched && addBannerForm.get('image').dirty">
                                        <span style="color: red;">Please upload image of size 1920 * 450 or 1640 * 450 only.</span>

                                    </span>
                                    <img *ngIf="image" [src]="image" height="200">
                                </div>
                            </div>
                  
                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!addBannerForm.valid"
                           (click)="editBanner()">Update</button>
                        <button class="btn btn-large  max-WT-150 btn-theme" (click)="back()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>