import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/constants/end-point';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-blog-management',
  templateUrl: './blog-management.component.html',
  styleUrls: ['./blog-management.component.scss']
})
export class BlogManagementComponent implements OnInit {
  blogDataArray: any[] = []
  blogId: any;
  // pagination
  itemsPerPage: number = 10;
  currentPage: number = 1;
  totalItems: any;

  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.getBlogList()
  }

  // ------------------------- get blog list -------------------------- //
  getBlogList() {
    this.mainService.showSpinner()
    let url = `${ApiEndPoint.blogList}?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.blogDataArray = res.data.list ? res.data.list : []
        this.totalItems = res.data.count ? res.data.count : 0
        this.mainService.successToast(res.message)
        this.mainService.hideSpinner();
      } else {
        this.blogDataArray = []
        this.totalItems = 0
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)

      }
    })
  }

  pagination(event) {
    this.currentPage = event
    this.getBlogList()
  }

  // -------------------------- navigate to add blog ---------------------------- //
  addBlog() {
    this.router.navigate(['/add-blog'])
  }

  // ---------------------------- navigate to view blog ------------------------------- //
  viewBlog(blogId) {
    console.log('id', blogId);
    this.router.navigate(['/view-blog'], { queryParams: { blogId: blogId } })
  }

  // ------------------------------- navigate to edit blog --------------------------- //
  editBlog(blogId) {
    console.log('id', blogId);
    this.router.navigate(['/edit-blog'], { queryParams: { blogId: blogId } })
  }

  // ------------------------------- delete blog ----------------------------- //
  openDeleteBlogModal(blogId) {
    $('#deleteBlog').modal('show')
    this.blogId = blogId
  }
  deleteBlog() {
    let url = `${ApiEndPoint.deleteBlog}?blogId=${this.blogId}`
    this.mainService.showSpinner();
    this.mainService.postApi(url, {}, 1).subscribe((res: any) => {
      console.log("delete response ==>", res)
      $('#deleteBlog').modal('hide');
      if (res.status == 200) {
        this.getBlogList()
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

}
