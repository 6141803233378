import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  editProfileForm: FormGroup
  file: any;
  imageType: any;
  imageUrl: any;
  countryArray: string[] = []
  countryCode: string[] = ['+90', '+91', '+92', '+93'];
  defaultCode: string = '+91';
  // editImage: any;
  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.editProfileFormValidation();
    this.getCountryList()
    this.getProfile();
  }
  editProfileFormValidation() {
    this.editProfileForm = new FormGroup({
      'firstName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'country': new FormControl(''),
      'dob': new FormControl(''),
      'countryCode': new FormControl(''),
      'phoneNo': new FormControl(''),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'image': new FormControl('')
    })
    this.editProfileForm.controls['countryCode'].setValue(this.defaultCode, { onlySelf: true });
  }

  getCountryList() {
    this.mainService.getApi('account/get-country-list', 1).subscribe((res: any) => {
      console.log("country response ==>", res);
      if (res.status == 200) {
        this.countryArray = res.data
        // this.mainService.successToast(res.message);
      } else {
        // this.mainService.errorToast(res.message)
      }
    })
  }

  // get profile
  getProfile() {
    this.mainService.showSpinner()
    this.mainService.getApi('account/my-account', 1).subscribe((res: any) => {
      console.log("profile response ==>", res);
      if (res.status == 200) {
        this.imageUrl = res.data.imageUrl? res.data.imageUrl:''
        this.editProfileForm.patchValue({
          'firstName': res.data.firstName ? res.data.firstName : '',
          'lastName': res.data.lastName ? res.data.lastName : '',
          'country': res.data.country ? res.data.country : '',
          'dob': res.data.dob ? res.data.dob : '',
          'countryCode': res.data.countryCode ? res.data.countryCode : '',
          'phoneNo': res.data.phoneNo ? res.data.phoneNo : '',
          'email': res.data.email ? res.data.email : ''
        })
        this.mainService.hideSpinner();
        this.mainService.loginData.next(res.data);
        // this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // update profile
  editProfile() {
    let data = {
      'imageUrl': this.imageUrl,
      // 'imageUrl': 'string',
      'firstName': this.editProfileForm.value.firstName,
      'lastName': this.editProfileForm.value.lastName,
      'country': this.editProfileForm.value.country,
      'dob': this.editProfileForm.value.dob,
      'countryCode': this.editProfileForm.value.countryCode,
      'phoneNo': this.editProfileForm.value.phoneNo,
      "pnWithoutCountryCode": "string",
      "location": "string",
      "state": "string",
      "zipCode": "string",
      "ipAddress": "string",
      "gender": "string",
      "address": "string",
      "city": "string"
    }
    this.mainService.showSpinner();
    this.mainService.postApi('account/profile-update', data, 1).subscribe((res: any) => {
      console.log("edit profile response ==>", res)
      if (res.status == 200) {
        this.mainService.hideSpinner();

        this.mainService.successToast(res.message);
        // this.mainService.loginData.next(res.data);
        this.getProfile()
        // this.router.navigate(['my-profile'])
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.meaasge)
      }
    })
  }

  ValidateFileUpload(event) {
    this.file = event.target.files;
    if (this.file[0]) {
      this.imageType = this.file[0].type;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(this.file[0]);
    }
  }

  // Image Functionality
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.mainService.showSpinner();
    this.mainService.fileUploadPostApi('account/upload-file', fb).subscribe(res => {
      this.mainService.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
      }
    }, err => {
      this.mainService.hideSpinner();
      if (err['status'] == '401') {
        // this.mainService.onLogout();
        this.mainService.errorToast('Unauthorized Access.');
      } else {
        this.mainService.errorToast('Something went wrong.');
      }
    })
  }
  
}
