<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">FAQ's / Pay Out FAQ's Management</h1>
        </div>
        <div class="content-section min-ht-0">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-section">
                            <div class="tab">
                                <button class="tablinks" [ngClass]="tabView == 'FAQ' ? 'active': ''"
                                    (click)="viewTab('FAQ')">FAQ's</button>
                                <button class="tablinks" [ngClass]="tabView == 'PAY_OUT_FAQ' ? 'active': ''"
                                    (click)="viewTab('PAY_OUT_FAQ')">Pay Out FAQ's</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- faq's tab content -->
        <app-faq-management *ngIf="tabView == 'FAQ'" [titleKey]="titleKey"> >
        </app-faq-management>
        <!-- end faq's tab content -->
        <!-- pay out tab content  -->
        <app-payout-management *ngIf="tabView == 'PAY_OUT_FAQ'">
        </app-payout-management>
        <!--end pay out tab content  -->
    </main>
</div>