import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelService } from 'src/app/provider/excel.service';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-lottery-purchase-data',
  templateUrl: './lottery-purchase-data.component.html',
  styleUrls: ['./lottery-purchase-data.component.scss']
})
export class LotteryPurchaseDataComponent implements OnInit {
  search: string;
  fromDate: any;
  toDate: any;
  today = new Date().toISOString().substr(0, 10);

  lotteryDataArray: any[] = []
  // pagination
  itemsPerPage = 10;
  currentPage = 1;
  totalItems: any;

  constructor(public mainService: MainService, private datePipe: DatePipe, public excelService: ExcelService,private router: Router) { }

  ngOnInit() {
    this.getLotteryPurchaseList()
  }

  // ----------- search ------------- //
  searchEvent() {
    if (this.search || this.fromDate || this.toDate) {
      this.currentPage = 1;
      this.getLotteryPurchaseList()
    }
  }

  // --------- reset ------------- //
  resetEvent() {
    if (this.search || this.fromDate || this.toDate) {
      this.currentPage = 1;
      this.search = ''
      this.fromDate = '';
      this.toDate = '';
      this.getLotteryPurchaseList()
    }
  }

  // ------- get lottery purchase list -------- //
  getLotteryPurchaseList() {
    this.mainService.showSpinner();
    let url = `account/find-lottery-data-all-user?page=${(this.currentPage - 1) + '&pagesize=' + (this.itemsPerPage) +
      (this.search ? ('&search=' + this.search) : '') +
      (this.fromDate ? ('&fromDate=' + (new Date(this.fromDate).getTime())) : '') +
      (this.toDate ? ('&toDate=' + (new Date(this.toDate).getTime() + (1000 * 60 * 60 * 24))) : '')}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log("get lottery purchase list response ==>", res)
      if (res.status == 200) {
        this.lotteryDataArray = res.data.data ? res.data.data : '';
        this.totalItems = res.data.count;
        this.mainService.successToast(res.message)
        this.mainService.hideSpinner();
      } else {
        this.lotteryDataArray = []
        this.totalItems = 0
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    }, err => {
      this.lotteryDataArray = [];
      this.totalItems = 0
      this.mainService.hideSpinner();
      this.mainService.errorToast(err.responseMessage)
    })
  }

  viewUser(id){
    this.router.navigate(['/view-user'], { queryParams: { userId: id } })
  }

  // ----------------- pagination --------------------- //
  pagination(event) {
    this.currentPage = event;
    this.getLotteryPurchaseList()
  }

  // ----------------- export to excel ----------------- //
  exportToExcel() {
    let dataArray = [];
    this.lotteryDataArray.forEach((element, ind) => {
      dataArray.push({
        "S.No.": ind + 1,
        "Date": element.creationDate ? this.datePipe.transform(element.creationDate, 'mediumDate') : 'N/A',
        "Time": element.creationDate ? this.datePipe.transform(element.creationDate, 'mediumTime') : 'N/A',
        "User Name": element.userName ? element.userName : 'N/A',
        "Lottery Name": element.lotteryName ? element.lotteryName : 'N/A',
        // "No. Of Lotto lines": element.noOfLottoLines ? element.noOfLottoLines : 'N/A',
        "Lotto Number": element.numberOne ? element.numberOne + ',' + element.numberTwo + ',' + element.numberThree + ',' + element.numberFour + ',' + element.numberFive + ',' + element.numberSix + ',' + element.numberBouns: 'N/A',
        // "Amount": element.amount ? element.amount : 'N/A'
      })
    })
    this.excelService.exportAsExcelFile(dataArray, 'lottery_purchase_data');
  }

}
