import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExcelService } from 'src/app/provider/excel.service';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-lottery-status-user-data',
  templateUrl: './view-lottery-status-user-data.component.html',
  styleUrls: ['./view-lottery-status-user-data.component.scss']
})
export class ViewLotteryStatusUserDataComponent implements OnInit {
  userId: any;
  userDetails: any;
  toatlLottoWon: any;
  userCryptoWallet: any;

  // search properties
  search: string;
  fromDate: any;
  toDate: any;
  today = new Date().toISOString().substr(0, 10);

  // pagination
  itemsPerPage = 10;
  currentPage = 1;
  totalItems: any;
  userLotteryDataArray: any[] = []

  constructor(private activatedRoute: ActivatedRoute, public mainService: MainService, private excelService: ExcelService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      this.userId = res.userId
    })
    this.getUserDetails();
    this.getTotalWinLotto();
    this.getCryptoWallet()
    // table events
    this.getUserLotteryDataList()
  }

  // ------------------- get user details ----------------------- //
  getUserDetails() {
    this.mainService.showSpinner();
    let url = `account/admin/user-management/user-details?userId=${this.userId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.userDetails = res.data
        this.mainService.hideSpinner()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  // ------------------- get total lotto won ------------------------- //
  getTotalWinLotto() {
    let url = `account/total-win-lotto?userUserId=${this.userId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.toatlLottoWon = res.data
      } else {
        this.mainService.errorToast(res.message)
      }
    })
  }

  // ------------------ get crypto wallet ---------------------------- //
  getCryptoWallet() {
    // let url = `account/get-crypto-wallet?userUserId=${this.userId}`
    // this.mainService.getApi(url, 1).subscribe((res: any) => {
    //   console.log(res)
    //   if (res.status == 200) {
    //     this.userCryptoWallet = res.data ? res.data[0] : ''
    //   } else {
    //     this.mainService.errorToast(res.message)
    //   }
    // })
  
      //let url = `account/get-wallet-of-user?userId=${this.userId}`
      let url = `wallet/wallet/get-all-user-balance-and-coinlist-withName?userId=${this.userId}`
      this.mainService.getApi(url, 1).subscribe((res: any) => {
        console.log(res)
        if (res.status == 200) {
          this.userCryptoWallet = res.data.userBalance[0] ? res.data.userBalance[0] : ''
        } else {
          this.mainService.errorToast(res.message)
        }
      })

  }



  /**
   * ***************************************************************************************
   * lotto table
   */

  // ----------- search ------------- //
  searchEvent() {
    if (this.search || this.fromDate || this.toDate) {
      this.currentPage = 1;
      this.getUserLotteryDataList()
    }
  }

  // --------- reset ------------- //
  resetEvent() {
    if (this.search || this.fromDate || this.toDate) {
      this.currentPage = 1;
      this.search = ''
      this.fromDate = '';
      this.toDate = '';
      this.getUserLotteryDataList()
    }
  }
  // ------- get user list -------- //
  getUserLotteryDataList() {
    let url = `account/find-lottery-data-by-user-base?userUserId=${this.userId + '&page=' + (this.currentPage - 1) + '&pagesize=' + (this.itemsPerPage) +
      (this.search ? ('&search=' + this.search) : '') +
      (this.fromDate ? ('&fromDate=' + (new Date(this.fromDate).getTime())) : '') +
      (this.toDate ? ('&toDate=' + (new Date(this.toDate).getTime() + (1000 * 60 * 60 * 24))) : '')}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log("get user lottery list response ==>", res)
      if (res.status == 200) {
        this.userLotteryDataArray = res.data.data ? res.data.data : '';
        this.totalItems = res.data.count;
      } else {
        this.userLotteryDataArray = [];
        this.totalItems = 0;
        this.mainService.errorToast(res.responseMessage)
      }
    }, err => {
      this.userLotteryDataArray = [];
      this.totalItems = 0;
      this.mainService.errorToast(err.responseMessage)
    })
  }

  // ----------------- pagination --------------------- //
  pagination(event) {
    this.currentPage = event;
    this.getUserLotteryDataList()
  }

  // -------------------- export to excel --------------------------- //
  exportToExcel() {
    let dataArray = [];
    this.userLotteryDataArray.forEach((element, ind) => {
      dataArray.push({
        "S.No": ind + 1,
        "Date": element.creationDate ? this.datePipe.transform(element.creationDate, 'mediumDate') : 'N/A',
        "Time": element.creationDate ? this.datePipe.transform(element.creationDate, 'mediumTime') : 'N/A',
        "Lotto Number": element.numberOne ? element.numberOne + ',' + element.numberTwo + ',' + element.numberThree + ',' + element.numberFour + ',' + element.numberFive + ',' + element.numberSix : 'N/A',
        "Amount": element.amount ? element.amount : 'N/A',
        "Status": element.priseStatus ? element.priseStatus : 'N/A',
        "Jackpot Lottery Names": element.lotteryName ? element.lotteryName : 'N/A',
      })
    })
    this.excelService.exportAsExcelFile(dataArray, 'user_lottery');
  }

}
