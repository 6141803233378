<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">FAQ's Management / Add FAQ</h1>
        </div>
        <div class="content-section">
           
            <div class="order-view mrgn-0-auto setting-page">
                <div class="mb40">
                    <div class="admin-profile">
                    </div>
                    
                    <div class="input-style mb20 mt20 ">
                        <form [formGroup]="addFaqForm">
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright ">Question<span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Question"
                                            formControlName="question" (keypress)="mainService.preventSpace($event)"
                                            maxlength="512">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addFaqForm.get('question').hasError('required') && (addFaqForm.get('question').dirty || addFaqForm.get('question').touched)">
                                                *Please enter question.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-2 textalignright">Answer <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group ">
                                        <textarea type="text" class="form-control" rows="10" placeholder="Enter Answer"
                                            formControlName="answer" (keypress)="mainService.preventSpace($event)"
                                            maxlength="2000"></textarea>
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addFaqForm.get('answer').hasError('required') && (addFaqForm.get('answer').dirty || addFaqForm.get('answer').touched)">
                                                *Please enter answer.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!addFaqForm.valid"
                            (click)="addFaq()">Save</button>
                        <button class="btn btn-large  max-WT-150 btn-theme" (click)="back()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>