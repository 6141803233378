<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Lottery Data</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div class="sec_head_new sec_head_new_after">
                                <!-- <form [formGroup]="searchForm"> -->
                                <div class="row">
                                    <div class="col-md-2">
                                        <!-- <input type="date" class="form-control" [(ngModel)]="fromDate" [max]="today"> -->
                                        <mat-form-field appearance="outline">
                                            <!-- <mat-label>From date</mat-label> -->
                                            <input matInput [matDatepicker]="picker" placeholder="From date" [(ngModel)]="fromDate" [max]="today" >
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                          </mat-form-field>
                                    </div>
                                    <div class="col-md-2">
                                        <!-- <input type="date" class="form-control" [(ngModel)]="toDate" [min]="fromDate"
                                            [disabled]="!fromDate"> -->
                                            <mat-form-field appearance="outline">
                                                <!-- <mat-label>To date</mat-label> -->
                                                <input  matInput [matDatepicker]="picker1" placeholder="To date" [(ngModel)]="toDate" [min]="fromDate"
                                                [disabled]="!fromDate" >
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                              </mat-form-field>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-2">
                                        <div class="filter_search mb20 width100">
                                            <div class="input-group filter_search_group">
                                                <input type="text" class="form-control overflow-text myinput"
                                                    [(ngModel)]="search" placeholder="Search by User Name" maxlength="60">
                                                <div class="input-group-append">
                                                    <button class="btn btn_search_group" type="button"><img
                                                            src="assets/img/icon-search.png" alt="Search"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-4">
                                        <div class="text-center admin-btn">
                                            <button type="button" class="btn  btn-theme Go_btn"
                                                (click)="searchEvent()">Submit</button>
                                            <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                (click)="resetEvent()">Reset</button>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2">
                                        <div class="justify-content-end">
                                            <button type="button" class="btn btn-theme Go_btn " style="padding-left: 0.65rem;
                                                padding-right: 0.65rem;" (click)="exportToExcel()">Export To
                                                Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- </form> -->
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th class="Sorting-img">S.No.</th>
                                        <th class="Sorting-img">Date</th>
                                        <th class="Sorting-img">Time</th>
                                        <th class="Sorting-img">User Name</th>
                                        <th class="Sorting-img">Lottery Name</th>
                                        <!-- <th class="Sorting-img">No. Of Lotto lines</th> -->
                                        <th class="Sorting-img">Lotto Number</th>
                                        <th class="Sorting-img">Action</th>
                                        <!-- <th class="Sorting-img">Amount</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of lotteryDataArray | paginate:{itemsPerPage:itemsPerPage, currentPage:currentPage, totalItems:totalItems}; let i = index"
                                        class="text-center">
                                        <td>{{itemsPerPage * (currentPage -1)+i+1}}</td>
                                        <td class="content">{{item?.creationDate | date:'mediumDate'}}</td>
                                        <td class="content">{{item?.creationDate | date:'mediumTime' || 'N/A'}}</td>
                                        <td class="content">{{item?.userName || 'N/A'}}</td>
                                        <!-- <td class="content">{{item?.lotteryName || 'N/A'}}</td> -->
                                        <td class="content">NY_LOTTERY
                                        </td>
                                        <!-- <td class="content">{{item?.noOfLottoLines}}</td> -->
                                        <td class="content">
                                            <div class="lottery-number-box">
                                                <span class="circle red-circle">{{item?.numberOne}}</span>
                                                <span class="circle red-circle">{{item?.numberTwo}}</span>
                                                <span class="circle red-circle">{{item?.numberThree}}</span>
                                                <span class="circle red-circle">{{item?.numberFour}}</span>
                                                <span class="circle red-circle">{{item?.numberFive}}</span>
                                                <span class="circle red-circle">{{item?.numberSix}}</span>
                                                <span style="border-left: 1px solid #000;"></span>
                                                <span class="circle bonus-num-bg-color">{{item?.numberBouns}}</span>
                                            </div>
                                        </td>
                                        <td class="content">
                                            <button class="btn btn-info" (click)="viewUser(item?.fkUserId)">
                                                <i class="fa fa-eye" style="color:white"></i></button>
                                        </td>
                                        <!-- <td class="content">{{item?.amount || 'N/A'}}</td> -->
                                    </tr>
                                    <tr *ngIf="!lotteryDataArray.length">
                                        <td class="table-no-record" colspan="8">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 10">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>