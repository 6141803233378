import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-payout',
  templateUrl: './view-payout.component.html',
  styleUrls: ['./view-payout.component.scss']
})
export class ViewPayoutComponent implements OnInit {
  payOutFaqId: any;
  payOutFaqData: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      if (res.payOutFaqId) {
        this.payOutFaqId = res.payOutFaqId
      }
    })
    this.viewFaq();
  }

  // -------------------------- view faq ------------------------ //
  viewFaq() {
    this.mainService.showSpinner();
    let url = `static/admin/static-content/view-Guarenteed-Pay-Outs?payOutsId=${this.payOutFaqId}`
    this.mainService.postApi(url, {}, 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.payOutFaqData = res.data
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message);
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message);
      }
    })
  }

  // ---------------------- navigate back --------------------- //
  back() {
    this.router.navigate(['/faq-payout-management'], { queryParams: { tabView: 'PAY_OUT_FAQ' } })
  }

}
