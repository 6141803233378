import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  resetForm: FormGroup;

  constructor(private router: Router, public mainService: MainService) { }

  ngOnInit() {
    this.resetForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})+$/)]))
    })
  }

  get f() { return this.resetForm.controls }

  submit() {
    if (this.resetForm.valid) {
      let url = `account/forget-password?email=${this.resetForm.value.email}&webUrl=${this.mainService.webUrl}reset-password`
      this.mainService.showSpinner()
      this.mainService.getApi(url, 0).subscribe((res: any) => {
        console.log('forgot password response==>', res);
        if (res.status == 200) {
          this.mainService.hideSpinner()
          this.mainService.successToast(res.message)
          this.resetForm.reset()
          this.router.navigate(['/login'])
        } else {
          this.mainService.hideSpinner()
          this.mainService.errorToast(res.message)
        }
      })
    }
    else {
      this.mainService.errorToast('Email address is required.')
      console.log('Email address is required.');
    }
  }

}
