import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-transaction',
  templateUrl: './view-transaction.component.html',
  styleUrls: ['./view-transaction.component.scss']
})
export class ViewTransactionComponent implements OnInit {
  userId: number;
  transactionId: number;
  userDetails: any;
  transactionDetails: any;

  constructor(private activatedRoute: ActivatedRoute, public mainService: MainService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      // this.userId = res.userId
      this.transactionId = res.transactionId
    })
    // this.getUserDetails();
    this.viewTransactionDetails();
  }

  // ----------------------- user details --------------------- //
  // getUserDetails() {
  //   this.mainService.showSpinner();
  //   // let url = `account/admin/user-management/user-details?userId=${this.userId}`
  //   let url = `wallet/admin/transaction-history/get-user-transaction-history?userId=${this.userId}`
  //   this.mainService.getApi(url, 1).subscribe((res: any) => {
  //     console.log(res)
  //     if (res.status == 200) {
  //       this.userDetails = res.data
  //       this.mainService.hideSpinner()
  //     } else {
  //       this.mainService.hideSpinner();
  //       this.mainService.errorToast(res.message)
  //     }
  //   })
  // }

  // -------------------- view transaction details ------------------- //
  viewTransactionDetails() {
    // let url = `account/get-view-transactions?transactionsId=${this.transactionId}`
    let url = `wallet/admin/transaction-history/get-transaction-details?txnId=${this.transactionId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.transactionDetails = res.data
      }
    })
  }

}
