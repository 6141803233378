import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './pages/authentication/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/authentication/login/login.component';
import { ResetPasswordComponent } from './pages/authentication/reset-password/reset-password.component';
import { AddBannerComponent } from './pages/dashboard-layout/banner-management/add-banner/add-banner.component';
import { BannerManagementComponent } from './pages/dashboard-layout/banner-management/banner-management/banner-management.component';
import { EditBannerComponent } from './pages/dashboard-layout/banner-management/edit-banner/edit-banner.component';
import { ViewBannerComponent } from './pages/dashboard-layout/banner-management/view-banner/view-banner.component';
import { AddBlogComponent } from './pages/dashboard-layout/blog-management/add-blog/add-blog.component';
import { BlogManagementComponent } from './pages/dashboard-layout/blog-management/blog-management/blog-management.component';
import { EditBlogComponent } from './pages/dashboard-layout/blog-management/edit-blog/edit-blog.component';
import { ViewBlogComponent } from './pages/dashboard-layout/blog-management/view-blog/view-blog.component';
import { DashboardComponent } from './pages/dashboard-layout/dashboard/dashboard.component';
import { FaqAndPayoutManagementComponent } from './pages/dashboard-layout/faq-and-payout/faq-and-payout-management/faq-and-payout-management.component';
import { AddPayoutComponent } from './pages/dashboard-layout/faq-and-payout/payout/add-payout/add-payout.component';
import { EditPayoutComponent } from './pages/dashboard-layout/faq-and-payout/payout/edit-payout/edit-payout.component';
import { PayoutManagementComponent } from './pages/dashboard-layout/faq-and-payout/payout/payout-management/payout-management.component';
import { ViewPayoutComponent } from './pages/dashboard-layout/faq-and-payout/payout/view-payout/view-payout.component';
import { AddFaqComponent } from './pages/dashboard-layout/faq-management/add-faq/add-faq.component';
import { EditFaqComponent } from './pages/dashboard-layout/faq-management/edit-faq/edit-faq.component';
import { FaqManagementComponent } from './pages/dashboard-layout/faq-management/faq-management/faq-management.component';
import { ViewFaqComponent } from './pages/dashboard-layout/faq-management/view-faq/view-faq.component';
import { JackpotLotteryManagementComponent } from './pages/dashboard-layout/lottery-management/jackpot-lottery-management/jackpot-lottery-management.component';
import { LotteryPurchaseDataComponent } from './pages/dashboard-layout/lottery-management/lottery-purchase-data/lottery-purchase-data.component';
import { LotteryResultsComponent } from './pages/dashboard-layout/lottery-management/lottery-results/lottery-results/lottery-results.component';
import { ViewLotteryDetailsComponent } from './pages/dashboard-layout/lottery-management/lottery-results/view-lottery-details/view-lottery-details.component';
import { ProfileManagementComponent } from './pages/dashboard-layout/profile-management/profile-management/profile-management.component';
import { ReferralManagementComponent } from './pages/dashboard-layout/referral-management/referral-management/referral-management.component';
import { EditStaticContentComponent } from './pages/dashboard-layout/static-content-management/edit-static-content/edit-static-content.component';
import { StaticContentManagementComponent } from './pages/dashboard-layout/static-content-management/static-content-management/static-content-management.component';
import { ViewStaticContentComponent } from './pages/dashboard-layout/static-content-management/view-static-content/view-static-content.component';
import { TransactionHistoryComponent } from './pages/dashboard-layout/transaction-management/transaction-history/transaction-history.component';
import { ViewTransactionComponent } from './pages/dashboard-layout/transaction-management/view-transaction/view-transaction.component';
import { EditUserComponent } from './pages/dashboard-layout/user-management/edit-user/edit-user.component';
import { UserManagementComponent } from './pages/dashboard-layout/user-management/user-management/user-management.component';
import { ViewUserDataComponent } from './pages/dashboard-layout/user-management/view-user/view-user-data/view-user-data.component';
import { WalletManagementComponent } from './pages/dashboard-layout/wallet-management/wallet-management.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },

  { path: 'dashboard', component: DashboardComponent },

  // user management
  { path: 'user-management', component: UserManagementComponent },
  { path: 'view-user', component: ViewUserDataComponent },
  { path: 'edit-user', component: EditUserComponent },

  // lottery management
  { path: 'jackpot-lottery-management', component: JackpotLotteryManagementComponent },
  { path: 'lottery-purchase-data', component: LotteryPurchaseDataComponent },
  { path: 'lottery-results', component: LotteryResultsComponent },
  { path: 'view-lottery-details', component: ViewLotteryDetailsComponent },

  // transaction management
  { path: 'transaction-history', component: TransactionHistoryComponent },
  { path: 'view-transaction', component: ViewTransactionComponent },

  // referral management
  { path: 'referral-management', component: ReferralManagementComponent },
  // static content management
  { path: 'static-content-management', component: StaticContentManagementComponent },
  { path: 'view-static-content', component: ViewStaticContentComponent },
  { path: 'edit-static-content', component: EditStaticContentComponent },

  // faq management and payout management
  { path: 'faq-payout-management', component: FaqAndPayoutManagementComponent },
 
  { path: 'faq-management', component: FaqManagementComponent },
  { path: 'add-faq', component: AddFaqComponent },
  { path: 'view-faq', component: ViewFaqComponent },
  { path: 'edit-faq', component: EditFaqComponent },

  { path: 'payout-management', component: PayoutManagementComponent },
  { path: 'add-payout', component: AddPayoutComponent },
  { path: 'view-payout', component: ViewPayoutComponent },
  { path: 'edit-payout', component: EditPayoutComponent },

  // blog management
  { path: 'blog-management', component: BlogManagementComponent },
  { path: 'add-blog', component: AddBlogComponent },
  { path: 'view-blog', component: ViewBlogComponent },
  { path: 'edit-blog', component: EditBlogComponent },
  
  // banner management
  { path: 'banner-management', component: BannerManagementComponent },
  { path: 'add-banner', component: AddBannerComponent },
  { path: 'view-banner', component: ViewBannerComponent },
  { path: 'edit-banner', component: EditBannerComponent },

  // wallet management
  { path: 'hot-wallet', component: WalletManagementComponent },

  { path: 'profile-management', component: ProfileManagementComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
