import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/constants/end-point';
import { ExcelService } from 'src/app/provider/excel.service';
import { MainService } from 'src/app/provider/main.service';
import { ClipboardService } from 'ngx-clipboard';

declare var $: any;

@Component({
  selector: 'app-view-lottery-details',
  templateUrl: './view-lottery-details.component.html',
  styleUrls: ['./view-lottery-details.component.scss']
})
export class ViewLotteryDetailsComponent implements OnInit {
  lotteryId: any;
  lotteryDrawType: any;
  lotteryDetails: any;
  luckyDrawNumber: any;
  lotteryResultArray: any[] = []
  // calculator
  lotteryCalculatorForm: FormGroup;
  viewPriceBreakDownData: any;
  weeklyPoolWallet: number;

  search: string;
  fromDate: any;
  toDate: any;
  prizeAmount:any
  prizeAmount1:any
  userId:any
  today = new Date().toISOString().substr(0, 10);
  startWeek:any
  lotteryPurchaseListDataArray: any[] = []
  // pagination
  itemsPerPage = 10;
  currentPage = 1;
  totalItems: any;
  amount:any=[]
  count4: number=0;
  count3: number=0;
  count2: number=0;
  count1: number=0;
  count5: number=0;
  resultDate:any
  jackpotAmount: any;
  addAmount: any;
  ltcPrize: any;
  jackpotAmount1: any;
  price1: number;
  walletAddress: any=[];
  wallet: number;
  lotteryAmount: any;
  lastWeek: any;
  priceAmount:any
  newAddress: string;
  // resArray: any=[];

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: MainService, private datePipe: DatePipe, public excelService: ExcelService,private clipboardApi : ClipboardService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res)
      if (res.lotteryId) {
        this.lotteryId = res.lotteryId,
          this.lotteryDrawType = res.lotteryDrawType
      }
    })
    this.viewLotteryDetails();
    this.getLuckyDrawNumber();
    //this.getLotteryResult()
    this.lotteryCalculatorFormValidation()
    this.getPurchaseDetails()
    this.myFunction()
    this.getJackpot()
    this.getLTC()
    this.getLastWeek()
    // this.getWalletAddress()

  }

  // ------------------------- view lottery details ------------------------- //
  viewLotteryDetails() {
    let url = `account/admin/dashboard/find-lottery-event-by-id?lotteryId=${this.lotteryId}`
    this.mainService.getApi(url, 1).subscribe(res => {
      console.log(res)
      if (res.status == 200) {
        this.lotteryDetails = res.data ? res.data : ''
      }
    })
  }

  // --------------------------- lucky draw number ------------------------------ //
  getLuckyDrawNumber() {
    let url = `account/find-winning-number-by-lotteryId?lotteryId=${this.lotteryId}`
    this.mainService.getApi(url, 1).subscribe(res => {
      console.log(res)
      if (res.status == 200) {
        this.luckyDrawNumber = res.data ? res.data : ''
      }
    })
  }

  // ------------------------------ lottery result list ------------------------- //
  getLotteryResult() {
  //  let url = `account/find-winners-by-lotteryId?lotteryId=${this.lotteryId}`
    let url = `account/admin/user-management/final-result?lotteryId=${this.lotteryId}`
    this.mainService.getApi(url, 1).subscribe(res => {
    
if(res.result==''){
  this.mainService.errorToast('Lottery result not found')
  return
}

      // if (res.status == 200) {
console.log(res.results);

        // winner results list
        
        res.results.firstWinnersList
          ? res.results.firstWinnersList.forEach((element: any) => {
            element.winner = 'First Winner';
            this.count1=this.count1+1;
            this.price1=20;
            this.lotteryResultArray.push(element);

          })
          : null;
        res.results.secondWinnersList
          ? res.results.secondWinnersList.forEach((element: any) => {
            element.winner = 'Second Winner';
            this.count2=this.count2+1;
            this.lotteryResultArray.push(element);

          })
          : null;
        res.results.thirdWinnersList
          ? res.results.thirdWinnersList.forEach((element: any) => {
            element.winner = 'Third Winner';
            this.count3=this.count3+1;
            // this.wallet=this.walletAddress.filter(item=>())
            this.lotteryResultArray.push(element);

          })
          : null;
        res.results.fourthWinnersList
          ? res.results.fourthWinnersList.forEach((element: any) => {
            element.winner = 'Fourth Winner';
            this.count4=this.count4+1;
            this.lotteryResultArray.push(element);

          })
          : null;
        res.results.fifthWinnersList
          ? res.results.fifthWinnerUsreIdsList.forEach((element: any) => {
            element.winner = 'Fifth Winner';
            this.count5=this.count5+1;
            this.lotteryResultArray.push(element);

          })
          : null;
          console.log(this.lotteryResultArray);
          
         this.amount= res.results.amounts
          // ? res.results.amounts.forEach((element: any) => {
          //   // element.winner = 'Fifth Winner';
          //   this.lotteryResultArray.push(element);

          // })
          // : null;
        console.log(this.lotteryResultArray);
        // endwinner results list
        //  this.lotteryResultArray = res.data ? res.data : ''
      //}
    })
 }
  //---------------Send Button Message-------------------------//
  sendButton(userId, amount){
    this.userId=userId
    this.prizeAmount1=amount
    console.log(this.prizeAmount1,userId);
    
    $('#sendRewardId').modal('show')
  }
addJackpot(){
  $('#AddJackpot').modal('show')
}

  // ---------------- send prize to user ----------------//

  sendToUser(){
    $('#sendRewardId').modal('hide')
    let url="account/admin/user-management/send-ltc-to-user?ltcAmount="+(this.prizeAmount1) + "&toUserId="+(this.userId)
    this.mainService.showSpinner();
    this.mainService.postApi(url,{}, 1).subscribe(res => {
      console.log(res);
    if(res.status == 200){
      this.mainService.hideSpinner();
      this.mainService.successToast(res.message)
    }
else{
  this.mainService.hideSpinner()
  this.mainService.errorToast(res.message)
}    
  })
    
  }

  // ---------------- send prize to all ----------------//

  sendAll(){
    $('#sendAll').modal('show')

  }

  sendToAll(){
    $('#sendAll').modal('hide')
    let url="account/admin/user-management/send-ltc-to-all-user"
    this.mainService.showSpinner();
    this.mainService.postApi(url,{}, 1).subscribe(res => {
      console.log(res);
    if(res.status == 200){
      this.mainService.hideSpinner();
      this.mainService.successToast(res.message)
    }
else{
  this.mainService.hideSpinner()
  this.mainService.errorToast(res.message)
}    
  })
    
  }


  /**
   * Calculator
   */

  lotteryCalculatorFormValidation() {
    this.lotteryCalculatorForm = new FormGroup({
      'adminPrice': new FormControl('', Validators.required),
      'firstPrice': new FormControl('', Validators.required),
      'secondPrice': new FormControl('', Validators.required),
      'thirdPrice': new FormControl('', Validators.required),
      'fourthPrice': new FormControl('', Validators.required),
      'fifthPrice': new FormControl('', Validators.required),
    })
  }

  openCalculatorModal() {
    $('#calculatorModalId').modal('show')
    this.patchPricePercentageInModal()
  }

  patchPricePercentageInModal() {
    this.lotteryCalculatorForm.patchValue({
      'adminPrice': this.lotteryDetails.adminPrice ? this.lotteryDetails.adminPrice : '',
      'firstPrice': this.lotteryDetails.firstPrice ? this.lotteryDetails.firstPrice : '',
      'secondPrice': this.lotteryDetails.secondPrice ? this.lotteryDetails.secondPrice : '',
      'thirdPrice': this.lotteryDetails.thirdPrice ? this.lotteryDetails.thirdPrice : '',
      'fourthPrice': this.lotteryDetails.fourthPrice ? this.lotteryDetails.fourthPrice : '',
      'fifthPrice': this.lotteryDetails.fifthPrice ? this.lotteryDetails.value.fifthPrice : '',
    })
    this.viewPriceBreakDown();
  }


  viewPriceBreakDown() {
    this.mainService.showSpinner();
    let url = `account/admin/user-management/view-price-break-down?lotteryId=${this.lotteryId}`
    this.mainService.postApi(url, {}, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.weeklyPoolWallet = res.data.WeeklyPoolWallet ? res.data.WeeklyPoolWallet : 0
        this.viewPriceBreakDownData = res.data ? res.data : '';
        this.mainService.hideSpinner();
      } else {
        this.viewPriceBreakDownData = '';
        this.mainService.hideSpinner();
      }
    })
  }

  calculatePriceBreakDown() {
    if (this.lotteryCalculatorForm.invalid) {
      return this.mainService.infoToast('Please provide valid percentage.')
    } else if (this.checkPricePercentageModal('event')) {
      let apiReqData = {
        'adminWinPercentage': Number(this.lotteryCalculatorForm.value.adminPrice),
        'firstWinPercentage': Number(this.lotteryCalculatorForm.value.firstPrice),
        'secondWinPercentage': Number(this.lotteryCalculatorForm.value.secondPrice),
        'thirdWinPercentage': Number(this.lotteryCalculatorForm.value.thirdPrice),
        'fourthWinPercentage': Number(this.lotteryCalculatorForm.value.fourthPrice),
        'fifthWinPercentage': Number(this.lotteryCalculatorForm.value.fifthPrice)
      }
      let url = `account/admin/user-management/calulate-price-break-down?lotteryId=${this.lotteryId}`
      this.mainService.showSpinner()
      this.mainService.postApi(url, apiReqData, 1).subscribe((res: any) => {
        console.log(res)
        if (res.status == 200) {
          this.viewPriceBreakDownData = res.data ? res.data : '';
          this.mainService.hideSpinner();
        } else {
          this.viewPriceBreakDownData = '';
          this.mainService.hideSpinner();
        }
      })
    }
  }


  updatePricePercentage() {
    if (this.lotteryCalculatorForm.invalid) {
      return this.mainService.infoToast('Please provide valid percentage.')
    } else if (this.checkPricePercentageModal('event')) {
      $('#calculatorModalId').modal('hide')
      let apiReqData = {
        'adminWinPercentage': Number(this.lotteryCalculatorForm.value.adminPrice),
        'firstWinPercentage': Number(this.lotteryCalculatorForm.value.firstPrice),
        'secondWinPercentage': Number(this.lotteryCalculatorForm.value.secondPrice),
        'thirdWinPercentage': Number(this.lotteryCalculatorForm.value.thirdPrice),
        'fourthWinPercentage': Number(this.lotteryCalculatorForm.value.fourthPrice),
        'fifthWinPercentage': Number(this.lotteryCalculatorForm.value.fifthPrice),
        "isPriceDestributed":true
      }
      let url = `account/admin/user-management/apply-price-break-down?lotteryId=${this.lotteryId}`
      this.mainService.showSpinner()
      this.mainService.postApi(url, apiReqData, 1).subscribe((res: any) => {
        console.log(res)
        if (res.status == 200) {
          this.viewPriceBreakDownData = res.data ? res.data : '';
          this.mainService.hideSpinner();
          
        } else if(res.status==205){
          this.viewPriceBreakDownData = '';
          this.mainService.hideSpinner();
          this.mainService.errorToast('Prize Already Calculated')
        }
        else{
          this.viewPriceBreakDownData = '';
          this.mainService.hideSpinner();
          this.mainService.errorToast(res.message);
        }
      })
    }
  }

  checkPricePercentageModal(event) {
    // if (event.target.value) {
    var totalPricePercentage: number = 0;
    for (const fields in this.lotteryCalculatorForm.controls) {
      totalPricePercentage = totalPricePercentage + Number(this.lotteryCalculatorForm.controls[fields].value)
    }
    console.log(totalPricePercentage)
    if (totalPricePercentage <= 100) {
      return true
    } 
    else {
      this.mainService.infoToast(`Percentage can't be greater than 100 nor less than 100.`)
      return false
    }
    // }
  }

  //Find Day name in letters not in number
myFunction() {
    var d = new Date()
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    
    var n = weekday[d.getDay()];
      let newTime= ((d.getHours()*60*60*1000)+(d.getMinutes()*60*1000)+(d.getSeconds()*1000)) 
      let timeCalc = 24 * 60 * 60 * 1000
      let addHours= 5*60*60*1000
      let addMinute= 30*60*1000
    //var firstDay = new Date();
    if(n=="Monday"){
      //this.startWeek = new Date(d.getTime() + ((7 * timeCalc)-(newTime)));
      this.startWeek = new Date(d.getTime() );
      this.resultDate = new Date(d.getTime() + ((5 * timeCalc)-(newTime)+addHours+addMinute));

    }
    else if(n=="Tuesday"){
      this.startWeek = new Date(d.getTime() )
      this.resultDate = new Date(d.getTime() + ((4 * timeCalc)-(newTime)+addHours+addMinute));

    }
    else if(n=="Wednesday"){
      this.startWeek = new Date(d.getTime() );
      this.resultDate = new Date(d.getTime() + ((3 * timeCalc)-(newTime)+addHours+addMinute));

    }
    else if(n=="Thursday"){
      this.startWeek = new Date(d.getTime())
      this.resultDate = new Date(d.getTime() + ((2 * timeCalc)-(newTime)+addHours+addMinute));

    }
    else if(n=="Friday"){
      this.startWeek = new Date(d.getTime() )
      this.resultDate = new Date(d.getTime() + ((1 * timeCalc)-(newTime)+addHours+addMinute));

    }
    else if(n=="Saturday"){
      this.startWeek = new Date(d.getTime() )
      this.resultDate = new Date(d.getTime() + ((7 * timeCalc)-(newTime)+addHours+addMinute));

    }
    else if(n=="Sunday"){
      this.startWeek = new Date(d.getTime() )
      this.resultDate = new Date(d.getTime() + ((6 * timeCalc)-(newTime)+addHours+addMinute));

      }
    
   console.log("start date" + " "+this.startWeek);
   console.log("close date" + " "+this.resultDate);
   
   console.log(this.resultDate.getTime());
  
   
   
   
  }



  /**
   * Carry forward lottery 
   * bind all data of this lottery as getting in lotteryDetails and hit with additional key isCountinue:true
   */
  carryForwardLottery() {
    let data = {
      'lotteryId': Number(this.lotteryId),
      'lotteryImageUrl': this.lotteryDetails.lotteryImageUrl,
      'lotteryName': this.lotteryDetails.lotteryName,
      'adminPrice': Number(this.lotteryDetails.adminPrice),
      'firstPrice': Number(this.lotteryDetails.firstPrice),
      'secondPrice': Number(this.lotteryDetails.secondPrice),
      'thirdPrice': Number(this.lotteryDetails.thirdPrice),
      'fourthPrice': Number(this.lotteryDetails.fourthPrice),
      'fifthPrice': Number(this.lotteryDetails.fifthPrice),
      'salePrice': Number(this.lotteryDetails.salePrice),

      // 'startDateTime': new Date(this.lotteryDetails.startDateTime).getTime(),
      // 'endDateTime': new Date(this.lotteryDetails.endDateTime).getTime(), 
      // 'resultDateTime': new Date(this.lotteryDetails.resultDateTime).getTime(),
      'startDateTime': this.startWeek.getTime(),
      'endDateTime': this.resultDate.getTime(), 
      'resultDateTime': this.resultDate.getTime(),
      'isCountinue': false
    }
    console.log(data)
    console.log("today" +new Date().getTime());
    
    console.log('start'+this.lotteryDetails.startDateTime);
    console.log('end'+this.lotteryDetails.endDateTime);
    console.log('result'+ this.lotteryDetails.resultDateTime);
    
    
    let url = ApiEndPoint.editLottery;
    this.mainService.showSpinner()
    this.mainService.postApi(url, data, 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.mainService.hideSpinner()
        this.mainService.successToast('Lottery carry forward successfully.')
      } else {
        this.mainService.hideSpinner()
        this.mainService.errorToast('Something went wrong.')
      }
    })
  }



  /**
   * *****************************************************************************************
   */
  // ----------- search ------------- //
  searchEvent() {
    if (this.search || this.fromDate || this.toDate) {
      this.currentPage = 1;
      this.getPurchaseDetails()
    }
  }

  // --------- reset ------------- //
  resetEvent() {
    if (this.search || this.fromDate || this.toDate) {
      this.currentPage = 1;
      this.search = ''
      this.fromDate = '';
      this.toDate = '';
      this.getPurchaseDetails()
    }
  }

  // ---------------------------- lottery purchase details list ---------------------- //
  getPurchaseDetails() {
    var url;
    if (this.lotteryDrawType == 'OPEN') {
      url = `account/find-lottery-without-result-latest-draws?lotteryId=${this.lotteryId + '&page=' + (this.currentPage - 1) + '&pagesize=' + (this.itemsPerPage) +
        (this.search ? ('&search=' + this.search) : '') +
        (this.fromDate ? ('&fromDate=' + (new Date(this.fromDate).getTime())) : '') +
        (this.toDate ? ('&toDate=' + (new Date(this.toDate).getTime() + (1000 * 60 * 60 * 24))) : '')}`
    } else {
      url = `account/find-lottery-with-result-closed-draws?lotteryId=${this.lotteryId + '&page=' + (this.currentPage - 1) + '&pagesize=' + (this.itemsPerPage) +
        (this.search ? ('&search=' + this.search) : '') +
        (this.fromDate ? ('&fromDate=' + (new Date(this.fromDate).getTime())) : '') +
        (this.toDate ? ('&toDate=' + (new Date(this.toDate).getTime() + (1000 * 60 * 60 * 24))) : '')}`
        this.getLotteryResult()
    }
    this.mainService.getApi(url, 1).subscribe(res => {
      console.log(res)
      if (res.status == 200) {
        this.lotteryPurchaseListDataArray = this.lotteryDrawType == 'OPEN' ? res.data.latestLotteryData : res.data.closeLotteryData
        this.totalItems = res.data.count;
        this.mainService.hideSpinner();
        this.mainService.successToast(res.message)
      } else {
        this.lotteryPurchaseListDataArray = []
        this.totalItems = 0
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    }, err => {
      this.lotteryPurchaseListDataArray = []
      this.totalItems = 0
      this.mainService.hideSpinner();
      this.mainService.errorToast(err.message)
    })
  }

  // ----------------- pagination --------------------- //
  pagination(event) {
    this.currentPage = event;
    this.getPurchaseDetails()
  }
  viewUser(id){
    this.router.navigate(['/view-user'], { queryParams: { userId: id } })
  }

  //---------get JackPot Amount ------------//

  getJackpot(){

  let url="account/admin/user-management/get-jackpot-price?lotteryId="+this.lotteryId

   this.mainService.getApi(url,1).subscribe(res=>{
     if(res.status==200){
       this.mainService.hideSpinner()
   this.jackpotAmount1=res.data.ticketPrice
       this.jackpotAmount=(res.data.jackpotPrice.prise+this.jackpotAmount1)
       console.log(this.jackpotAmount);
       
     }
     else{
       this.mainService.hideSpinner()
      //  this.mainService.errorToast(res.message)
     }
   })
  }

  getLTC(){
    this.mainService.getLiquidity().subscribe((res)=> {
      this.ltcPrize=(res['LTC'])
     console.log(this.ltcPrize);
     
      // this.ltcPrize=Number(this.ltcPrize.toFixed(8))
    })
  }
  //---------Add JackPot Amount ------------//

editJackpot(){
  let jackpotAmount= Number((this.addAmount)-(this.jackpotAmount*this.ltcPrize)).toFixed(8)
   let url="account/admin/user-management/jackpot-price-set?lotteryId=" + this.lotteryId+"&price="+jackpotAmount
   console.log(jackpotAmount);
   
   this.mainService.getApi(url,1).subscribe(res=>{
     if(res.status==200){
       this.mainService.hideSpinner()
       this.getJackpot()
    
     }
     else{
       this.mainService.hideSpinner()
       this.mainService.errorToast(res.message)
     }
   })
  }


  //---transfer winner amount
  winnerAmount(){
    let url="wallet/admin/transaction-history/send-withdraw-amount"
   
    
    this.mainService.getApi(url,1).subscribe(res=>{
      if(res.status==200){
        this.mainService.hideSpinner()
      
     
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
   }
   copyToClipboard(item) {
      
    this.mainService.successToast('Copied')
    this.clipboardApi.copyFromContent(item)
   }
//--------- get wallet address --///////
   getWalletAddress(userId) {
    this.mainService.showSpinner();
    let url = `wallet/admin/transaction-history/get-address-user?userId=${userId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.walletAddress = res.data
        this.newAddress=res.data.walletAddress
        this.mainService.hideSpinner()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }
//--------- get wallet address --///////
   getLastWeek() {
    this.mainService.showSpinner();
    let url = `account/admin/user-management/total-transaction-amount-week?lotteryId=${this.lotteryId}`
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.lastWeek = res.data
        this.mainService.hideSpinner()
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res.message)
      }
    })
  }

  //wallet address modal
  findAddress(id,purchaseId){
    $('#transfer').modal('show')
    this.getWalletAddress(id)
    this.lotteryAmount= purchaseId


  }

  sendWinningAward(){
    $('#transfer').modal('hide')
    this.mainService.showSpinner()
    let url= "account/admin/user-management/send-ltc-to-by-address?Address="+this.newAddress+"&lotteryPurchaseId="+this.lotteryAmount+"&ltcAmount="+this.priceAmount
    this.mainService.postApi(url,{},1).subscribe(res=>{
      if(res.status==200){
        this.mainService.hideSpinner()
        this.mainService.successToast(res.message)
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.message)
      }
    })
  }
  // -------------------- export to excel --------------------------- //
  exportToExcel() {
    let dataArray = [];
    this.lotteryPurchaseListDataArray.forEach((element, ind) => {
      dataArray.push({
        "S.No": ind + 1,
        "Purchase Date": element.creationDate ? this.datePipe.transform(element.creationDate, 'mediumDate') : 'N/A',
        "Purchase Time": element.creationDate ? this.datePipe.transform(element.creationDate, 'mediumTime') : 'N/A',
        "User ID": element.fkUserId ? element.fkUserId : 'N/A',
        "Name": element.userName ? element.userName : 'N/A',
        "Lotto Number": element.numberOne ? element.numberOne + ',' + element.numberTwo + ',' + element.numberThree + ',' + element.numberFour + ',' + element.numberFive + ',' + element.numberSix + ',' + element.numberBouns : 'N/A',
      })
    })
    this.excelService.exportAsExcelFile(dataArray, 'lottery_purchase');
  }

}

