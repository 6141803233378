<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">User Management / View</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <!-- tab section -->
                        <div class="tab-section">
                            <div class="tab">
                                <button class="tablinks" [ngClass]="tabView == 'general' ? 'active': ''"
                                    (click)="viewTab('general')">General</button>
                                <button class="tablinks" [ngClass]="tabView == 'wallet' ? 'active': ''"
                                    (click)="viewTab('wallet')">Wallet</button>
                                <button class="tablinks" [ngClass]="tabView == 'wallet-transaction' ? 'active': ''"
                                    (click)="viewTab('wallet-transaction')">Wallet Transaction</button>
                                <button class="tablinks" [ngClass]="tabView == 'lottery-status' ? 'active': ''"
                                    (click)="viewTab('lottery-status')">Lottery Status</button>
                            </div>
                        </div>
                        <!--end tab section  -->

                        <!-- general tab content -->
                        <app-view-general-user-data *ngIf="tabView == 'general'">
                        </app-view-general-user-data>
                        <!-- end general tab content -->

                        <!-- wallet tab content  -->
                        <app-view-wallet-user-data *ngIf="tabView == 'wallet'">
                        </app-view-wallet-user-data>
                        <!--end wallet tab content  -->

                        <!-- wallet transaction tab content  -->
                        <app-view-wallet-transaction-user-data *ngIf="tabView == 'wallet-transaction'">
                        </app-view-wallet-transaction-user-data>
                        <!--end wallet transaction tab content  -->

                        <!-- lottery purchased tab content  -->
                        <app-view-lottery-status-user-data *ngIf="tabView == 'lottery-status'">
                        </app-view-lottery-status-user-data>
                        <!--end lottery purchased tab content  -->
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>